import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
    selector: 'cs-validation-message-container',
    templateUrl: './validation-message-container.component.html',
    standalone: false
})
export class ValidationMessageContainer {

  @Input() public label: string;
  @Input() public errors: ValidationErrors | null;

}
