import { Component } from '@angular/core';
import {DialogManager} from '../../../common/dialogs/dialog-manager.service';
import {ImportTelemetryDialogData, ImportTelemetryResult} from './import-telemetry-dialog.service';
import {ImportTelemetryDialogSession, ImportTelemetryDialogSessionFactory} from './import-telemetry-dialog-session';
import {DialogComponentBase} from '../../../common/dialogs/dialog-component-base';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Component({
    selector: 'cs-import-telemetry-dialog',
    templateUrl: './import-telemetry-dialog.component.html',
    styleUrls: ['./import-telemetry-dialog.component.scss'],
    standalone: false
})
export class ImportTelemetryDialogComponent extends DialogComponentBase<ImportTelemetryDialogData, ImportTelemetryResult> {
  public isVisible: boolean = false;

  public session: ImportTelemetryDialogSession;

  constructor(
    sessionFactory: ImportTelemetryDialogSessionFactory,
    dialogManager: DialogManager,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(sessionFactory, dialogManager, dialogManager.primaryChanged, getFriendlyErrorAndLog);
  }

  protected getCurrentDialog(): ImportTelemetryDialogData {
    let currentDialog = this.dialogManager.getPrimary();
    if (currentDialog && currentDialog instanceof ImportTelemetryDialogData) {
      return currentDialog;
    }
    return undefined;
  }
}


