import {Component, Input, OnDestroy} from '@angular/core';
import {JobViewModel} from '../job-view-model';
import {
  VerifyCarTabViewModel,
  NavigationStationRenderType,
  SimTypesTabViewModel,
  CarTabViewModel, TrackTabViewModel
} from '../tab-view-model';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  JobConfigData,
  VisualizationCaches,
  VisualizationFactory
} from '../../../visualizations/visualization-factory.service';
import {ViewJobResultsSectionBase} from '../view-job-results-section-base';
import {Timer} from '../../../../common/timer.service';
import {JobSelectorState} from '../job-selector-state';
import {SimVersionDocumentCache} from '../../../sim-version-document-cache.service';
import {DocumentSubType} from '../../../../../generated/api-stubs';
import {OutOfZoneNavigationStation} from '../../../visualizations/visualization-zones';

export const UNEXPECTED_TAB_TYPE_ERROR_MESSAGE = 'Unexpected tab type.';

@Component({
    selector: 'cs-view-job-navigation-station',
    templateUrl: './view-job-navigation-station.component.html',
    styleUrls: ['./view-job-navigation-station.component.scss'],
    standalone: false
})
export class ViewJobNavigationStationComponent extends ViewJobResultsSectionBase implements OnDestroy {
  @Input() public jobSelectorState: JobSelectorState;
  @Input() public navigationStationPrefix: string;
  @Input() public visualizationCaches: VisualizationCaches;

  public dashboardId: string;

  public perJob: boolean = false;
  public navigationStation: OutOfZoneNavigationStation;
  public perJobNavigationStations: OutOfZoneNavigationStation[];
  public perJobBuilt: boolean[];
  public job: JobViewModel = null;

  constructor(
    private visualizationFactory: VisualizationFactory,
    private timer: Timer,
    private simVersionDocumentCache: SimVersionDocumentCache,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    super(getFriendlyErrorAndLog);
  }

  ngOnDestroy(): void {
    if(this.navigationStation){
      this.navigationStation.dispose();
    }

    if(this.perJobNavigationStations){
      for(let item of this.perJobNavigationStations){
        item.dispose();
      }
    }
  }

  public getJobDashboardId(jobIndex: number): string {
    return `${this.dashboardId}-${jobIndex}`;
  }

  public isJobActive(job: JobViewModel): boolean {
    return this.job === job;
  }

  protected async load(){
    this.dashboardId = this.navigationStationPrefix || 'view-job-charts-' + ('' + Math.random()).substring(2);

    this.tab.activated.subscribe(() => this.reload());

    let caches = this.visualizationCaches;

    if(this.tab instanceof SimTypesTabViewModel){
      if(this.tab.navigationStationRenderType === NavigationStationRenderType.racingLine){

        this.perJob = true;

        for (let job of this.jobs) {
          await job.jobResult.tryLoad();
        }

        this.perJobNavigationStations = [];
        this.perJobBuilt = this.jobs.map(v => false);
        let jobIndex = 0;
        for (let job of this.jobs) {
          if (job.jobResult.value && job.jobResult.value.studyJobInput && job.jobResult.value.studyJobInput.simConfig) {
            let config = job.jobResult.value.studyJobInput.simConfig.track;
            if (config) {
              let jobNavigationStation = await this.visualizationFactory.createRacingLineViewer(
                this.getJobDashboardId(jobIndex), [job], this.tab.simTypes, caches, config);
              this.perJobNavigationStations.push(jobNavigationStation);
            }
          }
          ++jobIndex;
        }
      } else {
        this.navigationStation = await this.visualizationFactory.createSimTypesViewer(this.dashboardId, this.jobs, this.tab.simTypes, caches);
      }
    } else if(this.tab instanceof VerifyCarTabViewModel) {
      if(this.tab.filter !== null && this.tab.filter !== undefined){
        this.perJob = this.tab.perJob;

        if(this.perJob) {
          this.perJobNavigationStations = [];
          this.perJobBuilt = this.jobs.map(v => false);
          let jobIndex = 0;
          for(let job of this.jobs){
            let jobNavigationStation = await this.visualizationFactory.createCarViewer(this.getJobDashboardId(jobIndex), [job], this.tab.filter, caches);
            this.perJobNavigationStations.push(jobNavigationStation);
            ++jobIndex;
          }
        } else {
          this.navigationStation = await this.visualizationFactory.createCarViewer(this.dashboardId, this.jobs, this.tab.filter, caches);
        }
      }
    } else if(this.tab instanceof CarTabViewModel || this.tab instanceof TrackTabViewModel){
      this.perJob = this.tab.perJob;
      let documentsResult = await this.simVersionDocumentCache.get(this.simVersionDocumentCache.lastRequestedVersion);

      for (let job of this.jobs) {
        await job.jobResult.tryLoad();
      }

      if(this.perJob) {
        this.perJobNavigationStations = [];
        this.perJobBuilt = this.jobs.map(v => false);
        let jobIndex = 0;
        for (let job of this.jobs) {
          if (job.jobResult.value && job.jobResult.value.studyJobInput && job.jobResult.value.studyJobInput.simConfig) {
            let jobName = await job.getStudyJobName();
            if (this.tab instanceof CarTabViewModel) {
              let config = job.jobResult.value.studyJobInput.simConfig.car;
              if (config) {
                let jobNavigationStation = await this.visualizationFactory.createCarPreview(
                  this.getJobDashboardId(jobIndex), [new JobConfigData(jobName, config, job)], documentsResult.units, caches);
                this.perJobNavigationStations.push(jobNavigationStation);
              }
            } else {
              let config = job.jobResult.value.studyJobInput.simConfig.track;
              if (config) {
                let jobNavigationStation = await this.visualizationFactory.createTrackPreview(
                  this.getJobDashboardId(jobIndex), [new JobConfigData(jobName, config, job)], documentsResult.units, caches);
                this.perJobNavigationStations.push(jobNavigationStation);
              }
            }
          }
          ++jobIndex;
        }
      } else{
        let getJobConfigData = async (configType: DocumentSubType): Promise<JobConfigData[]> => {
          let result: JobConfigData[] = [];
          for(let job of this.jobs){
            let jobName = await job.getStudyJobName();
            if(job.jobResult.value && job.jobResult.value.studyJobInput && job.jobResult.value.studyJobInput.simConfig){
              let config = job.jobResult.value.studyJobInput.simConfig[configType];
              if(config){
                result.push(new JobConfigData(jobName, job.jobResult.value.studyJobInput.simConfig[configType], job));
              }
            }
          }
          return result;
        };
        if (this.tab instanceof CarTabViewModel) {
          let jobConfigData = await getJobConfigData(DocumentSubType.car);
          this.navigationStation = await this.visualizationFactory.createCarPreview(this.dashboardId, jobConfigData, documentsResult.units, caches);
        } else {
          let jobConfigData = await getJobConfigData(DocumentSubType.track);
          this.navigationStation = await this.visualizationFactory.createTrackPreview(this.dashboardId, jobConfigData, documentsResult.units, caches);
        }
      }
    } else{
      this.errorMessage = UNEXPECTED_TAB_TYPE_ERROR_MESSAGE;
    }

    if(this.navigationStation){
      // Ensure the chart parent element has been rendered.
      await this.timer.yield();
      await this.navigationStation.build();
    }

    if(this.job){
      // Needs to be set to loaded before calling selectJob, so can't wait for base class to do it.
      this.isLoaded = true;
      await this.selectJob(this.job);
    }
  }

  public async reload(){
    try{
      if(this.navigationStation){
        await this.navigationStation.redraw();
      } else if(this.job){
        let jobIndex = this.jobs.indexOf(this.job);
        if(jobIndex !== -1){
          let isBuilt = this.perJobBuilt[jobIndex];
          let navigationStation = this.perJobNavigationStations[jobIndex];
          if(isBuilt){
            await navigationStation.redraw();
          }
        }
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async selectJob(job: JobViewModel) {
    try{
      this.job = job;

      if(!this.isLoaded){
        return;
      }

      let jobIndex = this.jobs.indexOf(this.job);
      if(jobIndex !== -1){
        let isBuilt = this.perJobBuilt[jobIndex];
        let navigationStation = this.perJobNavigationStations[jobIndex];
        if(!isBuilt){
          this.perJobBuilt[jobIndex] = true;
          await navigationStation.build();
        } else {
          await this.timer.yield();
          await navigationStation.redraw();
        }
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}
