import { Component, Input, OnChanges } from '@angular/core';
import {DownloadStudy, DownloadType} from '../../download-study.service';
import { SimType } from '../../../../../generated/api-stubs';

@Component({
    selector: 'cs-download-buttons',
    templateUrl: './download-buttons.component.html',
    standalone: false
})
export class DownloadButtonsComponent implements OnChanges{
  @Input() public tenantId: string;
  @Input() public studyId: string;
  @Input() public studyName: string;
  @Input() public jobCount: number;
  @Input() public isCompleted: boolean;
  @Input() public simTypes?: SimType[];

  downloadStudyTypes: DownloadType[] = [];

  constructor(public readonly downloadStudyService: DownloadStudy){}

  public ngOnChanges(){
    this.downloadStudyTypes = this.downloadStudyService.downloadStudyTypes(
      this.tenantId,
      this.studyId,
      this.studyName,
      this.jobCount,
      this.simTypes);
  }
}
