import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CanopyValidators} from '../../common/forms/canopy-validators.service';
import {FormSubmissionButton} from '../../common/forms/form-submission-button';
import {CustomProperty} from '../custom-properties/custom-properties';
import {FormSubmissionHandler} from '../../common/forms/form-submission-handler.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DisplayableError} from '../../common/errors/errors';
import {DocumentSubType} from '../../../generated/api-stubs';

export const NO_DOCUMENT_METADATA_ERROR = 'No document metadata provided.';

export class DocumentMetadata {
  constructor(
    public readonly name: string,
    public readonly customProperties: ReadonlyArray<CustomProperty>,
    public readonly notes: string){
  }
}

@Component({
    selector: 'cs-edit-document-metadata',
    templateUrl: './edit-document-metadata.component.html',
    styleUrls: ['./edit-document-metadata.component.scss'],
    standalone: false
})
export class EditDocumentMetadataComponent implements OnInit {

  public errorMessage: string;

  @Input() public tenantId: string;
  @Input() public target: DocumentSubType;
  @Input() public initialDocumentMetadata: DocumentMetadata;

  @Output() public readonly metadataChanged: EventEmitter<DocumentMetadata> = new EventEmitter<DocumentMetadata>();

  public form: UntypedFormGroup;
  public name: UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, ...CanopyValidators.configNameValidators]));
  public notes: UntypedFormControl = new UntypedFormControl('', [...CanopyValidators.configNotesValidators]);
  public submitButton = new FormSubmissionButton('Save', 'Saving...');
  public saveSuccessful = false;
  public customProperties: CustomProperty[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formSubmissionHandler: FormSubmissionHandler,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {

    this.form = this.formBuilder.group({
      name: this.name,
      notes: this.notes,
    });
  }

  public ngOnInit() {
    this.load();
  }

  public async load(){
    try {
      if(this.initialDocumentMetadata){
        this.name.setValue(this.initialDocumentMetadata.name, {});
        this.notes.setValue(this.initialDocumentMetadata.notes, {});
        this.customProperties = [...this.initialDocumentMetadata.customProperties];
      } else{
        throw new DisplayableError(NO_DOCUMENT_METADATA_ERROR);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onSubmit() {
    this.saveSuccessful = false;
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.saveSuccessful = true;
    }
  }

  private async submit() {
    this.metadataChanged.emit(
      new DocumentMetadata(
        this.name.value,
        this.customProperties,
        this.notes.value));
  }
}
