import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MembershipStub } from '../../../generated/api-stubs';
import { DOCUMENT } from '@angular/common';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { ZendeskRedirectWorkaround } from './zendesk-redirect-workaround';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';
import { RedirectService } from '../../navigation/redirect.service';

@Component({
    selector: 'cs-zendesk',
    templateUrl: './zendesk.component.html',
    styleUrls: ['./zendesk.component.scss'],
    standalone: false
})
export class ZendeskComponent implements OnInit {

  public redirectUrl: string;
  public errorMessage: string;

  private readonly document: Document;
  private userData: UserData;

  public requiresEmailConfirmation: boolean = false;
  public emailConfirmationSent: boolean = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly redirectService: RedirectService,
    private readonly membershipStub: MembershipStub,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    @Inject(DOCUMENT) document: any, // https://stackoverflow.com/a/53715642
    route: ActivatedRoute) {

    this.document = document as Document;
    this.redirectUrl = ZendeskRedirectWorkaround.processRedirectUrl(route.snapshot.queryParamMap.get('return_to'));
  }

  public async ngOnInit(): Promise<void> {
    try {
      await this.authenticationService.checkAuthentication().toPromise();
      this.userData = this.authenticationService.userDataSnapshot;
      if (!this.authenticationService.isAuthenticatedSnapshot) {
        this.redirectService.setRedirect('zendesk', this.redirectUrl);
        this.authenticationService.login();
        return;
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.performRedirect();
  }

  public get isLoading() {
    return !this.errorMessage && !this.requiresEmailConfirmation && !this.emailConfirmationSent;
  }

  private reset() {
    this.errorMessage = undefined;
    this.requiresEmailConfirmation = false;
    this.emailConfirmationSent = false;
  }

  public async performRedirect() {
    try {
      this.reset();
      let zendeskTokenResult = await this.membershipStub.getZendeskToken(this.userData.tenant, this.userData.sub);
      (document.getElementById('jwtInput') as HTMLInputElement).value = zendeskTokenResult.token;
      (document.getElementById('returnToInput') as HTMLInputElement).value = encodeURIComponent(this.redirectUrl);
      this.document.forms.namedItem('jwtForm').submit();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        this.requiresEmailConfirmation = true;
      }

      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async verifyEmail() {
    try {
      this.reset();
      await this.membershipStub.postEmailConfirmationRequest(this.userData.tenant, this.userData.sub);
      this.emailConfirmationSent = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async retryZendeskAutentication() {
    await this.performRedirect();
  }
}
