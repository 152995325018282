import {Component, Input} from '@angular/core';
import {LintingEngineRunnerDialog} from '../linting-engine-runner-dialog/linting-engine-runner-dialog.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DocumentSubType} from '../../../../generated/api-stubs';
import {ConfirmationDialog} from '../../../common/dialogs/confirmation-dialog.service';
import {CanopyError} from '../../../common/errors/errors';
import { AuthenticationService } from '../../../identity/state/authentication.service';

export const LINTING_ERROR_ERROR_TITLE: string = 'Linting Error';

@Component({
    selector: 'cs-linting-engine-dialog-launcher',
    templateUrl: './linting-engine-dialog-launcher.component.html',
    styleUrls: ['./linting-engine-dialog-launcher.component.scss'],
    standalone: false
})
export class LintingEngineDialogLauncherComponent {
  @Input() public tenantId: string;
  @Input() public userId: string;
  @Input() public configId: string;
  @Input() public getConfigData: () => any;
  @Input() public configType: DocumentSubType;
  @Input() public studyId: string;
  @Input() public jobId: string;
  @Input() public compactButton: boolean = false;

  constructor(
    private readonly prompt: ConfirmationDialog,
    private readonly lintingEngineRunner: LintingEngineRunnerDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public get isAdministrator(): boolean {
    return this.authenticationService.isAdministrator;
  }

  public async launch() {
    try{
      let configData: any;
      if(this.configType){
        if(!this.getConfigData){
          throw new CanopyError('No getConfigData delegate was supplied.');
        }

        configData = this.getConfigData();
        if(!configData){
          throw new CanopyError('No config was supplied.');
        }
      }

      await this.lintingEngineRunner.show(
        this.tenantId,
        this.userId,
        this.configId,
        configData,
        this.configType,
        this.studyId,
        this.jobId);
    } catch(error){
      let message = this.getFriendlyErrorAndLog.execute(error);
      await this.prompt.show(message, 'Linting Error');
    }
  }
}
