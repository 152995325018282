import * as marked from 'marked';
import {Component, Input, ElementRef, ViewEncapsulation, OnChanges} from '@angular/core';
import {Timer} from '../common/timer.service';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Router} from '@angular/router';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {cssSanitize} from '../common/css-sanitize';

@Component({
    selector: 'cs-markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MarkdownComponent implements OnChanges {
  @Input() data: string;
  @Input() anchorName: string;

  public convertedData: SafeHtml;
  public errorMessage: string;

  constructor(
    private timer: Timer,
    private elementRef: ElementRef,
    private router: Router,
    private sanitizer: DomSanitizer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  ngOnChanges() {
    this.load();
  }

  public async load(){
    try {
      let renderer = new marked.Renderer();
      renderer.table = function(header, body) {
        return '<table class="table">\n'
          + '<thead>\n'
          + header
          + '</thead>\n'
          + '<tbody>\n'
          + body
          + '</tbody>\n'
          + '</table>\n';
      };
      let md = marked.setOptions({ renderer });
      let markdownText = this.data;
      let htmlText = md.parse(markdownText);

      this.convertedData = this.sanitizer.bypassSecurityTrustHtml(htmlText);

      await this.timer.yield();
      this.parseLinks();

      if(this.anchorName){
        await this.timer.delay(1000);
        this.scrollToAnchor(this.anchorName);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public parseLinks(){
    let links = this.elementRef.nativeElement.getElementsByTagName('a');
    let markdownRegex = /~\/(.*)\.md(\?|#|$)/;
    for(let link of links){
      let path = decodeURI(link.pathname);

      let match = markdownRegex.exec(path);
      if(match && match.length >= 2 && match[1]) {
        let documentPath = match[1].replace(/ /g, '-');
        let targetAnchor = link.hash ? link.hash.substr(1) : undefined;
        link.href = 'javascript:void(0)';
        link.className += ' ' + cssSanitize('wiki-link-' + documentPath + (targetAnchor ? '-' + targetAnchor : ''));
        link.addEventListener('click', async () => {
          try {
            let params: {[key: string]: string} = {};
            if(targetAnchor){
              params['location'] = targetAnchor;
            }
            await this.router.navigate(['/wiki/' + documentPath, params]);
          } catch(error){
            this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
          }
        });
      } else if(path === '/' && link.hash){
        let targetAnchor = link.hash.substr(1);
        link.href = 'javascript:void(0)';
        link.className += ' ' + cssSanitize('wiki-relative-link-' + targetAnchor);
        link.addEventListener('click', () => {
          this.scrollToAnchor(targetAnchor);
        });
      }
    }
  }

  public scrollToAnchor(anchorName: string){
    let targetElement = this.elementRef.nativeElement.querySelector(`a[name="${anchorName}"]`)
      || this.elementRef.nativeElement.querySelector(`#${anchorName}`);
    if(targetElement){
      targetElement.scrollIntoView(targetElement);
    }
  }
}
