import {Component, Input, OnInit} from '@angular/core';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {
  TenantSettingsStub, GetTenantDefaultCustomPropertyNamesQueryResult,
  DocumentsWithProperties
} from '../../generated/api-stubs';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {UserIdentity} from '../identity/user-identity';
import {CanopyValidators} from '../common/forms/canopy-validators.service';
import {ConfigTypeLookup} from '../simulations/configs/config-types';
import {UntypedFormGroup, UntypedFormControl, UntypedFormBuilder} from '@angular/forms';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
    selector: 'cs-tenant-settings-default-custom-property-names',
    templateUrl: './tenant-settings-default-custom-property-names.component.html',
    styleUrls: ['./tenant-settings-default-custom-property-names.component.scss'],
    standalone: false
})
export class TenantSettingsDefaultCustomPropertyNamesComponent implements OnInit {

  @Input() public tenantId: string;

  public form: UntypedFormGroup;

  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Save', 'Saving...');
  public saveSuccessful = false;

  public isAdministrator: boolean;

  public currentIdentity: UserIdentity;
  public settingsResult: GetTenantDefaultCustomPropertyNamesQueryResult;
  public defaultCustomPropertyNames: DefaultCustomPropertyNames;
  public controls: { [id: string]: UntypedFormControl };
  public nameId: number = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private tenantSettingsStub: TenantSettingsStub,
    private formSubmissionHandler: FormSubmissionHandler,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.isAdministrator = this.authenticationService.isAdministrator;
      this.settingsResult = await this.tenantSettingsStub.getTenantDefaultCustomPropertyNames(this.tenantId);
      this.defaultCustomPropertyNames = this.createCompleteCustomPropertyNameList(this.settingsResult);
      this.controls = this.createControlsObject(this.defaultCustomPropertyNames);
      this.form = this.formBuilder.group(this.controls);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public createCompleteCustomPropertyNameList(settingsResult: GetTenantDefaultCustomPropertyNamesQueryResult): DefaultCustomPropertyNames {
    let result: DefaultCustomPropertyNames = [];
    for(let item of settingsResult.settings.defaultCustomPropertyNames){

      if(item.target !== DocumentsWithProperties.study && !settingsResult.configTypes.find(v => v === <string>item.target)) {
        continue;
      }

      item.names.sort();

      let nameData = [];
      for(let name of item.names){
        let control = new UntypedFormControl(name, CanopyValidators.customPropertyNameValidators);
        let id = this.getNextId();
        nameData.push({
          id,
          control
        });
      }

      let configType = ConfigTypeLookup.get(item.target);
      if(configType){
        result.push({
          target: item.target,
          title: configType.titleName,
          names: nameData
        });
      }
    }

    // Filter out targets no longer used and add missing targets, ordering by simulation input list.
    let orderedResult: DefaultCustomPropertyNameSet[] = [];
    for(let target of [...settingsResult.configTypes, DocumentsWithProperties.study]){
      let resultItem = result.find(v => v.target === target);

      if(resultItem){
        orderedResult.push(resultItem);
      } else{
        let configType = ConfigTypeLookup.get(target);
        if(configType){
          orderedResult.push({
            target: target as DocumentsWithProperties,
            title: configType.titleName,
            names: [] as DefaultCustomPropertyName[]
          });
        }
      }
    }

    return orderedResult;
  }

  public createControlsObject(items: DefaultCustomPropertyNames): { [id: string]: UntypedFormControl } {
    let controls: { [id: string]: UntypedFormControl } = {};

    for(let item of items){
      for(let name of item.names){
        controls[name.id] = name.control;
      }
    }

    return controls;
  }

  private getNextId(){
    return 'cp-' + this.nameId++;
  }

  public async reload(){
    this.form = undefined;
    await this.load();
  }

  public addEmptyName(item: DefaultCustomPropertyNameSet){
    let control = new UntypedFormControl('', CanopyValidators.customPropertyNameValidators);
    let id = this.getNextId();
    item.names.push({
      id,
      control
    });

    this.form.addControl(id, control);
    this.controls[id] = control;
  }

  public removeName(item: DefaultCustomPropertyNameSet, nameIndex: number) {
    let nameItem = item.names[nameIndex];
    item.names.splice(nameIndex, 1);
    this.form.removeControl(nameItem.id);
    delete this.controls[nameItem.id];
  }

  public createSettings(){
    let defaultCustomPropertyNames = [];
    for(let target of this.defaultCustomPropertyNames){
      let newItem = {
        target: target.target,
        names: [] as string[]
      };

      for(let item of target.names){
        let name = item.control.value;
        if(name){
          newItem.names.push(name);
        }
      }

      defaultCustomPropertyNames.push(newItem);
    }

    return {
      defaultCustomPropertyNames
    };
  }

  public async onSubmit() {
    this.saveSuccessful = false;
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.saveSuccessful = true;
    }
  }

  public async submit() {
    let settings = this.createSettings();

    await this.tenantSettingsStub.putTenantDefaultCustomPropertyNames(
      this.tenantId,
      settings);
  }
}

interface DefaultCustomPropertyName {
  id: string;
  control: UntypedFormControl;
}

interface DefaultCustomPropertyNameSet {
  target: DocumentsWithProperties;
  title: string;
  names: DefaultCustomPropertyName[];
}

type DefaultCustomPropertyNames = DefaultCustomPropertyNameSet[];
