import {Component} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CanopyValidators} from '../common/forms/canopy-validators.service';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {MembershipStub} from '../../generated/api-stubs';
import {InferableRouteParams} from '../common/inferable-route-params.service';
import {TenancyStub} from '../../generated/api-stubs';
import {OnInit} from '@angular/core';
import {FetchUserState} from '../user-state/fetch-user-state.service';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
    templateUrl: './register.page.html',
    standalone: false
})
export class RegisterPage implements OnInit {
  public form: UntypedFormGroup;
  public email: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.emailValidators]);
  public username: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.usernameValidators]);
  public password: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.passwordValidators]);

  public tenantName: string;
  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Register', 'Registering...');

  private tenantId: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private inferableRouteParams: InferableRouteParams,
    private fetchUserState: FetchUserState,
    private tenancyStub: TenancyStub,
    private membershipStub: MembershipStub,
    private formSubmissionHandler: FormSubmissionHandler,
    private router: Router,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.form = this.formBuilder.group({
        email: this.email,
        username: this.username,
        password: this.password
      });

      this.tenantId = this.inferableRouteParams.getTenantId(this.route);

      let userState = await this.fetchUserState.get();

      if(userState.tenant.tenantId === this.tenantId) {
        this.tenantName = userState.tenant.name;
      } else {
        let tenant = await this.tenancyStub.getTenant(this.tenantId);
        this.tenantName = tenant.name;
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onSubmit() {
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.router.navigate(['/tenants', this.tenantId]);
    }
  }

  private async submit() {
    await this.membershipStub.postRegistration({
      tenantId: this.tenantId,
      email: this.email.value,
      username: this.username.value,
      password: this.password.value
    });
  }
}
