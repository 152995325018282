import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  DocumentSubType,
  GetSimVersionDocumentsQueryResult,
} from '../../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {SimVersionDocumentCache} from '../../../sim-version-document-cache.service';
import {GetSimVersion} from '../../../../common/get-sim-version.service';
import {ConfigType, ConfigTypeLookup} from '../../config-types';
import {ConfigPreviewHelper} from '../../config-preview-component-base';
import {CompareConfigStructuresComponent} from '../compare-config-structures/compare-config-structures.component';
import {ISaveOutputConfigHandler} from '../compare-config-dialog/compare-config-dialog.service';
import {SaveAsDialog} from '../../../../common/dialogs/save-as-dialog.service';
import {StudyStagingArea} from '../../../study-staging-area/study-staging-area.service';
import {ConfigOrConfigLoader} from '../config-or-config-loader';

export class CompareTab {
  public _isRendered: boolean = false;
  public _isVisible: boolean = false;

  constructor(
    public readonly type: CompareTabType,
    public readonly name: string) {
  }

  public get isRendered(): boolean {
    return this._isRendered;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }
  public set isVisible(value: boolean){
    this._isVisible  = value;
    if(value){
      this._isRendered = true;
    }
  }
}

export enum CompareTabType {
  structure,
  visualizations,
}

@Component({
    selector: 'cs-compare-configs',
    templateUrl: './compare-configs.component.html',
    styleUrls: ['./compare-configs.component.scss'],
    standalone: false
})
export class CompareConfigsComponent implements OnInit {

  public CompareTabType = CompareTabType;

  @Input() public configs: ConfigOrConfigLoader[];
  @Input() public simVersion: string;
  @Input() public configType: DocumentSubType;
  @Input() public saveOutputConfigHandler: ISaveOutputConfigHandler;

  public configTypeDefinition: ConfigType;
  public simVersionDocumentsResult: GetSimVersionDocumentsQueryResult;
  public isLoaded: boolean;

  public errorMessage: string;

  public readonly compareStructure = new CompareTab(CompareTabType.structure, 'Compare Structure');
  public readonly compareVisualizations = new CompareTab(CompareTabType.visualizations, 'Compare Charts');

  public readonly tabs: CompareTab[] = [];

  @ViewChild('compareConfigStructuresComponent') compareConfigStructuresComponent: CompareConfigStructuresComponent;

  constructor(
    private readonly simVersionDocumentCache: SimVersionDocumentCache,
    private readonly getSimVersion: GetSimVersion,
    private readonly saveAsDialog: SaveAsDialog,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  ngOnInit() {
    this.load();
  }

  public get isSafeToSaveOutput(): boolean {
    return this.isLoaded && this.configs.length > 1;
  }

  public async load(){
    try {
      this.errorMessage = undefined;
      this.isLoaded = false;

      this.tabs.push(this.compareStructure);
      if(this.hasConfigPreview){
        this.tabs.push(this.compareVisualizations);
      }

      if(!this.simVersion){
        this.simVersion = this.getSimVersion.currentSimVersion;
      }

      this.configTypeDefinition = ConfigTypeLookup.get(this.configType);

      for(let i=this.configs.length - 1; i >= 0; --i){
        let item = this.configs[i];

        if(item.materialized){
          continue;
        }

        if(item.loader){
          item.materialized = await item.loader.execute();
        } else{
          this.configs.splice(i, 1);
        }
      }

      this.simVersionDocumentsResult = await this.simVersionDocumentCache.get(this.simVersion);
      this.isLoaded = true;
      this.tabs[0].isVisible = true;
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async saveAs(): Promise<void> {
    if(!this.compareConfigStructuresComponent){
      return;
    }

    let outputConfig = this.compareConfigStructuresComponent.getOutputConfig();

    await this.saveAsDialog.showForContent(
      outputConfig.name,
      this.configType,
      this.simVersion,
      undefined,
      outputConfig.data,
      outputConfig.properties,
      outputConfig.notes);
  }

  public async stage(): Promise<void> {
    if(!this.compareConfigStructuresComponent){
      return;
    }

    let outputConfig = this.compareConfigStructuresComponent.getOutputConfig();
    this.studyStagingArea.stage(
      this.configType,
      outputConfig.userId,
      outputConfig.configId,
      outputConfig.name,
      outputConfig.data,
      [...outputConfig.properties],
      outputConfig.notes,
      this.simVersion,
      true);
  }

  public get canSave(): boolean {
    return this.isLoaded
      && this.saveOutputConfigHandler
      && this.configs.length
      && this.saveOutputConfigHandler.canExecute(this.configs[0].materialized);
  }

  public async save(): Promise<void> {
    if(!this.compareConfigStructuresComponent){
      return;
    }

    let outputConfig = this.compareConfigStructuresComponent.getOutputConfig();

    await this.saveOutputConfigHandler.execute(outputConfig);
  }

  public get hasConfigPreview(): boolean {
    return ConfigPreviewHelper.hasConfigPreview(this.configType);
  }

  public setActiveTab(tab: CompareTab){
    for(let item of this.tabs){
      item.isVisible = tab === item;
    }
  }
}
