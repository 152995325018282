import {Component, Input} from '@angular/core';
import {StudyType} from '../../../../generated/api-stubs';

@Component({
    selector: 'cs-stage-as-telemetry-text',
    templateUrl: './stage-as-telemetry-text.component.html',
    styleUrls: ['./stage-as-telemetry-text.component.scss'],
    standalone: false
})
export class StageAsTelemetryTextComponent {

  @Input() public studyType: StudyType;

  constructor() { }
}
