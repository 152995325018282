import {Component, Input} from '@angular/core';
import {ViewJobResultsSectionBase} from '../view-job-results-section-base';
import {JobSelectorState} from '../job-selector-state';
import {JobViewModel} from '../job-view-model';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ScalarResultSet} from '../../../studies/study-utilities.service';

@Component({
    selector: 'cs-view-job-scalar-results',
    templateUrl: './view-job-scalar-results.component.html',
    styleUrls: ['./view-job-scalar-results.component.scss'],
    standalone: false
})
export class ViewJobScalarResultsComponent extends ViewJobResultsSectionBase {
  @Input() public jobSelectorState: JobSelectorState;

  public job: JobViewModel = null;

  public ScalarResultType = ScalarResultType;

  constructor(
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    super(getFriendlyErrorAndLog);
  }

  public getResults(set: ScalarResultSet, type: ScalarResultType){
    switch(type){
      case ScalarResultType.all:
        return set.results;

      case ScalarResultType.standard:
        return set.standardResults;

      case ScalarResultType.constraint:
        return set.constraintResults;

      case ScalarResultType.setup:
        return set.setupResults;
    }

    return [];
  }

  public get hasConstraintResults(): boolean {
    if(!this.job.scalarResults.value){
      return false;
    }

    return this.job.scalarResults.value.some(v => v.constraintResults.length > 0);
  }

  protected async load(){
    if(this.job){
      await this.job.scalarResults.tryLoad();
    }
  }

  public async selectJob(job: JobViewModel) {
    try{
      this.job = job;
      if(this.isLoaded){
        await this.load();
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

enum ScalarResultType {
  all,
  standard,
  constraint,
  setup,
}
