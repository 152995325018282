import {Component, Input, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ConfirmationDialog} from '../../../common/dialogs/confirmation-dialog.service';
import {GetStudyJobQueryResult, StudyStub} from '../../../../generated/api-stubs';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {StageInputSource} from '../stage-input-source.service';
import {LoadingDialog} from '../../../common/dialogs/loading-dialog.service';

export const ERROR_TITLE = 'Stage Inputs Error';

@Component({
    selector: 'cs-stage-all-inputs',
    templateUrl: './stage-all-inputs.component.html',
    styleUrls: ['./stage-all-inputs.component.scss'],
    standalone: false
})
export class StageAllInputsComponent implements OnInit {
  @Input() public studyId: string;
  @Input() public tenantId: string;
  @Input() public jobId: string | undefined = undefined;

  public errorMessage: string;

  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly studyStub: StudyStub,
    private readonly stageInputSource: StageInputSource,
    private readonly prompt: ConfirmationDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  ngOnInit() {
    if (!this.studyId) {
      this.errorMessage = 'Study ID is required.';
      return;
    }

    if (!this.tenantId) {
      this.errorMessage = 'Tenant ID is required.';
    }
  }

  public get showButton(): boolean {
    return !this.errorMessage;
  }

  public async execute() {
    try {
      const studyResult = await this.loadingDialog.showUntilFinished(
        this.studyStub.getStudy(this.tenantId, this.studyId, this.getSimVersion.currentSimVersion),
        'Loading Study...');

      let jobResult: GetStudyJobQueryResult;
      if (this.jobId) {
        jobResult = await this.loadingDialog.showUntilFinished(
          this.studyStub.getStudyJob(this.tenantId, this.studyId, this.jobId, this.getSimVersion.currentSimVersion),
          'Loading Study Job...');
      }
      this.stageInputSource.stageWholeStudy(studyResult, jobResult);
    } catch(error){
      const message = this.getFriendlyErrorAndLog.execute(error);
      await this.prompt.show(message, ERROR_TITLE);
    }
  }
}
