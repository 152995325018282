import {
  OnInit, Component, Input, EventEmitter, Output,
  OnDestroy
} from '@angular/core';
import {
  ConfigStub, DocumentNamesResultType,
  DocumentSubType
} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Subscription} from 'rxjs';
import {LocalStorage} from '../../../common/local-storage.service';
import {clone} from '../../../common/canopy-json.service';
import {SaveAsDialog} from '../../../common/dialogs/save-as-dialog.service';
import {ConfigLoaderDialog} from '../config-loader-dialog/config-loader-dialog.service';
import { AuthenticationService } from '../../../identity/state/authentication.service';

@Component({
    selector: 'cs-quick-config-manager',
    templateUrl: './quick-config-manager.component.html',
    styleUrls: ['./quick-config-manager.component.scss'],
    standalone: false
})
export class QuickConfigManagerComponent implements OnInit, OnDestroy {

  @Input() public configType: DocumentSubType;
  @Input() public subTreePath: string;
  @Input() public simVersion: string;
  @Input() public persistLocally: boolean;
  @Input() public updateSource: IConfigSource;
  @Input() public showName: boolean = true;
  @Output() public configChanged: EventEmitter<ManagedConfig<any>> = new EventEmitter();

  public tenantId: string;
  public userId: string;

  public isLoading: boolean;
  public isSaving: boolean;
  public errorMessage: string;

  public currentConfig: ManagedConfig<any>;

  public userConfigs: UserConfig[];

  private externalConfigUpdatedSubscription: Subscription;

  constructor(
    private configStub: ConfigStub,
    private localStorage: LocalStorage,
    private saveAsDialog: SaveAsDialog,
    private configLoaderDialog: ConfigLoaderDialog,
    private authenticationService: AuthenticationService,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(){
    const userData = this.authenticationService.userDataSnapshot;
    this.tenantId = userData.tenant;
    this.userId = userData.sub;

    if(this.updateSource){
      this.externalConfigUpdatedSubscription = this.updateSource.configUpdated.subscribe((v: ManagedConfig<any>) => this.onExternalConfigUpdated(v));
    }

    if(this.persistLocally){
      this.loadPersistedConfig();
    }

    this.configChanged.subscribe((v: ManagedConfig<any>) => this.onConfigEmitted(v));
  }

  public ngOnDestroy(){
    if(this.externalConfigUpdatedSubscription){
      this.externalConfigUpdatedSubscription.unsubscribe();
    }
  }

  private onExternalConfigUpdated(newConfig: ManagedConfig<any>){
    this.currentConfig = newConfig;
    if(this.persistLocally){
      this.localStorage.setItem(this.getLocalStorageKey(), ManagedConfig.toMutable(this.currentConfig));
    }
  }

  private onConfigEmitted(newConfig: ManagedConfig<any>){
    if(this.persistLocally){
      this.localStorage.setItem(this.getLocalStorageKey(), ManagedConfig.toMutable(newConfig));
    }
  }

  private getLocalStorageKey(){
    let key = `current_${this.configType}`;
    if(this.subTreePath){
      key = key + '_' + this.subTreePath;
    }

    return key;
  }

  public loadIfRequired(){
    if(this.userConfigs || this.isLoading){
      return;
    }

    this.loadUserConfigs();
  }

  public async loadPersistedConfig(){
    try{
      let loadedConfig = ManagedConfig.fromMutable(this.localStorage.getItem(this.getLocalStorageKey()));

      if(loadedConfig){
        loadedConfig = await this.upgradeConfigIfRequired(loadedConfig);
      }

      this.currentConfig = loadedConfig;
      this.configChanged.emit(this.currentConfig);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private async upgradeConfigIfRequired(config: ManagedConfig<any>): Promise<ManagedConfig<any>>{
    if(this.simVersion && config.simVersion !== this.simVersion){
      let upgradeResult = await this.configStub.upgradeConfig(
        this.tenantId,
        this.simVersion,
        {
          configType: this.configType,
          config: config.getConfigCopy(),
          simVersion: config.simVersion
        },
        this.subTreePath);

      config = ManagedConfig.copy(config, v => {
        v.config = upgradeResult.config;
        v.simVersion = this.simVersion;
      });
    }

    return config;
  }

  public async loadUserConfig(item: UserConfig) {
    try {
      this.isLoading = true;
      let configResult = await this.configStub.getConfig(
        this.tenantId,
        item.configId,
        this.subTreePath,
        this.simVersion);

      this.currentConfig = new ManagedConfig(
        configResult.config.name,
        configResult.config.userId,
        configResult.config.documentId,
        configResult.config.data,
        false,
        this.simVersion);
      this.configChanged.emit(this.currentConfig);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoading = false;
  }

  private async loadUserConfigs(){
    try{
      this.isLoading = true;
      this.errorMessage = undefined;
      let results = await this.configStub.getConfigNames(
        this.tenantId,
        this.configType,
        DocumentNamesResultType.matchedNameAndIdAndOwner,
        this.subTreePath,
        this.simVersion);

      this.userConfigs = results.names
        .filter(v => v.userId === this.userId)
        .map(v => ({ name: v.name, userId: v.userId, configId: v.configId }));
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoading = false;
  }

  public get isEdited(){
    return !!(this.currentConfig && this.currentConfig.isEdited);
  }

  public get canSave(){
    return !!(this.currentConfig && this.currentConfig.configId && this.currentConfig.userId === this.userId
      && !this.isSaving);
  }

  public get canSaveAs(){
    return !!(this.currentConfig && this.currentConfig.hasConfig
      && !this.isSaving);
  }

  public async save(){
    try {
      if(!this.canSave){
        return;
      }

      this.isSaving = true;
      await this.configStub.putConfig(
        this.tenantId,
        this.currentConfig.configId,
        {
          name: this.currentConfig.name,
          configType: this.configType,
          config: this.currentConfig.getConfigCopy(),
          simVersion: this.simVersion
        },
        this.subTreePath);

      this.currentConfig = ManagedConfig.copy(this.currentConfig, v => {
        v.isEdited = false;
      });
      this.configChanged.emit(this.currentConfig);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isSaving = false;
  }

  public async saveAs(){
    try {
      if(!this.canSaveAs){
        return;
      }

      this.isSaving = true;
      let result = await this.saveAsDialog.showForContent(
        this.currentConfig.name,
        this.configType,
        this.simVersion,
        this.subTreePath,
        this.currentConfig.getConfigCopy());

      if(result) {
        this.currentConfig = new ManagedConfig(
          result.config.name,
          result.userId,
          result.configId,
          result.config.data,
          false,
          this.simVersion);

        this.configChanged.emit(this.currentConfig);

        this.loadUserConfigs();
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    this.isSaving = false;
  }

  public async viewAll(){
    try {
      let result = await this.configLoaderDialog.loadConfig(
        this.configType,
        this.simVersion,
        this.subTreePath);

      if(result){
        this.currentConfig = new ManagedConfig(
          result.config.name,
          result.userId,
          result.configId,
          result.config.data,
          false,
          this.simVersion);

        this.configChanged.emit(this.currentConfig);
      }

      // The user may have deleted configs while in the dialog.
      this.loadUserConfigs();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

interface UserConfig {
  name: string;
  userId: string;
  configId: string;
}

export class ManagedConfig<TConfig> {
  constructor(
    private _name: string,
    private _userId: string,
    private _configId: string,
    private _config: TConfig,
    private _isEdited: boolean,
    private _simVersion?: string){
    this._config = clone(this._config);
  }

  public get name(): string {
 return this._name;
}
  public get userId(): string {
 return this._userId;
}
  public get configId(): string {
 return this._configId;
}
  public getConfigCopy(): TConfig {
 return clone(this._config);
}
  public get hasConfig(): boolean {
 return !!this._config;
}
  public get isEdited(): boolean {
 return this._isEdited;
}
  public get simVersion(): string {
 return this._simVersion;
}

  public static toMutable<TConfig>(input: ManagedConfig<TConfig>){
    if(!input){
      return undefined;
    }

    return {
      name: input.name,
      userId: input.userId,
      configId: input.configId,
      config: input.getConfigCopy(),
      isEdited: input.isEdited,
      simVersion: input.simVersion
    };
  }

  public static fromMutable(input: any){
    if(!input){
      return undefined;
    }

    return new ManagedConfig(
        input.name,
        input.userId,
        input.configId,
        input.config,
        input.isEdited,
        input.simVersion);
  }

  public static copy<TConfig>(input: ManagedConfig<TConfig>, change: (v: any) => void){
    if(!input){
      return undefined;
    }

    let mutable = ManagedConfig.toMutable(input);
    change(mutable);
    return ManagedConfig.fromMutable(mutable);
  }
}

export interface IConfigSource {
  configUpdated: EventEmitter<ManagedConfig<any>>;
}
