import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalStorage } from '../common/local-storage.service';
import { ConfirmationDialog } from '../common/dialogs/confirmation-dialog.service';
import { Modal } from 'bootstrap';

@Component({
    selector: 'cs-survey-message',
    templateUrl: './survey-message.component.html',
    standalone: false
})
export class SurveyMessageComponent implements OnInit {
  canSurvey: boolean;

  private readonly surveyKey = 'survey-dismissed';
  private readonly newSurveyKey = 'new-survey-dismissed';

  @ViewChild('scriptContainer') element: ElementRef<HTMLSpanElement>;
  @ViewChild('modal') modalRef: ElementRef<HTMLDivElement>;

  constructor(
    private readonly localStorage: LocalStorage,
    private readonly confirmationDialog: ConfirmationDialog) {}

  ngOnInit(): void {
    let surveyDismissed = this.localStorage.getItem(this.surveyKey);
    if(surveyDismissed){
      //Remove old key to avoid clutter
      this.localStorage.removeItem(this.surveyKey);
    }

    // Disable dismissal removal for now
    // let newSurveyDismissed = this.localStorage.getItem(this.newSurveyKey);
    // if(newSurveyDismissed){
    //   let date = new Date(newSurveyDismissed);
    //   //Remove 365 days after setting
    //   if(date && date.getTime() + 1000 * 60 * 60 * 24 * 365 < new Date().getTime()){
    //     this.localStorage.removeItem(this.newSurveyKey);
    //   }
    // }
    this.canSurvey = !this.localStorage.getItem(this.newSurveyKey) && (new Date() <= new Date(2025, 9, 1));
  }

  async setDismissed(){
    if(await this.confirmationDialog.show(
      `Dismissing will hide until the next survey period.`,
      'Dismiss survey?',
      `Dismiss`,
      'Cancel')){
        this.localStorage.setItem(this.newSurveyKey, new Date().toISOString());
        this.canSurvey = false;
      }
  }

  showSurvey(){
    let script = document.createElement('script');
    script.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd1dFyoSzl_2BM_2BPY8Q83FxM3VeTDrxfx0LilxxS27_2BYF87.js"
    this.element.nativeElement.appendChild(script);
    new Modal(this.modalRef.nativeElement).show();
    this.localStorage.setItem(this.newSurveyKey, new Date().toISOString());
    this.canSurvey = false;
  }
}
