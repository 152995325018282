import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {PromptAndSetOwnership, SET_OWNERSHIP_ERROR_TITLE} from '../prompt-and-set-ownership';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';

@Component({
    selector: 'cs-set-ownership-dialog-launcher',
    templateUrl: './set-ownership-dialog-launcher.component.html',
    styleUrls: ['./set-ownership-dialog-launcher.component.scss'],
    standalone: false
})
export class SetOwnershipDialogLauncherComponent implements OnInit {
  @Input() public compactButton: boolean = false;
  @Input() public documentId: string;
  @Input() public documentTenantId: string;
  @Input() public documentUserId: string;
  @Output() public ownershipChanged: EventEmitter<OwnershipChangedEvent> = new EventEmitter<OwnershipChangedEvent>();

  public userData: UserData;
  public errorMessage: string;
  public showButton: boolean;

  constructor(
    private readonly prompt: ConfirmationDialog,
    private readonly promptAndSetOwnership: PromptAndSetOwnership,
    private readonly authenticationService: AuthenticationService,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) { }

  ngOnInit() {
    try {
      this.userData = this.authenticationService.userDataSnapshot;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      this.userData = undefined;
      return;
    }
    this.showButton =
      !!(!this.errorMessage
        && this.userData
        && this.userData.tenant === this.documentTenantId
        && this.userData.sub !== this.documentUserId);
  }

  public async execute() {
    try {
      const success = await this.promptAndSetOwnership.execute(this.documentTenantId, this.documentId, this.userData.sub);
      if (success){
        this.ownershipChanged.emit(new OwnershipChangedEvent(this.userData.sub));
      }
    } catch(error){
      const message = this.getFriendlyErrorAndLog.execute(error);
      await this.prompt.show(message, SET_OWNERSHIP_ERROR_TITLE);
    }
  }
}

export class OwnershipChangedEvent {
  constructor(public readonly userId: string){
  }
}
