import {
  Component,
  Directive,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewEncapsulation
} from '@angular/core';
import {
  ConditionSource,
  DocumentCustomPropertyGroup,
  DocumentSubType,
  GetTenantUsersQueryResult,
  ListFilterData,
  ListFilterGroup,
  OrderByProperty,
  TenancyStub
} from '../../../generated/api-stubs';
import {CanopyValidators} from '../../common/forms/canopy-validators.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {AbstractControl, ControlContainer, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {cssSanitize} from '../../common/css-sanitize';
import {IConfigSource, ManagedConfig} from '../configs/quick-config-manager/quick-config-manager.component';
import {CanopyJson} from '../../common/canopy-json.service';
import {
  ConditionSourceItemSetViewModel,
  ConditionSourceItemViewModel,
  ConditionSourceViewModel,
  IFilterQueryBuilderDataAndFilterSource
} from './filter-query-builder-types';
import {QueryChangedEvent, ResolvedFilterQuery} from './filter-query-builder.component';
import {
  ListFilterQueryBuilderDataSource,
  ListFilterQueryBuilderDataSourceFactory
} from './list-filter-query-builder-data-source.service';
import {UpgradeListFilterForComplexQueries} from './upgrade-list-filter-for-complex-queries.service';
import { LIST_FILTERING_DOCUMENTATION_URL } from '../../common/constants';
import { AuthenticationService } from '../../identity/state/authentication.service';

// https://github.com/angular/angular/issues/11379#issuecomment-246756547
@Directive({
    selector: '[formControlName][csDynamicDisable]',
    standalone: false
})
export class DynamicDisableDirective implements OnInit, OnChanges {

  public LIST_FILTERING_DOCUMENTATION_URL: string = LIST_FILTERING_DOCUMENTATION_URL;

  constructor(
    @Optional() @Host() @SkipSelf() private parent: ControlContainer,
  ) {

  }

  @Input() formControlName: string;
  @Input('csDynamicDisable') shouldDisable: boolean;

  private ctrl: AbstractControl;

  ngOnInit() {
    if(this.parent && (this.parent as any)['form']) {
      this.ctrl = (<UntypedFormGroup>(this.parent as any)['form']).get(this.formControlName);
    }
  }

  ngOnChanges() {
    if (!this.ctrl) {
      return;
    }

    if (this.shouldDisable) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }
}

@Component({
    selector: 'cs-list-filter',
    templateUrl: './list-filter.component.html',
    styleUrls: ['./list-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ListFilterComponent implements OnInit, OnDestroy, IConfigSource, IFilterQueryBuilderDataAndFilterSource {
  @Input() public initialFilter: ListFilterData;
  @Input() public loadGroupResults: () => Promise<GroupResults>;
  @Input() public disableForm: boolean;
  @Input() public tenantId: string;
  @Input() public filterConfigType: DocumentSubType;
  @Input() public canPersistFilter: boolean = false;
  @Output() public filterChanged: EventEmitter<ListFilterData> = new EventEmitter();

  public groupResultsTask: Promise<GroupResults>;
  public groupResults: GroupResults;

  public configUpdated: EventEmitter<ManagedConfig<ListFilterData>> = new EventEmitter();
  public managedConfig: ManagedConfig<ListFilterData>;

  public form: UntypedFormGroup;
  public name: UntypedFormControl = new UntypedFormControl('', CanopyValidators.configNameValidators);
  public errorMessage: string;

  public includeIfDeleteRequested: boolean;
  public includeIfHasParentWorksheet: boolean;

  public isLoaded: boolean;
  public showFilters: boolean;
  public showSorting: boolean;
  public currentFilter: string;
  public isStudy: boolean;
  public isStudyJobs: boolean;

  public currentFilterQuery: ResolvedFilterQuery;
  public isCurrentFilterQueryApplied: boolean;

  public propertySortOptions: string[];

  public tenantUsers: GetTenantUsersQueryResult;

  private clearedFilter: ListFilterData;

  private listFilterQueryBuilderDataSource: ListFilterQueryBuilderDataSource;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly tenancyStub: TenancyStub,
    private readonly json: CanopyJson,
    private readonly listFilterQueryBuilderDataSourceFactory: ListFilterQueryBuilderDataSourceFactory,
    private readonly upgradeListFilterForComplexQueries: UpgradeListFilterForComplexQueries,
    private readonly authenticationService: AuthenticationService,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){

  }

  ngOnInit(): any {
    this.load();
  }

  ngOnDestroy(): any {
    if(this.listFilterQueryBuilderDataSource){
      this.listFilterQueryBuilderDataSource.dispose();
    }
  }

  public get canBeSoftDeleted(): boolean {
    return !this.isStudyJobs;
  }

  public get canBeInWorksheet(): boolean {
    return this.showWorksheets
      && !this.isStudyJobs
      && (!this.isConfig || this.filterConfigType !== DocumentSubType.worksheetFilter);
  }

  public get showWorksheets(): boolean {
    return this.authenticationService.showWorksheets;
  }

  public get isConfig(): boolean {
    return !this.isStudy && !this.isStudyJobs;
  }

  public async load(){
    try{
      this.isStudy = this.filterConfigType === DocumentSubType.studyFilter;
      this.isStudyJobs = this.filterConfigType === DocumentSubType.jobFilter;

      this.clearedFilter = this.json.clone(this.initialFilter);
      this.name.setValue(this.initialFilter.filterName, {});

      this.form = this.formBuilder.group({
        name: this.name,
      });

      this.includeIfDeleteRequested = !!this.initialFilter.includeIfDeleteRequested;
      this.includeIfHasParentWorksheet = !!this.initialFilter.includeIfHasParentWorksheet;

      this.propertySortOptions = [
        OrderByProperty.creationDate,
        OrderByProperty.modifiedDate,
        OrderByProperty.name
      ];

      this.discardCurrentFilterQuery();

      this.tenantUsers = await this.tenancyStub.getTenantUsers(this.tenantId);

      this.listFilterQueryBuilderDataSource = this.listFilterQueryBuilderDataSourceFactory.create(
        this.filterConfigType,
        this.canPersistFilter,
        this.tenantUsers);

      await this.listFilterQueryBuilderDataSource.initialize();

      this.isLoaded = true;
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get configSource(): IConfigSource{
    return this;
  }

  public async onConfigUpdatedExternally(item: ManagedConfig<ListFilterData>){
    try{
      if(item){
        this.managedConfig = item;
        let newFilter = item.getConfigCopy();
        if(newFilter){
          if((newFilter as any)['data']){
            // Backwards compatibility.
            newFilter = (newFilter as any)['data'];
          }

          newFilter = await this.upgradeListFilterForComplexQueries.execute(newFilter);

          this.name.setValue(newFilter.filterName, {});
          this.includeIfDeleteRequested = !!newFilter.includeIfDeleteRequested;
          this.includeIfHasParentWorksheet = !!newFilter.includeIfHasParentWorksheet;
          this.emitFilter(newFilter, false);
        } else{
          this.emitFilter(this.initialFilter, false);
        }
      } else{
        this.managedConfig = new ManagedConfig<ListFilterData>(
          undefined,
          undefined,
          undefined,
          undefined,
          false);
        this.emitFilter(this.initialFilter, false);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private onConfigClearedManually(){
    if(this.managedConfig){
      this.managedConfig = new ManagedConfig<ListFilterData>(
        undefined,
        undefined,
        undefined,
        undefined,
        false);
      this.configUpdated.emit(this.managedConfig);
    }
  }

  private onConfigUpdatedManually(v: ListFilterData){
    let managedConfigData = v;
    if(!this.managedConfig){
      this.managedConfig = new ManagedConfig<ListFilterData>(
        undefined,
        undefined,
        undefined,
        managedConfigData,
        false);
      this.configUpdated.emit(this.managedConfig);
    } else if(!this.json.equals(managedConfigData, this.managedConfig.getConfigCopy())){
      this.managedConfig = ManagedConfig.copy(this.managedConfig, c => {
        c.isEdited = true;
        c.config = managedConfigData;
      });
      this.configUpdated.emit(this.managedConfig);
    }
  }

  public refresh(){
    try{
      this.emitFilter(this.initialFilter, false);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadGroupResultsInBackground(){
    try{
      if(this.groupResultsTask || !this.loadGroupResults){
        return;
      }

      this.groupResultsTask = this.loadGroupResults();
      this.groupResults = await this.groupResultsTask;
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public toggleShowFilters(){
    try{
      this.currentFilter = undefined;
      this.showSorting = false;
      this.showFilters = !this.showFilters;
      if(this.showFilters) {
        this.loadGroupResultsInBackground();
      } else{
        this.discardCurrentFilterQuery();
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public toggleShowSorting(){
    try{
      this.currentFilter = undefined;
      this.showFilters = false;
      this.showSorting = !this.showSorting;
      if(this.showSorting){
        this.loadGroupResultsInBackground();
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private closeAllFilters(){
    this.currentFilter = undefined;
    this.showSorting = false;
    this.showFilters = false;
  }

  public clearFilter(){
    try{
      let cleared: ListFilterData = this.json.clone(this.clearedFilter);
      this.name.setValue(cleared.filterName, {});
      this.includeIfDeleteRequested = false;
      this.includeIfHasParentWorksheet = false;
      this.emitFilter(cleared, false);
      this.onConfigClearedManually();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public filterByQuery(query: ListFilterGroup){
    try{
      let clone = this.getClone();
      clone.query = query;
      this.emitFilter(clone);
      this.isCurrentFilterQueryApplied = true;
      //this.closeAllFilters();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public discardCurrentFilterQuery(){
    try{
      this.currentFilterQuery = new ResolvedFilterQuery(true, this.initialFilter.query);
      this.isCurrentFilterQueryApplied = true;
      this.closeAllFilters();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public toggleIncludeIfDeleteRequested(){
    this.includeIfDeleteRequested = !this.includeIfDeleteRequested;
    this.handleFilterControlsChanged();
  }

  public toggleIncludeIfHasParentWorksheet(){
    this.includeIfHasParentWorksheet = !this.includeIfHasParentWorksheet;
    this.handleFilterControlsChanged();
  }

  public filterName() {
    this.handleFilterControlsChanged();
  }

  public handleFilterControlsChanged(){
    try{
      let clone = this.getClone();
      clone.filterName = this.name.value || undefined;

      if(this.includeIfDeleteRequested){
        clone.includeIfDeleteRequested = true;
      } else{
        delete clone.includeIfDeleteRequested;
      }

      if(this.includeIfHasParentWorksheet){
        clone.includeIfHasParentWorksheet = true;
      } else{
        delete clone.includeIfHasParentWorksheet;
      }

      this.emitFilter(clone);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public togglePropertyOrdering(name: OrderByProperty){
    try{
      let clone = this.getClone();
      clone.orderByCustomProperty = undefined;
      if(clone.orderByProperty === name){
        clone.orderByDescending = !clone.orderByDescending;
      } else{
        clone.orderByProperty = name;
        clone.orderByDescending = false;
      }
      this.emitFilter(clone);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public toggleCustomPropertyOrdering(name: string){
    try{
      let clone = this.getClone();
      clone.orderByProperty = undefined;
      if(clone.orderByCustomProperty === name){
        clone.orderByDescending = !clone.orderByDescending;
      } else{
        clone.orderByCustomProperty = name;
        clone.orderByDescending = false;
      }
      this.emitFilter(clone);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private getClone(): ListFilterData {
    return this.json.clone(this.initialFilter);
  }

  private emitFilter(newFilter: ListFilterData, persist: boolean = true){
    delete newFilter.itemsPerPage;
    delete newFilter.continuationToken;
    this.currentFilterQuery = new ResolvedFilterQuery(true, newFilter.query);
    this.isCurrentFilterQueryApplied = true;
    this.filterChanged.emit(newFilter);

    if(persist){
      this.onConfigUpdatedManually(newFilter);
    }
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public get currentQuery(): ListFilterGroup{
    return this.initialFilter.query;
  }

  public get filterQueryBuilderDataSource(): IFilterQueryBuilderDataAndFilterSource {
    return this;
  }

  public getConditionSourceViewModel(source: ConditionSource): ConditionSourceViewModel {
    return this.listFilterQueryBuilderDataSource.getConditionSourceViewModel(source);
  }

  public getConditionSourceItemViewModel(source: ConditionSource, name: string): ConditionSourceItemViewModel{
    return this.listFilterQueryBuilderDataSource.getConditionSourceItemViewModel(source, name, this.groupResults);
  }

  public getConditionSources(): ReadonlyArray<ConditionSourceViewModel> {
    return this.listFilterQueryBuilderDataSource.getConditionSources(this.filterConfigType);
  }

  public getConditionSourceItems(conditionSourceId: string): ConditionSourceItemSetViewModel {
    return this.listFilterQueryBuilderDataSource.getConditionSourceItems(conditionSourceId, this.groupResults);
  }

  public get hasConditionSourceItemsChanged(): boolean {
    return this.listFilterQueryBuilderDataSource.hasConditionSourceItemsChanged;
  }

  public onQueryChanged(event: QueryChangedEvent){
    this.currentFilterQuery = new ResolvedFilterQuery(
      event.isValid,
      event.isValid
        ? event.root
        : (this.currentFilterQuery ? this.currentFilterQuery.root : null));

    this.isCurrentFilterQueryApplied = event.isValid && JSON.stringify(event.root || {}) === JSON.stringify(this.initialFilter.query || {});

    //if(event.isValid){
      //this.filterByQuery(event.root);
    //}
  }

  public get canShowFiltersAndSorting(): boolean{
    return this.isLoaded && !!this.groupResults;
  }
}

export type GroupResults = DocumentCustomPropertyGroup[];
