import {Component, Input, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CanopyError} from '../../../common/errors/errors';
import {ConfigStub, DocumentSubType, SimType, StudyStub} from '../../../../generated/api-stubs';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {ConfigContext} from '../runner/config-context';
import {LintingEngineFactory} from '../linting-engine-factory';
import {IContextDataLoader} from '../runner/context-data-loader';
import {getJobIdFromJobIndex} from '../../../common/get-job-id-from-job-index';
import {SimContext} from '../runner/sim-context';
import {CanopyFileLoader} from '../../visualizations/canopy-file-loader.service';
import {getJobIndexFromJobId} from '../../../common/get-job-index-from-job-id';
import {ExecutionResult} from '../runner/execution-result';
import {RuleContextBase} from '../runner/rule-context-base';
import {RuleLogLevel} from '../runner/rule-log-level';
import {ConfigTypeLookup} from '../../configs/config-types';
import {Timer} from '../../../common/timer.service';
import {LogError} from '../../../common/log-error.service';

@Component({
    selector: 'cs-linting-engine-runner',
    templateUrl: './linting-engine-runner.component.html',
    styleUrls: ['./linting-engine-runner.component.scss'],
    standalone: false
})
export class LintingEngineRunnerComponent implements OnInit, IContextDataLoader {

  @Input() public tenantId: string;

  @Input() public configId: string;

  @Input() public configData: any;
  @Input() public configType: DocumentSubType;

  @Input() public studyId: string;
  @Input() public jobId: string;

  public ResultStatus = ResultStatus;
  public RuleLogLevel = RuleLogLevel;

  public errorMessage: string;
  private fileLoader: CanopyFileLoader;
  public tasks: LintingTask[] = [];
  public status: ResultStatus = ResultStatus.pending;
  public loaded: boolean;

  constructor(
    private readonly configStub: ConfigStub,
    private readonly studyStub: StudyStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly lintingEngineFactory: LintingEngineFactory,
    private readonly timer: Timer,
    private readonly logError: LogError,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      if(!this.tenantId){
        throw new CanopyError('TenantId is required.');
      }

      if(!this.configData && !this.configId && !this.studyId){
        throw new CanopyError('Either a ConfigId, StudyId or config data is required.');
      }

      if(this.configData && !this.configType){
        throw new CanopyError('A config type must be supplied for a config.');
      }

      if (this.studyId && !this.jobId) {
        this.jobId = getJobIdFromJobIndex(this.studyId, 0);
      }

      this.loaded = true;

      await this.timer.yield();

      this.run();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async run() {
    try {
      this.tasks = [];
      this.errorMessage = undefined;
      this.status = ResultStatus.running;

      if (this.configId) {
        await this.populateConfigTasks();
      } else if (this.studyId) {
        await this.populateStudyTasks();
      } else if(this.configData){
        await this.populateConfigDataTasks();
      }

      const lintingEngine = this.lintingEngineFactory.create(this);

      for(let task of this.tasks){
        try {
          task.status = ResultStatus.running;
          task.result = await lintingEngine.execute(task.context);

          for(let ruleError of task.result.ruleErrors || []){
            this.logError.execute(ruleError);
          }

          task.status = ResultStatus.completed;
        } catch(error) {
          task.status = ResultStatus.failed;
          task.errorMessage = this.getFriendlyErrorAndLog.execute(error);
        }
      }

      this.status = ResultStatus.completed;
    } catch(error){
      this.status = ResultStatus.failed;
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public toggleDetails(task: LintingTask) {
    task.showDetails = !task.showDetails;
  }

  private getConfigTypeName(configType: string): string {
    const configTypeMetadata = ConfigTypeLookup.get(configType);
    return configTypeMetadata ? configTypeMetadata.titleName : configType;
  }

  public async populateConfigDataTasks() {
    const configContext = new ConfigContext(this.configType, this.configData);
    this.tasks.push(new LintingTask(ExecutionType.config, this.getConfigTypeName(configContext.configType), configContext));
  }

  public async populateConfigTasks() {
    const configResult = await this.configStub.getConfig(
      this.tenantId,
      this.configId,
      undefined,
      this.getSimVersion.currentSimVersion);


    const configContext = new ConfigContext(configResult.config.subType, configResult.config.data);
    this.tasks.push(new LintingTask(ExecutionType.config, this.getConfigTypeName(configContext.configType), configContext));
  }

  public async populateStudyTasks() {
    const simVersion = this.getSimVersion.currentSimVersion;
    const studyMetadataResult = await this.studyStub.getStudyMetadata(
      this.tenantId,
      this.studyId);
    const studyJobResult = await this.studyStub.getStudyJob(
      this.tenantId,
      this.studyId,
      this.jobId,
      simVersion);

    this.fileLoader = new CanopyFileLoader(this.studyStub, this.timer);
    this.fileLoader.addStudy(this.tenantId, this.studyId, studyMetadataResult.accessInformation, simVersion);

    const jobContext = new SimContext('job', studyJobResult.studyJobInput);
    for (let configType of jobContext.getConfigTypes()) {
      let configContext = jobContext.getConfigContext(configType);
      if (configContext && configContext.data.constructor === Object && Object.keys(configContext.data).length > 0) {
        this.tasks.push(new LintingTask(ExecutionType.config, this.getConfigTypeName(configType), configContext));
      }
    }

    for(let simType of studyJobResult.simTypes) {
      const configContext = new SimContext(simType, jobContext.data);
      this.tasks.push(new LintingTask(ExecutionType.simulation, simType, configContext));
    }
  }

  public async loadChannel(channelName: string, simType: string): Promise<ReadonlyArray<number>> {
    if(!this.fileLoader){
      return undefined;
    }

    return await this.fileLoader.loadChannelDataAndBinaryFormat(this.studyId, getJobIndexFromJobId(this.jobId), <SimType>simType, channelName);
  }
}

export class LintingTask {

  public status: ResultStatus;
  public result: ExecutionResult;
  public errorMessage: string;
  public showDetails: boolean = false;

  constructor(
    public readonly executionType: ExecutionType,
    public readonly name: string,
    public readonly context: RuleContextBase){
    this.status = ResultStatus.pending;
  }
}

export enum ResultStatus {
  pending = 'Pending',
  running = 'Running',
  completed = 'Completed',
  failed = 'Failed'
}

export enum ExecutionType {
  config = 'Config',
  simulation = 'Simulation',
}
