import {Component, ElementRef, HostBinding, Input } from '@angular/core';
import {StudyViewModel} from '../study-view-model';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {WorksheetItemComponentBase} from '../worksheet-item-component-base';
import {HashValidity} from '../hash-validity';
import {CellElementToViewModelLookup} from '../cell-element-to-view-model-lookup';
import {StudyContextMenuBuilder} from '../worksheet-commands/study-context-menu-builder';
import { AuthenticationService } from '../../identity/state/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { RowItemUrlService } from '../worksheet-commands/row-item-url.service';
import { StudyDocument } from '../../../generated/api-stubs';
import { Dayjs } from '../../common/dayjs.service';

/**
 * Component for displaying a study item in a worksheet.
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
    selector: '[cs-worksheet-study-item]',
    templateUrl: './worksheet-study-item.component.html',
    styleUrls: ['./worksheet-study-item.component.scss'],
    standalone: false
})
export class WorksheetStudyItemComponent extends WorksheetItemComponentBase {

  /**
   * The view model for the study item.
   */
  @Input() public viewModel: StudyViewModel;

  /**
   * Gets the study document for the study item.
   */
  public get study(): StudyDocument {
    return this.viewModel.populated.resolvedReference?.data?.studyDocument;
  }

  /**
   * Gets the creation date for the study item.
   */
  protected get creationDate(): string {
    return this.dayjs.toDateTime(this.viewModel.populated?.resolvedReference?.data?.creationDate);
  }

  /**
   * Gets the relative time of the creation date for the study item.
   */
  protected get fromCreationDate(): string {
    return this.dayjs.fromNow(this.viewModel.populated?.resolvedReference?.data?.creationDate);
  };

  /**
   * The hash validity enum.
   */
  public HashValidity = HashValidity;

  /**
   * Creates a new instance of WorksheetStudyItemComponent.
   * @param authenticationService The authentication service.
   * @param cellElementToViewModelLookup The cell element to view model lookup.
   * @param elementRef The element reference.
   * @param contextMenuBuilder The context menu builder.
   * @param getFriendlyErrorAndLog The service for getting friendly error messages and logging the error.
   * @param rowItemUrl The row item URL service.
   * @param route The activated route.
   * @param dayjs The dayjs service.
   */
  constructor(
    authenticationService: AuthenticationService,
    cellElementToViewModelLookup: CellElementToViewModelLookup,
    elementRef: ElementRef,
    contextMenuBuilder: StudyContextMenuBuilder,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    rowItemUrl: RowItemUrlService,
    route: ActivatedRoute,
    private readonly dayjs: Dayjs,
    ) {
    super(authenticationService, cellElementToViewModelLookup, elementRef, contextMenuBuilder, getFriendlyErrorAndLog, rowItemUrl, route);
  }

  /**
   * Gets whether the study matches the currently selected study.
   */
  @HostBinding('class.matches-selected') public get matchesSelected(): boolean {
    return this.viewModel.matchesSelectedConfig;
  }

  /**
   * Gets whether the study is selected.
   */
  @HostBinding('class.is-selected') public get isSelected(): boolean {
    return this.viewModel.isSelected;
  }

  /**
   * The string version of the study reference count.
   */
  public get referenceCountString(): string {
    let referenceMetadata = this.viewModel.referenceMetadata;
    if(!referenceMetadata){
      return '';
    }

    // Studies can be in the study column or the telemetry column, so we need to show both counts.
    return this.getDualReferenceCountString(referenceMetadata.studyCount, referenceMetadata.configCount);
  }

  /**
   * Gets whether the study is read only.
   */
  public get isStudyReadOnly(): boolean {
    return this.viewModel.isReadOnly(this.userData.sub);
  }
}
