import {Component, OnInit} from '@angular/core';
import {
  PoolStub, GetPoolsQueryResult
} from '../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {sortBy} from '../common/sort-by';

@Component({
    selector: 'cs-pools',
    templateUrl: './pools.component.html',
    styleUrls: ['./pools.component.scss'],
    standalone: false
})
export class PoolsComponent implements OnInit {

  public poolsResult: GetPoolsQueryResult;
  public errorMessage: string;

  constructor(
    private poolStub: PoolStub,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.poolsResult = await this.poolStub.getPools();
      this.poolsResult.pools.sort(sortBy('poolId'));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}
