import {Component} from '@angular/core';
import {InferableRouteParams} from '../../../common/inferable-route-params.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StudySummary} from '../view-studies/view-studies.component';


@Component({
    templateUrl: './view-studies.page.html',
    styleUrls: ['./view-studies.page.scss'],
    standalone: false
})
export class ViewStudiesPage {

  public tenantId: string;
  public userId: string;

  constructor(
    route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams,
    private readonly router: Router) {
    this.tenantId = inferableRouteParams.getTenantId(route);
    this.userId = inferableRouteParams.getUserId(route);
  }

  public studySelected(study: StudySummary) {
    this.router.navigate(['/studies', study.tenantId, study.studyId]);
  }
}
