import {OnInit, OnDestroy, Input} from '@angular/core';
import {Component} from '@angular/core';
import {
  StudyStub, StudyJobState, SimType
} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CanopyJson, FlattenedJsonItem} from '../../../common/canopy-json.service';
import {ResultsStagingArea} from '../../results-staging-area/results-staging-area.service';
import {ResultSource} from '../../results-staging-area/results-staging-area.service';
import {GetStudyQueryResult} from '../../../../generated/api-stubs';
import {GetStudyJobQueryResult} from '../../../../generated/api-stubs';
import {CanopyPusher, StudiesProgress} from '../../../common/canopy-pusher.service';
import {Disposable} from '../../../common/disposable';
import {DownloadStudy} from '../../studies/download-study.service';
import {JobViewModel, JobViewModelFactory} from '../job-results/job-view-model';
import {Timer} from '../../../common/timer.service';
import {StageStudy} from '../../studies/stage-study.service';
import {getJobIndexFromJobId} from '../../../common/get-job-index-from-job-id';
import {SignificantSimVersions} from '../../../common/significant-sim-versions.service';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {Subscription} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import { DownloadFile } from '../../../common/download-file.service';

export const POST_PROCESSOR_JOB_NAME = 'Post Processor';

@Component({
    selector: 'cs-view-job',
    templateUrl: './view-job.component.html',
    styleUrls: ['./view-job.component.scss'],
    standalone: false
})
export class ViewJobComponent implements OnInit, OnDestroy {
  @Input() public tenantId: string;
  @Input() public studyId: string;
  @Input() public jobId: string;
  @Input() public hideBackButton: boolean;
  @Input() public lazyLoadCharts: boolean;
  @Input() public studyResultTask?: Promise<GetStudyQueryResult>;

  public errorMessage: string;

  public isLoaded: boolean;

  public studyName: string;
  public studyJobCount: number;
  public job: JobSummary;
  public jobJson: any;
  public jobViewModel: JobViewModel;

  public userId: string;

  public studiesProgressSubscription: Subscription;
  public loadTask: Promise<any>;
  public viewer: Disposable;

  public dashboardId: string;

  public downloadToken: SafeResourceUrl;

  constructor(
    private readonly studyStub: StudyStub,
    private readonly json: CanopyJson,
    private readonly resultsStagingArea: ResultsStagingArea,
    private readonly timer: Timer,
    private readonly canopyPusher: CanopyPusher,
    private readonly jobViewModelFactory: JobViewModelFactory,
    private readonly stageStudy: StageStudy,
    public readonly significantSimVersions: SignificantSimVersions,
    private readonly downloadStudy: DownloadStudy,
    private readonly getSimVersion: GetSimVersion,
    private readonly downloadFile: DownloadFile,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    this.dashboardId = 'dashboard-' + ('' + Math.random()).substring(2);
  }

  public ngOnInit() {
    this.studiesProgressSubscription = this.canopyPusher.studiesProgress.subscribe((data: StudiesProgress) => this.onStudiesProgress(data));
    this.loadTask = this.load();
  }

  public ngOnDestroy() {
    if (this.studiesProgressSubscription) {
      this.studiesProgressSubscription.unsubscribe();
    }
    if(this.viewer){
      this.viewer.dispose();
    }
  }

  public async load() {
    try {
      this.isLoaded = false;

      let jobResultTask = this.studyStub.getStudyJob(this.tenantId, this.studyId, this.jobId, this.getSimVersion.currentSimVersion);
      let studyResult: GetStudyQueryResult;
      if(this.studyResultTask) {
        studyResult = await this.studyResultTask;
      } else {
        studyResult = await this.studyStub.getStudy(this.tenantId, this.studyId, this.getSimVersion.currentSimVersion);
      }

      let jobResult = await jobResultTask;

      this.userId = jobResult.studyJob.userId;
      this.jobViewModel = this.jobViewModelFactory.createFromData(jobResult, studyResult);

      this.studyName = studyResult.study.name;
      this.studyJobCount = studyResult.study.data.jobCount;
      let isPostProcessor = !jobResult.studyJobInput;

      this.setJobSummary(studyResult, jobResult, isPostProcessor);
      this.isLoaded = true;

      this.downloadToken = this.downloadStudy.getStudyJobTokenUri(
        this.tenantId,
        this.studyId,
        this.jobId,
        studyResult.study.name,
        jobResult.studyJob.name);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async reload() {
    this.isLoaded = false;
    this.job = undefined;
    this.jobViewModel = undefined;
    await this.timer.yield();

    this.loadTask = this.load();
    await this.loadTask;
  }

  public setJobSummary(studyResult: GetStudyQueryResult, jobResult: GetStudyJobQueryResult, isPostProcessor: boolean) {
    let j = jobResult.studyJob;
    this.jobJson = this.json.stringify(j);

    let flattenedDefinition: FlattenedJsonItem[] = [];
    if(j.data.definition){
      flattenedDefinition = this.json.flatten(j.data.definition);
    } else if(j.data.changes) {
      flattenedDefinition = j.data.changes.map(v => ({ key: v.path, value: v.savedConfig ? v.savedConfig.name : v.value }));
    }

    this.job = new JobSummary(
      j.name,
      j.data.state === StudyJobState.successful,
      j.data.state === StudyJobState.failed,
      studyResult.study.data.jobCount === 1,
      flattenedDefinition,
      jobResult,
      studyResult,
      j.documentId.substring(studyResult.study.documentId.length + 1),
      isPostProcessor
    );
  }

  public stageToResultsStagingArea() {
    try {
      this.resultsStagingArea.addSource(
        new ResultSource(this.tenantId, this.studyId, this.studyName, this.jobId, this.job.name));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async stageToStudyStagingArea() {
    try {
      await this.stageStudy.toStudyStagingArea(
        this.tenantId, this.studyId, this.studyName, this.studyJobCount, getJobIndexFromJobId(this.jobId));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public downloadJob(channelsAsCsv: boolean, simTypeChannels?: SimType) {
    return this.downloadStudy.downloadJob(
      this.tenantId, this.studyId, this.jobId,
      this.job.studyResult.study.name,
      this.job.name,
      channelsAsCsv, simTypeChannels);
  }

  public async downloadJobScalarResults(simType: SimType){
    let ai = this.job.jobResult.accessInformation;
    let filename = simType + '_ScalarResults.csv'
    // cross origin downloads aren't allowed unless the sas sets the response Content-Disposition header to 'attachment'
    // instead fetch file create download of text locally
    return this.downloadFile.text(filename, await (await fetch(ai.url + filename + ai.accessSignature)).text());
  }

  public async onStudiesProgress(data: StudiesProgress){
    try {
      if(this.loadTask){
        await this.loadTask;
      }

      if(!this.job || this.job.isSuccessful || this.job.isFailed) {
        return;
      }

      for(let item of data.items) {
        if(item.studyId !== this.studyId){
          continue;
        }

        let jobIndex = this.job.jobResult.studyJob.data.index;

        if(item.successfulJobs.some(v => v.i === jobIndex)
          || item.failedJobs.some(v => v.i === jobIndex)
          || item.completedJobCount === this.job.studyResult.study.data.jobCount) {
          this.reload();
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

export class JobSummary {
  constructor(
    public name: string,
    public isSuccessful: boolean,
    public isFailed: boolean,
    public isSingleJob: boolean,
    public flattenedDefinition: FlattenedJsonItem[],
    public jobResult: GetStudyJobQueryResult,
    public studyResult: GetStudyQueryResult,
    public jobIndex: string,
    public isPostProcessor: boolean
  ){}
}
