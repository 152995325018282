import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Component, OnInit} from '@angular/core';

import {DocumentSubType, DocumentType, GetSupportSessionQueryResult, SupportSessionStub,} from '../../generated/api-stubs';
import {CreateUserInformationMaps, UserInformationMap} from '../simulations/create-user-information-maps.service';
import {ConfigType, ConfigTypeLookup} from '../simulations/configs/config-types';
import {CreateWorksheetFromStudyIfRequired} from '../worksheets/create-worksheet-from-study-if-required';
import {CreateWorksheetRowFromStudyOptions} from '../worksheets/create-worksheet-row-from-study';

@Component({
    templateUrl: './view-support-sessions.page.html',
    styleUrls: ['./view-support-sessions.page.scss'],
    standalone: false
})
export class ViewSupportSessionsPage implements OnInit {

  public errorMessage: string;
  public map: UserInformationMap;
  public documents: DocumentSummary[];

  constructor(
    private readonly supportSessionStub: SupportSessionStub,
    private readonly createUserInformationMaps: CreateUserInformationMaps,
    private readonly createWorksheetFromStudyIfRequired: CreateWorksheetFromStudyIfRequired,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.documents = undefined;
      let documentsResult = await this.supportSessionStub.getAllSupportSessions();
      this.map = this.createUserInformationMaps.execute(documentsResult.userInformation);
      this.documents = documentsResult.documents.map(v => {
        let user = this.map.users[v.userId];
        let tenant = this.map.tenants[v.tenantId];

        return new DocumentSummary(
          v.documentId,
          v.tenantId,
          v.userId,
          user ? user.username : v.userId,
          tenant ? tenant.shortName : v.tenantId,
          v.name,
          v.type,
          v.subType,
          v.type === DocumentType.config ? ConfigTypeLookup.get(v.subType) : undefined,
          v.type === DocumentType.study,
          v.type === DocumentType.config && v.subType !== DocumentSubType.worksheet,
          v.type === DocumentType.config && v.subType === DocumentSubType.worksheet,
          {
            session: v.supportSession,
            userInformation: documentsResult.userInformation
          });
      });
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async createWorksheet(document: DocumentSummary, event: MouseEvent){
    if(document.type !== DocumentType.study){
      return;
    }

    await this.createWorksheetFromStudyIfRequired.execute(
      document.tenantId,
      document.documentId,
      document.name,
      true,
      CreateWorksheetRowFromStudyOptions.fromEvent(event));
  }

  public reload(){
      return this.load();
  }
}

class DocumentSummary {
  constructor(
    public documentId: string,
    public tenantId: string,
    public userId: string,
    public username: string,
    public tenantName: string,
    public name: string,
    public type: DocumentType,
    public subType: DocumentSubType,
    public configType: ConfigType,
    public isStudy: boolean,
    public isConfig: boolean,
    public isWorksheet: boolean,
    public supportSession: GetSupportSessionQueryResult  ){}
}
