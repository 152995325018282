import {OnInit, Component} from '@angular/core';
import {FormSubmissionHandler} from '../../../common/forms/form-submission-handler.service';
import {STUDY_ID_URL_PARAMETER_KEY} from '../../../common/constants';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {FormCancellationHandler} from '../../../common/forms/form-cancellation-handler.service';
import {InferableRouteParams} from '../../../common/inferable-route-params.service';
import {CanopyValidators} from '../../../common/forms/canopy-validators.service';
import {FormSubmissionButton} from '../../../common/forms/form-submission-button';
import {setFormPristine} from '../../../common/forms/set-form-pristine-hack';
import {StudyStub} from '../../../../generated/api-stubs';
import {CustomProperty} from '../../custom-properties/custom-properties';
import {CustomPropertyUtilities} from '../../custom-properties/custom-property-utilities';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder} from '@angular/forms';
import {RouterCanDeactivate} from '../../../common/can-deactivate';
import {ActivatedRoute} from '@angular/router';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {DocumentUpdatedEventService} from '../../../worksheets/document-updated-event.service';

@Component({
    templateUrl: './edit-study.page.html',
    standalone: false
})
export class EditStudyPage implements OnInit, RouterCanDeactivate {
  public errorMessage: string;
  public tenantId: string;
  public userId: string;
  public studyId: string;
  public isLoaded: boolean = false;

  public form: UntypedFormGroup;
  public name: UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, ...CanopyValidators.configNameValidators]));
  public notes: UntypedFormControl = new UntypedFormControl('', [...CanopyValidators.configNotesValidators]);
  public submitButton = new FormSubmissionButton('Save', 'Saving...');
  public saveSuccessful = false;
  public customProperties: CustomProperty[] = [];

  constructor(
    route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly studyStub: StudyStub,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private readonly formCancellationHandler: FormCancellationHandler,
    private readonly getSimVersion: GetSimVersion,
    private readonly documentUpdatedEventService: DocumentUpdatedEventService,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {

    this.tenantId = inferableRouteParams.getTenantId(route);
    this.userId = inferableRouteParams.getUserId(route);
    this.studyId = route.snapshot.params[STUDY_ID_URL_PARAMETER_KEY];

    this.form = this.formBuilder.group({
      name: this.name,
      notes: this.notes,
    });
  }

  //public routerCanReuse(nextInstruction:ComponentInstruction, prevInstruction:ComponentInstruction):any {
  //  return false;
  //}

  public ngOnInit() {
    this.load();
  }

  public async load(){
    try {
      let studyResult = await this.studyStub.getStudy(this.tenantId, this.studyId, this.getSimVersion.currentSimVersion);
      this.name.setValue(studyResult.study.name, {});
      this.notes.setValue(studyResult.study.notes, {});
      this.customProperties = CustomPropertyUtilities.objectToList(studyResult.study.properties);
      this.isLoaded = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public routerCanDeactivate() {
    return this.formCancellationHandler.execute(this.form);
  }

  public async onSubmit() {
    this.saveSuccessful = false;
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.saveSuccessful = true;
    }
  }

  private async submit() {
    await this.studyStub.putStudy(
      this.tenantId,
      this.studyId,
      {
        name: this.name.value,
        properties: this.customProperties,
        notes: this.notes.value,
      });

    this.documentUpdatedEventService.emit(this.tenantId, this.studyId);

    setFormPristine(this.form);
  }
}
