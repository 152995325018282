import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogError } from '../../common/log-error.service';

@Component({
    selector: 'cs-zendesk-message',
    templateUrl: './zendesk-message.page.html',
    styleUrls: ['./zendesk-message.page.scss'],
    standalone: false
})
export class ZendeskMessagePage implements OnInit {

  public readonly zendeskMessage: string;
  public readonly zendeskKind: string;

  public readonly isError: boolean;

  constructor(
    private logError: LogError,
    route: ActivatedRoute,

  ) {
    this.zendeskMessage = route.snapshot.queryParamMap.get('message');
    this.zendeskKind = route.snapshot.queryParamMap.get('kind');

    this.isError = this.zendeskKind === 'error';
}

  ngOnInit() {
    this.logZendeskMessage();
  }

  private logZendeskMessage() {
    try {
      if (this.isError) {
        let fullMessage = `Zendesk SSO Error: ${this.zendeskMessage}`;
        console.log(fullMessage);
        this.logError.execute(new Error(fullMessage));
      }
    } catch(error) {
      console.log(error);
    }
  }
}
