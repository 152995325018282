import { Component } from '@angular/core';
import { DialogComponentBase } from '../../common/dialogs/dialog-component-base';
import { DialogManager } from '../../common/dialogs/dialog-manager.service';
import { GetFriendlyErrorAndLog } from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import { DownloadReEncryptedConfigDialogSessionFactory } from './download-re-encrypted-config-dialog-session';
import { DownloadReEncryptedConfigDialogData, DownloadReEncryptedConfigResult } from './download-re-encrypted-config-dialog.service';

@Component({
    selector: 'cs-download-re-encrypted-config-dialog',
    templateUrl: './download-re-encrypted-config-dialog.component.html',
    styleUrls: ['./download-re-encrypted-config-dialog.component.scss'],
    standalone: false
})
export class DownloadReEncryptedConfigDialogComponent extends DialogComponentBase<DownloadReEncryptedConfigDialogData, DownloadReEncryptedConfigResult> {

  constructor(
    sessionFactory: DownloadReEncryptedConfigDialogSessionFactory,
    dialogManager: DialogManager,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(sessionFactory, dialogManager, dialogManager.tertiaryChanged, getFriendlyErrorAndLog);
  }

  protected getCurrentDialog(): DownloadReEncryptedConfigDialogData {
    let currentDialog = this.dialogManager.getTertiary();
    if (currentDialog && currentDialog instanceof DownloadReEncryptedConfigDialogData) {
      return currentDialog;
    }
    return undefined;
  }
}
