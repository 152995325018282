import { Component, EventEmitter, Input, Output } from '@angular/core';
import {HIGH_PRIORITY_VALUE, LOW_PRIORITY_VALUE} from '../../../../common/constants';
import { StudyStub } from '../../../../../generated/api-stubs';
import { ToastrService } from 'ngx-toastr';
import { GetFriendlyErrorAndLog } from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Component({
    selector: 'cs-set-priority',
    templateUrl: './set-priority.component.html',
    standalone: false
})
export class SetPriorityComponent {

  @Input() tenantId: string;
  @Input() studyId: string;
  @Input() priority: number;

  @Output() priorityChange: EventEmitter<number> = new EventEmitter<number>();

  public get highPriority(): boolean{
    return this.priority === this.HIGH_PRIORITY_VALUE;
  }

  public get lowPriority(): boolean{
    return this.priority === this.LOW_PRIORITY_VALUE;
  }

  constructor(
    private readonly studyStub: StudyStub,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    private readonly toastrService: ToastrService,
  ){}

  private readonly HIGH_PRIORITY_VALUE = HIGH_PRIORITY_VALUE;
  private readonly LOW_PRIORITY_VALUE = LOW_PRIORITY_VALUE;

  isSettingPriority: boolean;

  public async setHighPriority(){
    await this.setPriority(this.HIGH_PRIORITY_VALUE);
  }

  public async setLowPriority(){
    await this.setPriority(this.LOW_PRIORITY_VALUE);
  }

  private async setPriority(priority: number){
    this.isSettingPriority = true;
    try {
      await this.studyStub.putStudyPriority(this.tenantId, this.studyId, priority);
      this.priority = priority;
      this.priorityChange.emit(this.priority);
    } catch(error) {
      let friendlyError = this.getFriendlyErrorAndLog.execute(error);
      this.toastrService.error(friendlyError, 'Could not set priority');
    } finally {
      this.isSettingPriority = false;
    }
  }
}
