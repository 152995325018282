import {cssSanitize} from '../css-sanitize';
import {Component, OnInit} from '@angular/core';
import {LoadingDialogData} from './loading-dialog.service';
import {DialogManager} from './dialog-manager.service';
import {GetFriendlyErrorAndLog} from '../errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {LoadingDialogSession, LoadingDialogSessionFactory} from './loading-dialog-session';
import {Subscription} from 'rxjs';

@Component({
    selector: 'cs-loading-dialog',
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.scss'],
    standalone: false
})
export class LoadingDialogComponent implements OnInit {
  public isVisible: boolean = false;

  public dialog: LoadingDialogData<any>;
  public session: LoadingDialogSession;
  public errorMessage: string;

  private loadingCompleteSubscription: Subscription;

  constructor(
    private sessionFactory: LoadingDialogSessionFactory,
    private dialogManager: DialogManager,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit(): any {
    this.dialogManager.tertiaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public async accept(result: any) {
    if(this.dialog){
      this.dialog.resolve(result);
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public async load(){
    try {
      await this.session.load();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }

  public dialogManagerChanged() {

    let currentDialog = this.dialogManager.getTertiary();
    if(currentDialog && currentDialog instanceof LoadingDialogData){
      if(this.dialog){
        console.warn('WARNING: Loading dialog was prematurely dismissed.');
        this.dismiss();
      }

      this.errorMessage = undefined;
      this.isVisible = true;
      this.dialog = <LoadingDialogData<any>>currentDialog;
      this.session = this.sessionFactory.create(this.dialog);

      if(this.loadingCompleteSubscription){
        this.loadingCompleteSubscription.unsubscribe();
      }
      this.loadingCompleteSubscription = this.session.loadingComplete.subscribe((result: any) => this.accept(result));

      this.load();
    } else {
      this.dismiss();
      this.isVisible = false;
      this.dialog = undefined;
    }
  }
}

