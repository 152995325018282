import {Component, ViewEncapsulation} from '@angular/core';
@Component({
    selector: 'cs-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LoaderComponent {
}
