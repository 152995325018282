import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SimVersionStub, TenantSettingsStub} from '../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CURRENT_VERSION} from '../../common/constants';
import { ParseReleaseNotes, RELEASE_NOTES_FILE_NAME, ReleaseNote } from '../../common/parse-release-notes.service';

export const DEFAULT_SELECT_RELEASE_MESSAGE = 'Pin';
export const DEFAULT_SELECT_DEFAULT_MESSAGE = 'Auto Update Sim Version';

@Component({
    selector: 'cs-select-sim-version',
    templateUrl: './select-sim-version.component.html',
    styleUrls: ['./select-sim-version.component.scss'],
    standalone: false
})
export class SelectSimVersionComponent implements OnInit {

  @Input() public tenantId: string;
  @Input() public isTenantSimVersion: boolean;
  @Input() public currentSimVersion: string;
  @Input() public selectReleaseMessage: string;
  @Input() public selectDefaultMessage: string;
  @Output() public simVersionSelected: EventEmitter<SimVersionSelectedEvent> = new EventEmitter<SimVersionSelectedEvent>();

  public errorMessage: string;

  public releaseNotes: ReleaseNote[];

  public isSaving: boolean;

  constructor(
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly simVersionStub: SimVersionStub,
    private readonly parseReleaseNotes: ParseReleaseNotes,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      if(this.isTenantSimVersion) {
        let settingsResult = await this.tenantSettingsStub.getTenantSettingsSimVersion(this.tenantId);
        this.currentSimVersion = settingsResult.simVersion;
      }

      if(!this.selectReleaseMessage){
        this.selectReleaseMessage = DEFAULT_SELECT_RELEASE_MESSAGE;
      }

      if(!this.selectDefaultMessage){
        this.selectDefaultMessage = DEFAULT_SELECT_DEFAULT_MESSAGE;
      }

      let releaseNotesResult = await this.simVersionStub.getWikiDocument(CURRENT_VERSION, RELEASE_NOTES_FILE_NAME, this.tenantId);
      this.releaseNotes = this.parseReleaseNotes.execute(releaseNotesResult.document.content);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async setSimVersion(simVersion: string) {
    try {
      this.isSaving = true;
      const event = new SimVersionSelectedEvent(simVersion);
      this.simVersionSelected.emit(event);
      if(event.promise){
        await event.promise;
      }
      this.currentSimVersion = simVersion;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    } finally {
      this.isSaving = false;
    }
  }
}

export class SimVersionSelectedEvent {
  constructor(
    public readonly simVersion: string){
  }

  public promise: Promise<void>;
}
