import {Component, Input, OnInit} from '@angular/core';
import {
  GetTenantDefaultCustomPropertyNamesQueryResult, TenantSettingsStub,
  DocumentsWithProperties
} from '../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CanopyValidators} from '../../common/forms/canopy-validators.service';
import {sortBy} from '../../common/sort-by';
import {CustomProperty} from './custom-properties';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {setFormPristine} from '../../common/forms/set-form-pristine-hack';
import {CanopyAutocomplete} from '../../common/canopy-autocomplete.service';
import {Timer} from '../../common/timer.service';

@Component({
    selector: 'cs-edit-custom-properties',
    templateUrl: './edit-custom-properties.component.html',
    styleUrls: ['./edit-custom-properties.component.scss'],
    standalone: false
})
export class EditCustomPropertiesComponent implements OnInit {

  @Input() public tenantId: string;
  @Input() public target: string;
  @Input() public initialProperties: CustomProperty[];
  @Input() public resultProperties: CustomProperty[];
  @Input() public form: UntypedFormGroup;

  public errorMessage: string;
  public settingsResult: GetTenantDefaultCustomPropertyNamesQueryResult;

  public customProperties: CustomPropertyControl[];
  public nameId: number = 0;

  constructor(
    private tenantSettingsStub: TenantSettingsStub,
    private timer: Timer,
    private canopyAutocomplete: CanopyAutocomplete,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.settingsResult = await this.tenantSettingsStub.getTenantDefaultCustomPropertyNames(this.tenantId, this.target);
      let cp: CustomPropertyControl[] = [];
      if(this.initialProperties){
        for(let item of this.initialProperties){
          let ids = this.getNextNameAndValue();
          cp.push({
            initialName: item.name,
            name: new UntypedFormControl(item.name, CanopyValidators.customPropertyNameValidators),
            value: new UntypedFormControl(item.value, CanopyValidators.customPropertyValueValidators),
            nameId: ids.name,
            valueId: ids.value,
            nameAutocomplete: undefined,
            valueAutocomplete: undefined
          });
        }
      }

      for(let item of this.settingsResult.settings.defaultCustomPropertyNames) {
        if(item.target === this.target){
          for(let name of item.names){
            if(!cp.find(v => v.name.value === name)){
              let ids = this.getNextNameAndValue();
              cp.push({
                initialName: name,
                name: new UntypedFormControl(name, CanopyValidators.customPropertyNameValidators),
                value: new UntypedFormControl('', CanopyValidators.customPropertyValueValidators),
                nameId: ids.name,
                valueId: ids.value,
                nameAutocomplete: undefined,
                valueAutocomplete: undefined
              });
            }
          }
        }
      }

      cp.sort(sortBy('initialName'));

      for(let item of cp){
        this.form.addControl(item.nameId, item.name);
        this.form.addControl(item.valueId, item.value);
      }

      this.customProperties = cp;

      this.form.valueChanges.subscribe(() => this.onValueChanges());

      await this.createAutocompleteOnInputs();

      if(!this.customProperties.length){
        this.add();
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public onValueChanges(){
    if(this.form.valid){
      this.resultProperties.splice(0);
      for(let item of this.customProperties){
        if(item.name.value && item.value.value){
          this.resultProperties.push({ name: item.name.value.trim(), value: item.value.value.trim() });
        }
      }
    }
  }

  public onNameFocus(item: CustomPropertyControl){
    if(!item.nameAutocomplete){
      return;
    }

    item.nameAutocomplete.list = this.getAutocompleteNames(item);
    item.nameAutocomplete.evaluate();
  }

  public onValueFocus(item: CustomPropertyControl){
    if(!item.valueAutocomplete){
      return;
    }

    item.valueAutocomplete.list = this.getAutocompleteValuesForPropertyName(item.name.value);
    item.valueAutocomplete.evaluate();
  }

  public getAutocompleteNames(item: CustomPropertyControl){
    let group = this.settingsResult.customPropertyGroups.find(v => v.target === this.target);

    let results = group ? group.properties.map(v => v.name) : [];
    if(this.target === DocumentsWithProperties.study){
      for(let childGroup of this.settingsResult.customPropertyGroups){
        if(childGroup.target === this.target){
          continue;
        }

        results.push(...childGroup.properties.map(v => childGroup.target + '.' + v.name));
      }
    } else{
      let studyGroup = this.settingsResult.customPropertyGroups.find(v => v.target === DocumentsWithProperties.study);
      if(studyGroup){
        let prefix = this.target + '.';
        results.push(...studyGroup.properties
          .filter(v => v.name.indexOf(prefix) === 0)
          .map(v => v.name.substring(prefix.length)));
      }
    }

    let existingNames = this.customProperties.filter(v => v !== item).map(v => v.name.value);

    let result = this.getUniqueValues(results, true).filter(v => existingNames.indexOf(v) === -1);
    return result;
  }

  public getAutocompleteValuesForPropertyName(propertyName: string){
    let result: string[] = [];
    if(propertyName){
      this.findAutocompleteValues(result, this.target, propertyName);

      let parts = propertyName.split('.');
      if(parts.length === 2){
        this.findAutocompleteValues(result, parts[0], parts[1]);
      } else if(this.target !== DocumentsWithProperties.study){
        this.findAutocompleteValues(result, DocumentsWithProperties.study, this.target + '.' + propertyName);
      }
    }

    result = this.getUniqueValues(result, false);
    return result;
  }

  private getUniqueValues(input: string[], valueSort: boolean){
    let unique = Array.from(new Set(input));
    return valueSort ? unique.sort(this.propertyNameSortDelegate) : unique.sort();
  }

  private findAutocompleteValues(resultList: string[], target: string, propertyName: string){
    let group = this.settingsResult.customPropertyGroups.find(v => v.target === target);
    if(group){
      let groupValues = group.properties.find(v => v.name === propertyName);
      if(groupValues) {
        resultList.push(...groupValues.groups.map(v => v.key));
      }
    }
  }

  private propertyNameSortDelegate = function(a: string, b: string) {
    let aContainsPeriod = a.indexOf('.') !== -1;
    let bContainsPeriod = b.indexOf('.') !== -1;
    if(aContainsPeriod === bContainsPeriod){
      return a.localeCompare(b);
    }
    return aContainsPeriod ? 1 : -1;
  };

  public async createAutocompleteOnInputs(){
    await this.timer.yield();
    for(let item of this.customProperties){
      if(!item.nameAutocomplete){
        item.nameAutocomplete = this.canopyAutocomplete.create(item.nameId + '-input', item.name, [], this.propertyNameSortDelegate);
      }
      if(!item.valueAutocomplete){
        item.valueAutocomplete = this.canopyAutocomplete.create(item.valueId + '-input', item.value, []);
      }
    }
  }

  private getNextNameAndValue(){
    let id = this.getNextId();
    return {
      name: id + '-n',
      value: id + '-v'
    };
  }
  private getNextId(){
    return 'cp-' + this.nameId++;
  }

  public async add(){
    try {
      let ids = this.getNextNameAndValue();
      let item: CustomPropertyControl = {
        initialName: '',
        name: new UntypedFormControl('', CanopyValidators.customPropertyNameValidators),
        value: new UntypedFormControl('', CanopyValidators.customPropertyValueValidators),
        nameId: ids.name,
        valueId: ids.value,
        nameAutocomplete: undefined,
        valueAutocomplete: undefined
      };

      this.customProperties.push(item);
      this.form.addControl(item.nameId, item.name);
      this.form.addControl(item.valueId, item.value);

      await this.createAutocompleteOnInputs();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public remove(index: number){
    let item = this.customProperties[index];
    this.customProperties.splice(index, 1);
    this.form.removeControl(item.nameId);
    this.form.removeControl(item.valueId);
    setFormPristine(this.form, false);
    this.onValueChanges();
  }
}

interface CustomPropertyControl {
  initialName: string;
  name: UntypedFormControl;
  value: UntypedFormControl;
  nameId: string;
  valueId: string;
  nameAutocomplete: any;
  valueAutocomplete: any;
}
