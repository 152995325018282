import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {ConfigEditorPageBase} from '../config-editor-page-base';
import {RouterCanDeactivate} from '../../../common/can-deactivate';
import {CustomProperty} from '../../custom-properties/custom-properties';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CanopyValidators} from '../../../common/forms/canopy-validators.service';
import {FormSubmissionButton} from '../../../common/forms/form-submission-button';
import {ConfigStub, GetConfigQueryResult, GetSupportSessionQueryResult, SimVersionStub} from '../../../../generated/api-stubs';
import {VersionedDocumentData} from '../../versioning/versioned-document-data';
import {TelemetryConfigType} from '../config-types';
import {Router} from '@angular/router';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {TextFileReader} from '../../text-file-reader.service';
import {JsonEditor} from '../json-config-editor/json-editor.service';
import { UnvalidatedJsonEditorInstance } from '../json-config-editor/unvalidated-json-editor-instance';
import {CanopyJson} from '../../../common/canopy-json.service';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {FormSubmissionHandler} from '../../../common/forms/form-submission-handler.service';
import {FormCancellationHandler} from '../../../common/forms/form-cancellation-handler.service';
import {
  CompareConfigDialog, ISaveOutputConfigHandler,
} from '../comparing/compare-config-dialog/compare-config-dialog.service';
import {SaveAsDialog} from '../../../common/dialogs/save-as-dialog.service';
import {ConfirmationDialog} from '../../../common/dialogs/confirmation-dialog.service';
import {ConvertTelemetryConfigToStudy} from '../convert-telemetry-config-to-study.service';
import {SignificantSimVersions} from '../../../common/significant-sim-versions.service';
import {DocumentUpdatedEventService} from '../../../worksheets/document-updated-event.service';
import {VisualizationFactory} from '../../visualizations/visualization-factory.service';
import {Timer} from '../../../common/timer.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DisplayableError, InputValidationError} from '../../../common/errors/errors';
import {InputCustomProperty, StudyInput} from '../../../worksheets/study-input';
import {CustomPropertyUtilities} from '../../custom-properties/custom-property-utilities';
import {setFormPristine} from '../../../common/forms/set-form-pristine-hack';
import {SaveToEditorHandlerFactory} from '../comparing/save-output-config-handlers/save-to-editor-handler';
import {getDefaultConfigId} from '../../../worksheets/study-input-utilities';
import {fromReadonly} from '../../../common/from-readonly';
import {ConfigOrConfigLoader} from '../comparing/config-or-config-loader';
import { AuthenticationService, UserData } from '../../../identity/state/authentication.service';

export const COMPARE_TO_STAGED_TITLE: string = 'Comparing to Staged';
export const COMPARE_TO_SAVED_TITLE: string = 'Comparing to Saved';
export const COMPARE_SAVE_OUTPUT_BUTTON_NAME: string = 'Save Output to Editor';

@Component({
    selector: 'cs-edit-config',
    templateUrl: './edit-config.component.html',
    styleUrls: ['./edit-config.component.scss'],
    standalone: false
})
export class EditConfigComponent extends ConfigEditorPageBase implements OnInit, OnDestroy, RouterCanDeactivate {
  @Input() public tenantId: string;
  public userId: string;
  @Input() public configId: string;
  @Input() public isStaged: boolean;
  @Input() public isDefault: boolean;
  public customProperties: CustomProperty[] = [];

  @Input() public simVersion: string;
  @Input() public configName: string;
  @Input() public isWorksheetPage: boolean = false;

  public form: UntypedFormGroup;
  public name: UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, ...CanopyValidators.configNameValidators]));
  public notes: UntypedFormControl = new UntypedFormControl('', [...CanopyValidators.configNotesValidators]);
  public unvalidatedInput: UntypedFormControl = new UntypedFormControl('', []);
  public submitSaveButton: FormSubmissionButton = new FormSubmissionButton('Save', 'Saving...');
  public submitStageButton: FormSubmissionButton = new FormSubmissionButton('Stage Snapshot', 'Staging...');
  public submitSaveAsButton: FormSubmissionButton = new FormSubmissionButton('Save As New Config', 'Saving...');
  public submitTelemetryButton: FormSubmissionButton = new FormSubmissionButton('Run Telemetry', 'Submitting...');
  public hasSaved: boolean = false;
  public isLoaded: boolean = false;
  public supportSession: GetSupportSessionQueryResult;

  public documentResult: GetConfigQueryResult;
  public stagedConfig: StudyInput;
  public originalStagedConfig: StudyInput;
  public initialEditorResult: StudyInput;

  public lastSavedEditorValue: any;

  public userData: UserData;

  public configDataFormatted: string;

  public getConfigDocumentCallback: () => VersionedDocumentData;
  public getEditorValueCallback: () => any;
  public setConfigDocumentCallback: (document: VersionedDocumentData) => void;
  public getConfigOrConfigLoaderCallback: () => ConfigOrConfigLoader;
  public resetPropertyEditor: EventEmitter<void> = new EventEmitter<void>();

  public telemetryConfigType = TelemetryConfigType;

  public saveOutputConfigHandler: ISaveOutputConfigHandler;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authenticationService: AuthenticationService,
    private readonly configStub: ConfigStub,
    private readonly simVersionStub: SimVersionStub,
    private readonly getSimVersion: GetSimVersion,
    private readonly textFileReader: TextFileReader,
    private readonly jsonEditor: JsonEditor,
    private readonly json: CanopyJson,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly formSubmissionHandler: FormSubmissionHandler,
    private readonly formCancellationHandler: FormCancellationHandler,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly saveAsDialog: SaveAsDialog,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly convertTelemetryConfigToStudy: ConvertTelemetryConfigToStudy,
    private readonly significantSimVersions: SignificantSimVersions,
    private readonly documentUpdatedEventService: DocumentUpdatedEventService,
    private readonly saveToEditorHandlerFactory: SaveToEditorHandlerFactory,
    visualizationFactory: VisualizationFactory,
    timer: Timer,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {

    super(visualizationFactory, timer, getFriendlyErrorAndLog);
  }

  public get saveSuccessful(){
    return this.hasSaved && !!this.form && !this.form.dirty;
  }

  public get canRunTelemetry(): boolean {
    try {
      const current= this.editorInstance.getValue();
      return current.channels && current.channels.length;
    } catch{
      return false;
    }
  }

  public async ngOnInit() {
    this.form = this.formBuilder.group({
      name: this.name,
      notes: this.notes,
      unvalidatedInput: this.unvalidatedInput,
    });

    this.getConfigDocumentCallback = this.getConfigDocument.bind(this);
    this.setConfigDocumentCallback = this.setConfigDocument.bind(this);
    this.getEditorValueCallback = this.getEditorValue.bind(this);
    this.getConfigOrConfigLoaderCallback = this.getConfigForCompare.bind(this);

    if(this.isStaged) {
      this.submitSaveButton = new FormSubmissionButton('Save to Source Config', 'Saving...');
      await this.loadFromStagingArea();
    } else if(this.isDefault){
      if(!this.configName){
        throw new DisplayableError('Default config name is required.');
      }
      if(!this.simVersion){
        throw new DisplayableError('Default config sim version is required.');
      }

      await this.loadFromSimVersionDocuments();
    } else {
      if(!this.configId){
        throw new DisplayableError('Config ID is required.');
      }
      await this.loadFromDatabase();
    }
    //Usually happens if navigation away happens while loading
    if(this.editorInstance && this.editorInstance.ready){
      this.initialEditorResult = this.getAsStudyInput(this.editorInstance.getValue());
    }
  }

  public ngOnDestroy(): any {
    super.ngOnDestroy();
  }

  public load() {
    this.userData = this.authenticationService.userDataSnapshot;
  }

  public get isCurrentTenant(): boolean {
    return this.userData.tenant === this.tenantId;
  }

  public get parentWorksheetId(): string | undefined {
    return this.documentResult ? this.documentResult.config.parentWorksheetId : undefined;
  }

  public get isOwner(): boolean {
    return this.userId === this.userData.sub;
  }

  public get isAdministrator(): boolean {
    return this.authenticationService.isAdministrator;
  }

  public async configureEditor(initialContent: any, simVersion: string){
    this.editorInstance = await this.jsonEditor.create(
      this.form,
      'json-editor',
      this.configType.singularKey,
      initialContent,
      simVersion,
      this.configType.singularKey);

    this.lastSavedEditorValue = initialContent;
    this.simVersion = simVersion;

    this.saveOutputConfigHandler = this.saveToEditorHandlerFactory.create(
      this.editorInstance,
        v => {
        this.customProperties = fromReadonly(v || []);
        this.resetPropertyEditor.emit();
      },
      v => {
        this.notes.setValue(v || '', {});
      });

    if(this.isTrack){
      await this.loadConfigPreviewIfRequired(initialContent);
    }

    if(this.editorInstance instanceof UnvalidatedJsonEditorInstance) {
      let unvalidatedEditorInstance = this.editorInstance;
      this.unvalidatedInput.setValue(unvalidatedEditorInstance.unvalidatedContent, {});
      this.unvalidatedInput.valueChanges.subscribe(v => unvalidatedEditorInstance.unvalidatedContent = v);
    }

    this.configDataFormatted = this.json.stringify({
      simVersion,
      config: initialContent
    });
  }

  public async loadFromSimVersionDocuments() {
    try {
      this.load();
      this.configId = getDefaultConfigId(this.configType.singularKey, this.configName);
      let documentResult = await this.simVersionStub.getDocument(this.simVersion, this.configId);

      let configData = this.json.parse(documentResult.document.content);

      this.userId = undefined;
      this.customProperties = [];

      await this.configureEditor(configData, this.simVersion);

      this.name.setValue(this.configName, {});
      this.isLoaded = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadFromStagingArea() {
    try {
      this.load();
      let simVersion = await this.getSimVersion.execute();
      let input = await this.studyStagingArea.getInput(this.configType.singularKey, simVersion);
      if(!input) {
        throw new InputValidationError('A staged ' + this.configType.name + ' was not found.');
      }

      this.stagedConfig = input;
      this.originalStagedConfig = await this.studyStagingArea.getInput(this.configType.singularKey, undefined);

      this.userId = input.userId;
      this.tenantId = this.userData.tenant;
      this.configId = input.configId;
      this.customProperties = InputCustomProperty.toMutables(input.properties);

      await this.configureEditor(input.data, simVersion);

      this.name.setValue(input.name, {});
      this.notes.setValue(input.notes, {});
      this.isLoaded = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadFromDatabase() {
    try {
      this.load();
      let simVersion = await this.getSimVersion.execute();
      let configResult = await this.configStub.getConfig(
        this.tenantId,
        this.configId,
        undefined,
        simVersion);

      this.documentResult = configResult;

      this.userId = configResult.config.userId;
      this.customProperties = CustomPropertyUtilities.objectToList(configResult.config.properties);
      this.supportSession = {
        session: configResult.config.supportSession,
        userInformation: configResult.userInformation
      };

      await this.configureEditor(configResult.config.data, simVersion);

      this.name.setValue(configResult.config.name, {});
      this.notes.setValue(configResult.config.notes, {});

      this.isLoaded = true;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async processFileImportEvent(event: Event) {
    try {
      if(!this.isLoaded){
        return;
      }
      this.isLoaded = false;

      this.errorMessage = undefined;

      let target = event.target as HTMLInputElement;
      let files = target.files;
      if(!files || files.length === 0) {
        return;
      }

      let file = files[0];
      target.value = '';

      let text = await this.textFileReader.read(file);
      let data: any;
      try {
        data = this.json.parse(<string>text);
      } catch(error) {
        throw new DisplayableError(error.message);
      }

      let config = data;
      let notes: string | undefined;
      let customProperties: CustomProperty[] | undefined;
      if(data.simVersion){
        let simVersion = data.simVersion;
        config = data.config;
        notes = data.notes || '';
        customProperties = CustomPropertyUtilities.objectToList(data.customProperties || {});

        if(simVersion !== this.editorInstance.simVersion){
          let upgradeResult = await this.configStub.upgradeConfig(
            this.userData.tenant,
            this.editorInstance.simVersion,
            {
              configType: this.configType.singularKey,
              config,
              simVersion
            });

          config = upgradeResult.config;
        }
      }

      this.editorInstance.setValue(config);

      if(notes && typeof notes === 'string') {
        this.notes.setValue(notes, {});
      }

      if(customProperties){
        this.customProperties = customProperties;
      }

      if(file.name && !this.name.value){
        this.name.setValue(file.name.replace(/\.[^/.]+$/, ''), {});
      }

      await this.loadConfigPreviewIfRequired(config);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isLoaded = true;
  }

  public async compareToStaged(){
    try {
      let staged = await this.studyStagingArea.getInput(this.configType.singularKey, this.editorInstance.simVersion);
      if(!staged){
        return;
      }

      await this.compareConfigDialog.compare(
        this.configType,
        [
          this.getConfigForCompare(),
          new ConfigOrConfigLoader('staged', staged, undefined),
        ],
        this.saveOutputConfigHandler,
        COMPARE_SAVE_OUTPUT_BUTTON_NAME);

      // We call this in case the user staged the output and it now matches the form.
      await this.updateEditedStateIfEditingStagingArea();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async compareToSaved(){
    try {
      await this.compareConfigDialog.compare(
        this.configType,
        [
          this.getConfigForCompare(),
          new ConfigOrConfigLoader('saved', this.getAsStudyInput(this.lastSavedEditorValue), undefined),
        ],
        this.saveOutputConfigHandler,
        COMPARE_SAVE_OUTPUT_BUTTON_NAME);

      // We call this in case the user staged the output and it now matches the form.
      await this.updateEditedStateIfEditingStagingArea();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async updateEditedStateIfEditingStagingArea(): Promise<void> {
    if (!this.isStaged){
      return;
    }

    setFormPristine(this.form, await this.checkIfEditorMatchesStagingArea());
  }

  private async checkIfEditorMatchesStagingArea(): Promise<boolean> {
    let stagingArea = await this.studyStagingArea.getInput(this.configType.singularKey, this.simVersion);
    if(!stagingArea){
      return false;
    }

    let current = this.editorInstance.getValue();
    let editor = this.getAsStudyInput(current);

    return this.json.studyInputDataEquals(stagingArea, editor);
  }

  public getConfigForCompare(): ConfigOrConfigLoader {
    let current = this.editorInstance.getValue();
    return new ConfigOrConfigLoader('current', this.getAsStudyInput(current), undefined);
  }

  public get editorValue(): any {
    try {
      return this.editorInstance.getValue();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      return undefined;
    }
  }

  public tryGetEditorValue = async (save: boolean) => {
    try {
      this.errorMessage = undefined;
      if(save) {
        let saveSuccessful = await this.onSubmit();
        if (!saveSuccessful) {
          return undefined;
        }
      }

      return this.editorInstance.getValue();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      return undefined;
    }
  };

  public routerCanDeactivate() {
    return this.formCancellationHandler.execute(this.form);
  }

  public async onSave() {
    return await this.formSubmissionHandler.execute(this.submitSave, this.form, this.submitSaveButton, this);
  }

  public async onStage() {
    return await this.formSubmissionHandler.execute(this.submitStage, this.form, this.submitStageButton, this);
  }

  public async onSaveAs(){
    return await this.formSubmissionHandler.execute(this.submitSaveAs, this.form, this.submitSaveAsButton, this);
  }

  public async onSubmitTelemetry() {
    let success = await this.formSubmissionHandler.execute(this.submitTelemetry, this.form, this.submitTelemetryButton, this);

    if (success) {
      this.router.navigate(['/configs', this.configType.pluralKey]);
    }

    return success;
  }

  public get isSubmitting(): boolean {
    return this.submitSaveButton.isSubmitting
      || this.submitStageButton.isSubmitting
      || this.submitSaveAsButton.isSubmitting
      || this.submitTelemetryButton.isSubmitting;
  }

  public onSubmit(): Promise<boolean> {
    if(this.isStaged){
      return this.onStage();
    } else{
      return this.onSave();
    }
  }

  private async submitSave() {
    let editorValue = this.editorInstance.getValue();
    let saveCompleted = await this.saveConfig(editorValue);
    if(saveCompleted){
      if(this.isStaged) {
        this.studyStagingArea.updateIsEdited(
          this.configType.singularKey,
          false);
      } else {
        this.lastSavedEditorValue = editorValue;
        this.hasSaved = true;
        setFormPristine(this.form);
      }
    }
  }

  private async submitStage() {
    let editorValue = this.editorInstance.getValue();
    this.stageConfig(editorValue);

    if(this.isStaged){
      this.lastSavedEditorValue = editorValue;
      this.hasSaved = true;
      setFormPristine(this.form);
    }
  }

  private async submitSaveAs() {
    let editorValue = this.editorInstance.getValue();
    let name = this.name.value;
    let result = await this.saveAsDialog.showForContent(
      name,
      this.configType.singularKey,
      this.editorInstance.simVersion,
      undefined,
      editorValue,
      this.customProperties,
      this.notes.value);

    if(result){
      setFormPristine(this.form);
      this.router.navigate(['/configs', this.configType.pluralKey, this.userData.tenant, result.configId, 'edit']);
    }
  }

  private async submitTelemetry() {
    let editorValue = this.editorInstance.getValue();

    await this.convertTelemetryConfigToStudy.execute(
      editorValue,
      this.editorInstance.simVersion,
      this.name.value,
      false,
      this.customProperties,
      this.notes.value);
  }

  public get showSubmitTelemetry() {
    return this.configType.singularKey === this.telemetryConfigType.singularKey
      && this.significantSimVersions.isAfterTelemetrySimulation
      && this.canRunTelemetry;
  }

  private stageConfig(editorValue: any) {
    this.studyStagingArea.stageInput(
      this.getAsStudyInput(editorValue));
  }

  private getAsStudyInput(editorValue: any): StudyInput {
    return new StudyInput(
      this.configType.singularKey,
      this.userId,
      this.configId,
      this.name.value,
      editorValue,
      this.customProperties,
      this.notes.value,
      this.editorInstance.simVersion,
      this.isEdited,
      undefined);
  }

  public get isEdited(): boolean {
    return this.isStaged || this.form.dirty;
  }

  public getEditorValue(): any {
    return this.editorValue;
  }

  public getConfigDocument(): VersionedDocumentData {
    return {
      name: this.name.value,
      notes: this.notes.value,
      data: this.editorValue,
      properties: CustomPropertyUtilities.listToObject(this.customProperties),
      deleteRequested: this.documentResult ? this.documentResult.config.deleteRequested : undefined,
      parentWorksheetId: this.documentResult ? this.documentResult.config.parentWorksheetId : undefined,
    };
  }

  public setConfigDocument(document: VersionedDocumentData): void {
    this.name.setValue(document.name);
    this.notes.setValue(document.notes);
    this.customProperties = CustomPropertyUtilities.objectToList(document.properties);
    this.editorInstance.setValue(document.data);
    this.resetPropertyEditor.emit();
  }

  private async saveConfig(editorValue: any): Promise<boolean> {
    if(this.isDefault){
      return false;
    }

    if(this.isStaged){
      let verified = await this.verifySave();
      if(!verified){
        return false;
      }
    }

    await this.configStub.putConfig(
      this.tenantId,
      this.configId,
      {
        name: this.name.value,
        configType: this.configType.singularKey,
        config: editorValue,
        properties: this.customProperties,
        notes: this.notes.value,
        simVersion: this.editorInstance.simVersion
      });

    this.documentUpdatedEventService.emit(this.tenantId, this.configId);

    return true;
  }

  private async verifySave(): Promise<boolean>{
    let existingConfig;
    try {
      existingConfig = await this.configStub.getConfig(
        this.tenantId,
        this.configId,
        undefined,
        this.editorInstance.simVersion);
    } catch (error) {
      if (error.isFromApi && error.response.status === 404) {
        await this.confirmationDialog.show(
          'The source config no longer exists.',
          'Unable to save',
          'OK',
          'OK');

        return false;
      } else {
        throw error;
      }
    }

    let existingName = existingConfig.config.name;
    let currentName = this.name.value;
    if(existingName !== currentName){
      let shouldRename = await this.confirmationDialog.show(
        `Saving will rename the source config from "${existingName}" to "${currentName}".`,
        'Confirm Rename',
        'Save and Rename Source',
        'Cancel');

      if(!shouldRename){
        return false;
      }
    }

    return true;
  }

  public onSupportSessionChanged(supportSession: GetSupportSessionQueryResult){
    this.supportSession = supportSession;
  }

  // public async saveTyres(tyres: FrontAndRearTyres): Promise<void> {
  //   if(!tyres || !tyres.front || !tyres.rear) {
  //     return Promise.resolve();
  //   }
  //
  //
  //   let tyresEditor = this.editorInstance.jsonEditor.editors['root.tyres'];
  //   let tyresIndex = tyresEditor.display_text.findIndex((v: string) => v.indexOf(tyres.type) !== -1);
  //   // let otherIndex = tyresIndex === 0 ? 1 : 0;
  //   //
  //   // // First we switch to another tyres type to wipe out any non-shared parameters.
  //   // await this.switchEditorNode(tyresEditor, otherIndex);
  //
  //   await this.switchEditorNode(tyresEditor, tyresIndex);
  //
  //   // Bypass validation at this point.
  //   let oldEditorValue = this.editorInstance.jsonEditor.getValue();
  //
  //   let newEditorValue = {
  //     ...oldEditorValue,
  //     tyres: {
  //       ...oldEditorValue.tyres
  //     }
  //   };
  //
  //   newEditorValue.tyres.name = tyres.type;
  //
  //   let sides: ('front' | 'rear')[] = ['front', 'rear'];
  //   for(let side of sides){
  //     if(!tyres[side]) {
  //       continue;
  //     }
  //
  //     newEditorValue.tyres[side] = {
  //       ...newEditorValue.tyres[side],
  //       ...tyres[side]
  //     };
  //     // newEditorValue.tyres[side].rUnloaded = oldEditorValue.tyres[side].rUnloaded;
  //     // newEditorValue.tyres[side].vLowSpeed = oldEditorValue.tyres[side].vLowSpeed;
  //     // newEditorValue.tyres[side].radiusEquations = oldEditorValue.tyres[side].radiusEquations;
  //     // newEditorValue.tyres[side].rollingResistance = oldEditorValue.tyres[side].rollingResistance;
  //   }
  //
  //   this.editorInstance.setValue(newEditorValue);
  //
  //   return Promise.resolve();
  // }

  // private async switchEditorNode(multipleEditor: any, index: number) {
  //   if(multipleEditor.switcher.value === multipleEditor.display_text[index]){
  //     return;
  //   }
  //
  //   multipleEditor.switchEditor(index);
  //   multipleEditor.switcher.value = multipleEditor.display_text[index];
  //   multipleEditor.refreshValue();
  //   multipleEditor.onChange(true);
  //
  //   await this.timer.yield();
  // }
}
