import { Component, Input, OnInit } from '@angular/core';
import { ZendeskRedirectWorkaround } from '../../../sso/zendesk/zendesk-redirect-workaround';
import { SupportSessionDialogSession } from '../support-session-dialog-session';

@Component({
    selector: 'cs-zendesk-information',
    templateUrl: './zendesk-information.component.html',
    styleUrls: ['./zendesk-information.component.scss'],
    standalone: false
})
export class ZendeskInformationComponent implements OnInit {

  @Input() public session: SupportSessionDialogSession;

  public currentUrl: string;

  public createSupportTicketUrl: string;
  public createSupportTicketEmailUrl: string;
  public searchSupportTicketsUrl: string;
  public manageSupportTicketsUrl: string;
  public documentationUrl: string;

  constructor() {
  }

  public get warningsRequireConfirmation(): boolean {
    return this.session.warnings && this.session.warnings.length && !this.session.data.session;
  }

  public get isSupportSessionOpen(): boolean {
    return this.session.data && this.session.data.session && this.session.data.session.isOpen;
  }

  ngOnInit() {
    this.currentUrl = window.location.href;

    this.createSupportTicketUrl = ZendeskRedirectWorkaround.createZendeskUrl(
      'https://support.canopysimulations.com/hc/en-gb/requests/new'
      + '?tf_5635745411613=' + encodeURIComponent(this.currentUrl)
      + '&ticket_form_id=5617263741341');

    this.searchSupportTicketsUrl = 'https://support.canopysimulations.com/hc/en-gb/requests?query='
      + encodeURIComponent('fieldvalue:' + this.session.documentId);

    this.manageSupportTicketsUrl = 'https://support.canopysimulations.com/hc/en-gb/requests';

    const newLine = '\n';
    this.createSupportTicketEmailUrl = 'mailto:support@canopysimulations.com?body='
      + encodeURIComponent(
        'Support Session URL: ' + this.currentUrl + newLine + newLine
        + 'Expected Behaviour:' + newLine + newLine + newLine
        + 'Current Behaviour:' + newLine + newLine + newLine
        + 'Description:' + newLine + newLine + newLine);

      this.documentationUrl = 'https://support.canopysimulations.com/';
  }
}
