import {Component} from '@angular/core';
import {ResultsStagingArea, ResultSource} from './results-staging-area.service';
import {OnInit} from '@angular/core';
import {ResultsState} from './results-staging-area.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Rison} from '../../common/canopy-rison';
import { AuthenticationService } from '../../identity/state/authentication.service';

@Component({
    selector: 'cs-results-staging-area',
    templateUrl: './results-staging-area.component.html',
    styleUrls: ['./results-staging-area.component.scss'],
    standalone: false
})
export class ResultsStagingAreaComponent implements OnInit {
  public errorMessage: string;

  public tenantId: string;
  public state: ResultsState;
  public urlCommands: any[];

  constructor(
    private readonly resultsStagingArea: ResultsStagingArea,
    private readonly rison: Rison,
    private readonly authenticationService: AuthenticationService,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit() {
    this.load();
    this.resultsStagingArea.changed.subscribe(() => this.load());
  }

  public async load() {
    try {
      this.errorMessage = undefined;
      const userData = this.authenticationService.userDataSnapshot;
      this.tenantId = userData.tenant;
      this.state = this.resultsStagingArea.get();
      let data = this.rison.encodeObject(ResultsState.toMutable(this.state));
      this.urlCommands = ['/view/overlay', { data }];
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public clear() {
    try {
      this.errorMessage = undefined;
      this.resultsStagingArea.clear();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public moveSourceLeft(source: ResultSource){
    this.resultsStagingArea.moveSourceLeft(source.studyId, source.jobId);
  }
  public moveSourceRight(source: ResultSource){
    this.resultsStagingArea.moveSourceRight(source.studyId, source.jobId);
  }
  public removeSource(source: ResultSource){
    this.resultsStagingArea.removeSource(source.studyId, source.jobId);
  }
}
