import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import { CANOPY_THEME_COOKIE_NAME } from "../../common/constants";

@Component({
    selector: 'cs-theme-switcher',
    templateUrl: './theme-switcher.component.html',
    standalone: false
})
export class ThemeSwitcherComponent implements OnInit{
  public ColourTheme = ColourTheme;
  constructor(private readonly cookieManager: CookieService){
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', () => this.setDisplayedTheme(null));
  }

  ngOnInit(): void {
    this.setDisplayedTheme(null);
  }

  public themeIcon(theme: ColourTheme) {
    return !theme ? 'fa-adjust' :
      theme === ColourTheme.light ? 'fa-sun-o' :
      'fa-moon-o';
  }

  public getStoredTheme(): ColourTheme {
    return ColourTheme[this.cookieManager.get(CANOPY_THEME_COOKIE_NAME) as keyof typeof ColourTheme];
  }

  public setTheme(theme: ColourTheme) {
    this.setStoredTheme(theme);
    this.setDisplayedTheme(theme);
  }

  private setDisplayedTheme(theme: ColourTheme) {
    theme ??= this.getPreferredTheme();
    document.documentElement.setAttribute('data-bs-theme', theme);
  }

  private getPreferredTheme(): ColourTheme {
    const storedTheme = this.getStoredTheme();
    return storedTheme ? storedTheme :
      window.matchMedia('(prefers-color-scheme: dark)').matches ? ColourTheme.dark :
      ColourTheme.light;
  }

  private setStoredTheme(theme?: ColourTheme) {
    let expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 25);
    this.cookieManager.set(CANOPY_THEME_COOKIE_NAME, theme, expiryDate, '/');
  }
}

export enum ColourTheme {
  light = 'light',
  dark = 'dark'
}
