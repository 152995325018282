import {OnInit, Component, Input} from '@angular/core';
import {
  GetStudyQueryResult, GetStudyJobQueryResult,
  ConfigStub, DocumentSubType, SimType, StudyDocumentStudyDocumentDataSource
} from '../../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {CustomPropertyUtilities} from '../../../custom-properties/custom-property-utilities';
import {CanopyFileLoader, CanopyFileLoaderFactory} from '../../../visualizations/canopy-file-loader.service';
import {getJobIndexFromJobId} from '../../../../common/get-job-index-from-job-id';
import {PopulateTrackRacingLineFromSimulation} from '../../../../visualizations/populate-track-racing-line-from-simulation';
import {GetSimVersion} from '../../../../common/get-sim-version.service';
import {DisplayableError} from '../../../../common/errors/errors';
import {StudyStagingArea} from '../../../study-staging-area/study-staging-area.service';
import {LoadingDialog} from '../../../../common/dialogs/loading-dialog.service';
import {SaveAsDialog} from '../../../../common/dialogs/save-as-dialog.service';
import {getConfigNotesFromStudy, getConfigPropertiesFromStudy} from '../../../../worksheets/study-utilities';
import {CanopyJson} from '../../../../common/canopy-json.service';
import { DownloadFile } from '../../../../common/download-file.service';
import { AuthenticationService, UserData } from '../../../../identity/state/authentication.service';

@Component({
    selector: 'cs-save-racing-line-buttons',
    templateUrl: './save-racing-line-buttons.component.html',
    styleUrls: ['./save-racing-line-buttons.component.scss'],
    standalone: false
})
export class SaveRacingLineButtonsComponent implements OnInit {
  @Input() public studyResult: GetStudyQueryResult;
  @Input() public jobResult: GetStudyJobQueryResult;
  @Input() public showAsCard: boolean = false;

  public trackSource: StudyDocumentStudyDocumentDataSource;

  public errorMessage: string;
  public isSavingToSourceTrack: boolean;
  public userData: UserData;

  private readonly fileLoader: CanopyFileLoader;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly configStub: ConfigStub,
    private readonly loadingDialog: LoadingDialog,
    private readonly saveAsDialog: SaveAsDialog,
    canopyFileLoaderFactory: CanopyFileLoaderFactory,
    private readonly getSimVersion: GetSimVersion,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly json: CanopyJson,
    private readonly downloadFile: DownloadFile,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    this.fileLoader = canopyFileLoaderFactory.create();
  }

  public ngOnInit(){
    this.userData = this.authenticationService.userDataSnapshot;
    this.trackSource = this.studyResult.study.data.sources.find(v => v.configType === DocumentSubType.track);
    this.fileLoader.addStudy(this.studyResult.study.tenantId, this.studyResult.study.documentId, this.studyResult.accessInformation, this.studyResult.convertedSimVersion);
  }

  public get isSupportedStudyType(): boolean {
    return this.jobResult.studyJob.data.state === 'successful'
      && (
        this.studyResult.study.data.studyType === 'generateRacingLine'
        || this.studyResult.study.data.studyType === 'quasiStaticLapWithGenerateRacingLine'
        || this.studyResult.study.data.studyType === 'trackConverter'
        || (this.studyResult.study.data.studyType as string).startsWith('dynamicLap')
      );
  }

  public get canSaveToSource(): boolean{
    if(this.jobResult.studyJob.data.state !== 'successful'){
      return false;
    }

    if(!this.trackSource || !this.trackSource.configId || !this.trackSource.userId){
      return false;
    }

    if(this.trackSource.userId !== this.userData.sub){
      return false;
    }

    if(this.isSavingToSourceTrack){
      return false;
    }

    return true;
  }

  public get canSave(): boolean{
    if(this.jobResult.studyJob.data.state !== 'successful'){
      return false;
    }

    if(this.isSavingToSourceTrack){
      return false;
    }

    return true;
  }

  public async stageRacingLine() {
    try {
      this.errorMessage = undefined;
      let trackData = await this.loadingDialog.showUntilFinished(
        this.fileLoader.loadTrackForStudyJob(this.studyResult.study.documentId, getJobIndexFromJobId(this.jobResult.studyJob.documentId)),
        'Loading...');

      this.removeTrackDataNotInSchema(trackData);

      let properties = getConfigPropertiesFromStudy(DocumentSubType.track, this.studyResult);
      let notes = getConfigNotesFromStudy(DocumentSubType.track, this.studyResult);

      this.studyStagingArea.stage(
        DocumentSubType.track,
        this.trackSource ? this.trackSource.userId : undefined,
        this.trackSource ? this.trackSource.configId : undefined,
        this.getTrackConfigName(),
        trackData,
        properties,
        notes,
        this.studyResult.convertedSimVersion,
        true);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async saveRacingLineToSourceTrack(){
    try {
      this.errorMessage = undefined;
      this.isSavingToSourceTrack = true;
      let configId = this.trackSource.configId;
      let sourceTrackResult = await this.loadingDialog.showUntilFinished(
        this.configStub.getConfig(
          this.userData.tenant,
          configId,
          undefined,
          this.getSimVersion.currentSimVersion),
        'Loading...');

      let newConfig = sourceTrackResult.config.data;
      newConfig = await this.loadingDialog.showUntilFinished(
        this.populateConfigRacingLineFromStudyJob(newConfig),
        'Applying...');

      this.removeTrackDataNotInSchema(newConfig);

      await this.loadingDialog.showUntilFinished(
        this.configStub.putConfig(
          this.userData.tenant,
          configId,
          {
            name: sourceTrackResult.config.name,
            configType: sourceTrackResult.config.subType,
            properties:  CustomPropertyUtilities.objectToList(sourceTrackResult.config.properties),
            notes: sourceTrackResult.config.notes,
            config: newConfig,
            simVersion: sourceTrackResult.convertedSimVersion
          }),
        'Saving...');
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this.isSavingToSourceTrack = false;
  }

  public async populateConfigRacingLineFromStudyJob(track: any) {
    let studyId = this.studyResult.study.documentId;
    let jobIndex = getJobIndexFromJobId(this.jobResult.studyJob.documentId);
    if(!this.jobResult.studyJobInput.simTypes.length) {
      throw new DisplayableError('No job sim types found for job.');
    }
    let simType: SimType = this.jobResult.studyJobInput.simTypes[0];
    let studyType = this.studyResult.study.data.studyType;
    let vectorMetadata = await this.fileLoader.loadVectorMetadata(studyId, jobIndex, simType);
    let populateRacingLine = new PopulateTrackRacingLineFromSimulation(
      studyType,
      this.jobResult.studyJob.simVersion,
      vectorMetadata,
      (fileName, binaryFormat) => this.fileLoader.loadChannelDataByFileName(studyId, jobIndex, fileName, binaryFormat),
      (fileName) => this.fileLoader.loadJsonDataByFileName(studyId, jobIndex, fileName));

    let result = await populateRacingLine.execute(track);

    this.removeTrackDataNotInSchema(result);

    return result;
  }

  public async saveRacingLineToNewTrack(){
    try {
      this.errorMessage = undefined;

      let trackData = await this.loadingDialog.showUntilFinished(
        this.fileLoader.loadTrackForStudyJob(this.studyResult.study.documentId, getJobIndexFromJobId(this.jobResult.studyJob.documentId)),
        'Loading...');

      this.removeTrackDataNotInSchema(trackData);

      let properties = getConfigPropertiesFromStudy(DocumentSubType.track, this.studyResult);
      let notes = getConfigNotesFromStudy(DocumentSubType.track, this.studyResult);

      await this.saveAsDialog.showForContent(
        this.getTrackConfigName(),
        DocumentSubType.track,
        this.studyResult.convertedSimVersion,
        undefined,
        trackData,
        properties,
        notes);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async downloadDeprecated(){
    try {
      this.errorMessage = undefined;

      let trackData = await this.loadingDialog.showUntilFinished(
        this.fileLoader.loadTrackForStudyJob(this.studyResult.study.documentId, getJobIndexFromJobId(this.jobResult.studyJob.documentId)),
        'Loading...');

      // We want to keep all sections.
      //this.removeTrackDataNotInSchema(trackData);

      let configDataFormatted = this.json.stringify({
        simVersion: this.studyResult.study.simVersion,
        config: trackData
      });

      this.downloadFile.text(
        (this.getTrackConfigName()) + '.json',
        configDataFormatted,
        'application/json');

    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private removeTrackDataNotInSchema(trackData: any){
    if(trackData.centreLine){
      delete trackData.centreLine;
    }
  }

  private getTrackConfigName(): string {
    if(this.trackSource && this.trackSource.name){
      return this.trackSource.name;
    }

    return this.studyResult.study.name;
  }
}
