import {Component, Input} from '@angular/core';
import {ViewJobResultsSectionBase} from '../view-job-results-section-base';
import {JobSelectorState} from '../job-selector-state';
import {JobViewModel} from '../job-view-model';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

@Component({
    selector: 'cs-view-job-inputs',
    templateUrl: './view-job-inputs.component.html',
    styleUrls: ['./view-job-inputs.component.scss'],
    standalone: false
})
export class ViewJobInputsComponent extends ViewJobResultsSectionBase {
  @Input() public jobSelectorState: JobSelectorState;
  public job: JobViewModel = null;

  constructor(
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    super(getFriendlyErrorAndLog);
  }

  protected async load(){
    if(this.job){
      await this.job.studyResult.tryLoad();
      await this.job.jobResult.tryLoad();
    }
  }

  public async selectJob(job: JobViewModel) {
    try{
      this.job = job;

      if(this.isLoaded){
        await this.load();
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}
