import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChannelEditorDialogData} from './channel-editor-dialog.service';
import {DialogManager, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {ChannelEditorDialogSession, ChannelEditorDialogSessionFactory} from './channel-editor-dialog-session';
import {Timer} from '../../common/timer.service';

/**
 * Component for editing channels in a visualization.
 */
@Component({
    selector: 'cs-channel-editor-dialog',
    templateUrl: './channel-editor-dialog.component.html',
    styleUrls: ['./channel-editor-dialog.component.scss'],
    standalone: false
})
export class ChannelEditorDialogComponent implements OnInit {

  /**
   * The filter text box DOM element.
   */
  @ViewChild('filter') filterElement: ElementRef;

  /**
   * Whether the dialog is visible.
   */
  public isVisible: boolean = false;

  /**
   * The dialog data to display.
   */
  public dialog: DialogBase<any>;

  /**
   * The session for the dialog.
   */
  public session: ChannelEditorDialogSession;

  /**
   * Creates a new channel editor dialog component.
   * @param timer The timer service.
   * @param sessionFactory The channel editor dialog session factory.
   * @param dialogManager The dialog manager.
   */
  constructor(
    private timer: Timer,
    private sessionFactory: ChannelEditorDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  /**
   * Initialize the component.
   */
  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  /**
   * Accept the dialog.
   */
  public accept() {
    if(this.dialog) {
      this.dialog.resolve(this.session.buildResult());
    }
  }

  /**
   * Dismiss the dialog.
   */
  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  /**
   * Handle a change in the dialog manager. This decides whether to show or hide the dialog.
   */
  public async dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getSecondary();
    if(currentDialog && currentDialog instanceof ChannelEditorDialogData){
      this.isVisible = true;
      this.dialog = <ChannelEditorDialogData>currentDialog;
      this.session = this.sessionFactory.create(<ChannelEditorDialogData>currentDialog);
      this.session.load();

      await this.timer.yield();
      this.session.filterElement = this.filterElement;
    } else {
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}

