import { Component, Input } from '@angular/core';

@Component({
    selector: 'cs-study-jobs-summary',
    templateUrl: './study-jobs-summary.component.html',
    styleUrls: ['./study-jobs-summary.component.scss'],
    standalone: false
})
export class StudyJobsSummaryComponent {
  @Input() tenantId: string;
  @Input() studyId: string;
  @Input() jobCount: number;
  @Input() completedJobCount: number;
  @Input() succeededJobCount: number;
  @Input() canNavigate: boolean;
}
