import {Component, Input} from '@angular/core';
import {ImportTelemetryDialog} from '../import-telemetry-dialog/import-telemetry-dialog.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ConfirmationDialog} from '../../../common/dialogs/confirmation-dialog.service';
import { AuthenticationService } from '../../../identity/state/authentication.service';

export const ERROR_TITLE: string = 'Import Telemetry Error';

@Component({
    selector: 'cs-import-telemetry-dialog-launcher',
    templateUrl: './import-telemetry-dialog-launcher.component.html',
    styleUrls: ['./import-telemetry-dialog-launcher.component.scss'],
    standalone: false
})
export class ImportTelemetryDialogLauncherComponent {
  @Input() public compactButton: boolean = false;

  constructor(
    private readonly prompt: ConfirmationDialog,
    private readonly importTelemetryDialog: ImportTelemetryDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public get isAdministrator(): boolean {
    return this.authenticationService.isAdministrator;
  }

  public async launch(showDifferences: boolean) {
    try{
      await this.importTelemetryDialog.show(showDifferences);
    } catch(error){
      let message = this.getFriendlyErrorAndLog.execute(error);
      await this.prompt.show(message, ERROR_TITLE);
    }
  }
}
