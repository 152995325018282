import {Component} from '@angular/core';
import {DialogManager} from './dialog-manager.service';
import {OnInit} from '@angular/core';
import {ConfirmationDialogData} from './confirmation-dialog.service';
import {cssSanitize} from '../css-sanitize';

export const DEFAULT_MESSAGE = 'Please confirm.';
export const DEFAULT_TITLE = 'Canopy Simulations';
export const DEFAULT_ACCEPT_MESSAGE = 'OK';
export const DEFAULT_DISMISS_MESSAGE = 'Cancel';

@Component({
    selector: 'cs-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    standalone: false
})
export class ConfirmationDialogComponent implements OnInit {

  public confirmationDialog: ConfirmationDialogData;
  public isVisible: boolean = false;
  public title: string;
  public message: string;
  public acceptMessages: ReadonlyArray<string>;
  public dismissMessage: string;
  public warnings: ReadonlyArray<string>;
  public errors: ReadonlyArray<string>;

  constructor(private dialogManager: DialogManager){
  }

  public ngOnInit(): any {
    this.dialogManager.tertiaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public get showDismissButton(): boolean {
    return !!(this.dismissMessage && this.acceptMessages.every(v => v !== this.dismissMessage));
  }

  public accept(message: string) {
    if(this.confirmationDialog) {
      this.confirmationDialog.resolve(message);
    }
  }

  public dismiss() {
    if(this.confirmationDialog){
      this.confirmationDialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getTertiary();
    if(currentDialog && currentDialog instanceof ConfirmationDialogData){
      this.isVisible = true;
      this.confirmationDialog = <ConfirmationDialogData>currentDialog;
      this.message = this.confirmationDialog.message || DEFAULT_MESSAGE;
      this.title = this.confirmationDialog.title || DEFAULT_TITLE;
      this.acceptMessages = this.confirmationDialog.acceptMessages || [ DEFAULT_ACCEPT_MESSAGE ];
      this.dismissMessage = this.confirmationDialog.dismissMessage || DEFAULT_DISMISS_MESSAGE;
      this.warnings = this.confirmationDialog.warnings || [];
      this.errors = this.confirmationDialog.errors || [];
    } else {
      this.isVisible = false;
      this.confirmationDialog = undefined;
    }
  }

  public cssSanitize(value: string): string {
    return cssSanitize(value);
  }
}
