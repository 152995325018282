import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {
  PoolStub, GetPoolStatusQueryResult, ComputeNodeState, PoolType,
} from '../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {sortBy} from '../common/sort-by';
import {Subscription} from 'rxjs';
import {Timer} from '../common/timer.service';

export const TICK_INTERVAL: number = 10;
export const NOT_ASSIGNED_TICK_INTERVAL: number = 5*60;

@Component({
    selector: 'cs-pool-status',
    templateUrl: './pool-status.component.html',
    styleUrls: ['./pool-status.component.scss'],
    standalone: false
})
export class PoolStatusComponent implements OnInit, OnDestroy {

  @Input() public tenantId: string;
  @Input() public poolType: PoolType = PoolType.primary;

  public status: GetPoolStatusQueryResult;
  public errorMessage: string;
  public counter: number;
  public isAssigned: boolean = true;
  public containsHelperNodes: boolean = false;

  public subscription: Subscription;

  constructor(
    private poolStub: PoolStub,
    private timer: Timer,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();

    this.counter = 0;
    let timer = this.timer.repeat(1000);
    this.subscription = timer.subscribe(() => this.onTimerTick());
  }

  public ngOnDestroy(){
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public onTimerTick(){
    if(this.counter > 0){
      --this.counter;

      if(this.counter === 0){
        this.load();
      }
    }
  }

  public async load() {
    try {
      this.errorMessage = undefined;
      this.status = await this.poolStub.getPoolStatus(this.tenantId, this.poolType);
      this.isAssigned = true;
      this.status.computeNodes.sort(sortBy('computeNodeId'));

      this.containsHelperNodes = this.status.computeNodes.some(v => !v.isDedicated)
        || !!this.status.targetLowPriority || !!this.status.currentLowPriority;

      for(let node of this.status.computeNodes){
        this.drawCell(this.status.maximumTasksPerNode, node);
      }

      this.counter = TICK_INTERVAL;
    } catch (error) {
      if (error.isFromApi && error.response.status === 404) {
        this.isAssigned = false;
        this.counter = NOT_ASSIGNED_TICK_INTERVAL;
      } else{
        this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
        this.isAssigned = true;
        this.counter = TICK_INTERVAL;
      }
    }
  }

  private drawCell(maxTasks: number, nodeInfo: ComputeNode) {
    let uiState: ComputeNodeUIStates;

    // Map all the node states onto few UI states/colors
    switch (nodeInfo.state) {
      case ComputeNodeState.creating:
      case ComputeNodeState.leavingPool:
      case ComputeNodeState.rebooting:
      case ComputeNodeState.reimaging:
      case ComputeNodeState.starting:
      case ComputeNodeState.waitingForStartTask:
        uiState = ComputeNodeUIStates.Transitioning;
        break;

      case ComputeNodeState.idle:
        uiState = ComputeNodeUIStates.Idle;
        break;

      case ComputeNodeState.offline:
      case ComputeNodeState.startTaskFailed:
      case ComputeNodeState.unknown:
      case ComputeNodeState.unusable:
        uiState = ComputeNodeUIStates.Error;
        break;

      case ComputeNodeState.running:
        uiState = ComputeNodeUIStates.Running;
        break;

      case ComputeNodeState.preempted:
        uiState = ComputeNodeUIStates.Preempted;
        break;

      default:
        uiState = ComputeNodeUIStates.Unknown;
        break;
    }

    let nodeClass = '';

    if(!nodeInfo.isDedicated){
      nodeClass = 'low-priority ';
    }

    // Use one rectangle for a node if MaxTasksPerComputeNode is one or node can't be used
    if (maxTasks === 1 ||
      uiState === ComputeNodeUIStates.Transitioning ||
      uiState === ComputeNodeUIStates.Preempted ||
      uiState === ComputeNodeUIStates.Error ||
      uiState === ComputeNodeUIStates.Unknown) {

      switch (uiState) {
        case ComputeNodeUIStates.Error:
          nodeClass += 'error';
          break;
        case ComputeNodeUIStates.Idle:
          nodeClass += 'idle';
          break;
        case ComputeNodeUIStates.Running:
          nodeClass += 'running';
          break;
        case ComputeNodeUIStates.Transitioning:
          nodeClass += 'transitioning';
          break;
        case ComputeNodeUIStates.Preempted:
          nodeClass += 'preempted';
          break;
        case ComputeNodeUIStates.Unknown:
          nodeClass += 'unknown';
          break;
        default:
          nodeClass += 'unknown';
          break;
      }

      nodeInfo.tasks = [{
        class: nodeClass,
        tooltip: nodeInfo.state
      }];
    } else {
      // MaxTasksPerComputeNode > 1 and compute node in state where could have tasks running
      // Have a grid to represent max number of tasks the compute node can run at a time
      nodeInfo.tasks = [];
      for(let i=0; i<maxTasks; ++i){
        nodeInfo.tasks.push({
          class: nodeClass + (i < nodeInfo.runningTasks ? 'running' : 'idle'),
          tooltip: i < nodeInfo.runningTasks ? 'running' : ''
        });
      }
    }
  }
}

interface ComputeNode {
  computeNodeId: string;
  state: ComputeNodeState;
  runningTasks: number;
  tasks?: ComputeNodeTask[];
  isDedicated: boolean;
}

interface ComputeNodeTask {
  class: string;
  tooltip: string;
}

enum ComputeNodeUIStates {
  Preempted,
  Transitioning,
  Idle,
  Running,
  Error,
  Unknown,
}
