import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TextFile} from '../../text-file-reader.service';
import {TelemetryConfig, TelemetryFile} from '../telemetry-config';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import * as d3 from '../../../visualizations/d3-bundle';
import {DisplayableError} from '../../../common/errors/errors';
import {MutableTelemetryChannel} from '../telemetry-channel';
import {ImportTelemetryStage} from '../import-telemetry-dialog/import-telemetry-stage';
import {Timer} from '../../../common/timer.service';
import {tryParseAsNumber} from '../../../common/try-parse-as-number';

const QUOTE_CHARACTERS: string[] = ['"', '\'', '`'];
export const DELIMITERS: string[] = [',', '\t', ';', '|', ':'];

@Component({
    selector: 'cs-convert-telemetry-csv-to-json',
    templateUrl: './convert-telemetry-csv-to-json-stage.component.html',
    styleUrls: ['./convert-telemetry-csv-to-json-stage.component.scss'],
    standalone: false
})
export class ConvertTelemetryCsvToJsonStageComponent extends ImportTelemetryStage {

  @Input() public csvFile: TextFile;
  @Output() public telemetryConfigCreated: EventEmitter<TelemetryFile> = new EventEmitter<TelemetryFile>();

  public infoMessages: string[] = [];

  public errorMessages: string[] = [];

  constructor(
    timer: Timer,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
    super(timer, getFriendlyErrorAndLog);
  }

  public async run(): Promise<void>{
    let rows: any[];
    try {
      rows = this.getRowsFromDelimitedText(this.csvFile.content);
    } catch (error) {
      this.errorMessage = error.message;
      return;
    }

    try {
       let telemetryConfig: TelemetryConfig = this.convertRowsToTelemetryConfig(rows);

      this.telemetryConfigCreated.emit(
        new TelemetryFile(
          this.csvFile.name,
          telemetryConfig));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public getRowsFromDelimitedText(input: string): any[]{
    let delimiter = determineDelimiter(input);
    if(!delimiter){
      throw new DisplayableError('Unable to determine data delimiter. Ensure every row has the same number of columns.');
    }

    this.infoMessages.push('Detected file delimiter: ' + (delimiter === '\t' ? 'TAB' : delimiter));

    let dsv = d3.dsvFormat(delimiter);
    let rows = dsv.parseRows(input);
    this.infoMessages.push(`Found ${rows.length} rows.`);

    for(let row of rows){
      for(let columnIndex=0; columnIndex < row.length; ++columnIndex){
        row[columnIndex] = this.sanitizeRowValue(row[columnIndex]);
      }
    }

    return rows;
  }

  private sanitizeRowValue(input: string): string {
    input = input.trim();
    for(let quoteCharacter of QUOTE_CHARACTERS){
      if(input.startsWith(quoteCharacter) && input.endsWith(quoteCharacter)){
        input = input.substr(1, input.length - 2);
        break;
      }
    }
    return input;
  }

  public convertRowsToTelemetryConfig(rows: ReadonlyArray<ReadonlyArray<string>>): TelemetryConfig | undefined{
    let channelNames: ReadonlyArray<string>;
    let units: ReadonlyArray<string>;
    let dataStartIndex = this.findIndexOfFirstRowOfNumbers(rows);
    switch(dataStartIndex){
      case -1:
        throw new DisplayableError('Data row not found.');
      case 0:
        throw new DisplayableError('Header row not found.');
      case 1:
        channelNames = rows[0];
        units = channelNames.map(v => '');
        break;
      case 2:
        channelNames = rows[0];
        units = rows[1];
        break;
      case 3:
        channelNames = rows[1];
        units = rows[2];
        break;
      default:
        throw new DisplayableError('Too many header rows: ' + dataStartIndex);
    }

    units = units.map(v => v || '()');

    if(channelNames.length !== units.length) {
      throw new DisplayableError(`Units row length (${units.length}) does not match channel name row length (${channelNames.length}).`);
    }

    let numericRows: number[][] = new Array<number[]>(rows.length - dataStartIndex);
    for(let rowIndex = dataStartIndex, resultIndex = 0; rowIndex < rows.length; ++rowIndex, ++resultIndex) {
      let row = rows[rowIndex].map(v => this.parseAsNumberOrDefaultToNaN(v));
      if(row.length !== channelNames.length) {
        this.errorMessages.push(`Row index ${rowIndex} length (${row.length}) does not match channel name row length (${channelNames.length}).`);
      }
      numericRows[resultIndex] = row;
    }

    if(this.errorMessages.length) {
      return undefined;
    }

    let channels: MutableTelemetryChannel[] = channelNames.map((v, i) =>
      new MutableTelemetryChannel(v, units[i], '', numericRows.map(r => r[i])));

    // This handles empty channels caused by trailing commas,
    // as well as removing any unnamed channels in other columns.
    channels = channels.filter(v => v.name.length > 0);

    let telemetryConfig: TelemetryConfig = {
      channels
    };

    return telemetryConfig;
  }

  /*
  private parseAsNumber(value: string): number {
    let result = this.tryParseAsNumber(value);
    if(typeof result === 'undefined') {
      throw new Error('Failed to parse value as number: ' + value);
    }
    return result;
  }
  */

  private parseAsNumberOrDefaultToNaN(value: string): number {
    let result = this.tryParseAsNumber(value);
    if(typeof result === 'undefined') {
      return NaN;
    }
    return result;
  }

  private canParseAsNumber(value: string): boolean {
    let result = this.tryParseAsNumber(value);
    if(typeof result === 'undefined') {
      return false;
    }
    return true;
  }

  private tryParseAsNumber(value: string): number | undefined{
    return tryParseAsNumber(value);
  }

  private findIndexOfFirstRowOfNumbers(rows: ReadonlyArray<ReadonlyArray<string>>): number {
    for(let i = 0; i < rows.length; ++i) {
      if(rows[i].every(v => this.canParseAsNumber(v))) {
        return i;
      }
    }

    return -1;
  }
}

function determineDelimiter(text: string, delimiters: ReadonlyArray<string> = DELIMITERS) {
  let possibleDelimiters = delimiters.filter(isPossibleDelimiter);
  if(possibleDelimiters.length === 0){
    return undefined;
  }

  return possibleDelimiters[0];

  function isPossibleDelimiter(delimiter: string) {
    let cache = -1;
    return text.split('\n').every((line, i) => {
      // Skip the first line for ATLAS CSV format.
      if (!line || i === 0) {
        return true;
      }

      let length = line.split(delimiter).length;
      if (cache < 0) {
        cache = length;
      }
      return cache === length && length > 1;
    });
  }
}
