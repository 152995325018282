import {Component, Input, ElementRef} from '@angular/core';
import {WorksheetDock} from '../../../worksheets/worksheet-dock/worksheet-dock.service';

@Component({
    selector: 'cs-edit-config-sticky',
    templateUrl: './edit-config-sticky.component.html',
    styleUrls: ['./edit-config-sticky.component.scss'],
    standalone: false
})
export class EditConfigStickyComponent{

  private readonly bottomPadding = 15;

  @Input() public showSaved: boolean;
  @Input() public showDirty: boolean;
  @Input() public isSubmitDisabled: boolean;
  @Input() public isStaged: boolean;
  @Input() public submitButtonText: string;
  @Input() public additionalScrollOffset: number = 0;

  constructor(
    private readonly worksheetDock: WorksheetDock,
    private readonly _el: ElementRef){
  }

  public get bottomString(): string {
    if(this.worksheetDock.worksheet){
      return '' + (this.worksheetDock.height + this.bottomPadding) + 'px';
    }

    return '' + this.bottomPadding + 'px';
  }

  public scrollToTop(){
    this._el.nativeElement.scrollIntoView();
  }
}
