import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CustomProperty} from './custom-properties';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'cs-view-edit-custom-properties',
    templateUrl: './view-edit-custom-properties.component.html',
    styleUrls: ['./view-edit-custom-properties.component.scss'],
    standalone: false
})
export class ViewEditCustomPropertiesComponent implements OnInit, OnDestroy {

  @Input() public tenantId: string;
  @Input() public target: string;
  @Input() public initialProperties: CustomProperty[];
  @Input() public resultProperties: CustomProperty[];
  @Input() public form: UntypedFormGroup;
  @Input() public reset: Observable<void>;

  private subscription: Subscription;

  public isEditing = false;

  public ngOnInit(){
    if(this.reset){
      this.reset.subscribe(() => this.isEditing = false);
    }
  }

  public ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  public edit(){
    this.isEditing = true;
  }
}
