import {Component, Input, OnDestroy} from '@angular/core';
// import {simVersionToNumber} from '../../visualizations/sim-version-to-number';
import {CustomProperty} from '../../custom-properties/custom-properties';
import {CarConfigValidationSessionBase, CheckStatus} from '../car-config-validation-session-base';
import {CarConfigValidationSessionFactory} from '../car-config-validation-session-factory';
import {CarConfigValidationStagingArea} from '../car-config-validation-staging-area.service';
import {PromptDialog} from '../../../common/dialogs/prompt-dialog.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {InputValidationError} from '../../../common/errors/errors';

export const NO_EDITOR_VALUE_FUNCTION_ERROR = 'No function provided to get editor value.';
export const NO_EDITOR_VALUE_ERROR = 'Unable to check car unless it is valid.';

@Component({
    selector: 'cs-car-config-validator',
    templateUrl: './car-config-validator.component.html',
    styleUrls: ['./car-config-validator.component.scss'],
    standalone: false
})
export class CarConfigValidatorComponent implements OnDestroy {

  @Input() public tryGetEditorValue: (save: boolean) => Promise<string>;
  @Input() public simVersion: string;
  @Input() public isCarDirty: boolean;

  @Input() public userId: string;
  @Input() public configId: string;
  @Input() public name: string;
  @Input() public isEdited: boolean;
  @Input() public customProperties: ReadonlyArray<CustomProperty>;

  public errorMessage: string;
  public session: CarConfigValidationSessionBase;
  public checkStatusEnum = CheckStatus;
  public previousSession: CarConfigValidationSessionBase;

  constructor(
    private readonly carValidationSessionFactory: CarConfigValidationSessionFactory,
    private readonly carConfigValidationStagingArea: CarConfigValidationStagingArea,
    private readonly promptDialog: PromptDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  ngOnDestroy(): void {
    this.removeSession();
  }

  public async runCheck(save: boolean){
    try {
      this.errorMessage = undefined;
      this.removeSession();

      if(!this.tryGetEditorValue){
        throw new InputValidationError(NO_EDITOR_VALUE_FUNCTION_ERROR);
      }

      let editorValue = await this.tryGetEditorValue(save);
      if(!editorValue){
        throw new InputValidationError(NO_EDITOR_VALUE_ERROR);
      }

      // if(simVersionToNumber(this.simVersion) >= 1650){
      //   this.session = this.carValidationSessionFactory.createInlineStudySession(
      //     editorValue,
      //     this.simVersion,
      //     this.userId,
      //     this.configId,
      //     this.name,
      //     this.isEdited,
      //     this.customProperties);
      // }
      // else{
        this.session = this.carValidationSessionFactory.createNormalStudySession(
          editorValue,
          this.simVersion,
          this.userId,
          this.configId,
          this.name,
          this.isEdited,
          this.customProperties);
      // }

      await this.session.execute();
    } catch(error){
      this.removeSession();
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public removeSession(){
    try {
      this.errorMessage = undefined;
      if(this.session){
        this.session.dispose();

        if(this.session.status === CheckStatus.checked
          && this.session.isStudySuccessful){
          this.previousSession = this.session;
        }

        this.session = undefined;
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async saveSession(){
    try {
      this.errorMessage = undefined;
      let name = await this.promptDialog.show<string>(
        'Enter a name:',
        'Pin Results',
        'Pin',
        'Save',
        'Cancel');

      if(name){
        this.carConfigValidationStagingArea.add(
          this.session.jobViewModel.clone(name),
          this.session.editorValue);

        this.session.savedInStagingArea = true;
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public get savedSessionCount() {
    try {
      return this.carConfigValidationStagingArea.count();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      return 0;
    }
  }

  public get canSaveSession() {
    try {
      return !!(this.session
        && this.session.status === CheckStatus.checked
        && !this.session.savedInStagingArea
        && this.session.isStudySuccessful);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
      return false;
    }
  }

  public clearSavedSessions() {
    try {
      this.carConfigValidationStagingArea.clear();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}
