import {Component, Input} from '@angular/core';
import {ListVersionsDialog} from '../list-versions-dialog/list-versions-dialog.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {DocumentSubType} from '../../../../generated/api-stubs';
import {ConfirmationDialog} from '../../../common/dialogs/confirmation-dialog.service';
import {VersionedDocumentData} from '../versioned-document-data';
import {CanopyError} from '../../../common/errors/errors';
import {ISaveOutputConfigHandler} from '../../configs/comparing/compare-config-dialog/compare-config-dialog.service';
import { AuthenticationService } from '../../../identity/state/authentication.service';

export const LIST_VERSIONS_ERROR_TITLE: string = 'List Versions Error';

@Component({
    selector: 'cs-list-versions-dialog-launcher',
    templateUrl: './list-versions-dialog-launcher.component.html',
    styleUrls: ['./list-versions-dialog-launcher.component.scss'],
    standalone: false
})
export class ListVersionsDialogLauncherComponent {
  @Input() public tenantId: string;
  @Input() public userId: string;
  @Input() public configId: string;
  @Input() public getDocument: () => VersionedDocumentData;
  @Input() public setDocument: (document: VersionedDocumentData) => void;
  @Input() public configType: DocumentSubType;
  @Input() public compactButton: boolean = false;
  @Input() public saveOutputConfigHandler: ISaveOutputConfigHandler;

  constructor(
    private readonly prompt: ConfirmationDialog,
    private readonly listVersionsDialog: ListVersionsDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public get isAdministrator(): boolean {
    return this.authenticationService.isAdministrator;
  }

  public async launch() {
    try{
      if(!this.configType){
        throw new CanopyError('No config type specified.');
      }

      if(!this.getDocument){
        throw new CanopyError('No getDocument delegate was supplied.');
      }

      let document = this.getDocument();
      if(!document){
        throw new CanopyError('No version reference document was supplied.');
      }

      let result = await this.listVersionsDialog.show(
        this.tenantId,
        this.userId,
        this.configId,
        document,
        this.configType,
        this.saveOutputConfigHandler);

      if(result && result.loadedDocument && this.setDocument){
        this.setDocument(result.loadedDocument);
      }
    } catch(error){
      let message = this.getFriendlyErrorAndLog.execute(error);
      await this.prompt.show(message, LIST_VERSIONS_ERROR_TITLE);
    }
  }
}
