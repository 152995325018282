import {Directive, ElementRef} from '@angular/core';
import {DialogManager} from './dialog-manager.service';
import {OnInit} from '@angular/core';

export const DIALOG_IS_OPEN_CLASS_NAME = 'dialog-is-open';

const CLASS_NAME_WITH_SPACE_START = ' ' + DIALOG_IS_OPEN_CLASS_NAME;
const CLASS_NAME_WITH_SPACE_END = DIALOG_IS_OPEN_CLASS_NAME + ' ';

@Directive({
    selector: '[csSetBodyDialogStyle]',
    standalone: false
})
export class SetBodyDialogStyleDirective implements OnInit {
  private body: HTMLBodyElement;

  constructor(el: ElementRef, private dialogManager: DialogManager) {
    this.body = el.nativeElement.ownerDocument.body;
  }

  public ngOnInit(): any {
    this.dialogManager.primaryChanged.subscribe(() => this.dialogManagerChanged());
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public dialogManagerChanged(){
    let currentDialog = this.dialogManager.getPrimary() || this.dialogManager.getSecondary();
    if(currentDialog){
      if(this.body.className.indexOf(CLASS_NAME_WITH_SPACE_START) === -1
        && this.body.className.indexOf(CLASS_NAME_WITH_SPACE_END) === -1) {
        this.body.className += CLASS_NAME_WITH_SPACE_START;
      }
    } else{
      this.body.className = this.body.className.replace(CLASS_NAME_WITH_SPACE_START, '');
      this.body.className = this.body.className.replace(CLASS_NAME_WITH_SPACE_END, '');
    }
  }
}
