import {Component, OnInit} from '@angular/core';
import {SupportSessionDialogData, SupportSessionResult} from './support-session-dialog.service';
import {DialogManager, DialogBase} from '../../common/dialogs/dialog-manager.service';
import {SupportSessionDialogSession, SupportSessionDialogSessionFactory} from './support-session-dialog-session';

@Component({
    selector: 'cs-support-session-dialog',
    templateUrl: './support-session-dialog.component.html',
    styleUrls: ['./support-session-dialog.component.scss'],
    standalone: false
})
export class SupportSessionDialogComponent implements OnInit {
  public dialog: DialogBase<SupportSessionResult>;
  public session: SupportSessionDialogSession;

  constructor(
    private sessionFactory: SupportSessionDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  public ngOnInit(): any {
    this.dialogManager.tertiaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getTertiary();

    if(currentDialog && currentDialog instanceof SupportSessionDialogData){
      this.dialog = <SupportSessionDialogData>currentDialog;
      this.session = this.sessionFactory.create(<SupportSessionDialogData>currentDialog);
      this.session.load();
    } else {
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}
