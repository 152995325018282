import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormSubmissionButton } from '../../forms/form-submission-button';
import { FormBuilderModel } from './form-builder.model';
import { FormBuilderService } from '../../services/form-builder.service';
import { FormSubmissionComponent } from '../../forms/form-submission-component.interface';
import { FormSubmissionHandler } from '../../forms/form-submission-handler.service';
import { FormConfig, FormFieldConfig } from '../../interfaces/form-config/form-config.interface';

@Component({
    selector: 'cs-form-builder',
    templateUrl: './form-builder.component.html',
    standalone: false
})
export class FormBuilderComponent implements FormSubmissionComponent, OnInit {

  @Input()
  public model: FormBuilderModel;

  @Input()
  public onSubmit: () => Promise<void> = () => Promise.resolve();

  @Input()
  public errorMessage: string;

  @Output()
  public submitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public set formConfig(value: FormConfig) {
    this._formConfig = value;
  }

  public get isSubmitDisabled(): boolean {
    return !this.model.isDirty || !this.model.isValid || this.submitButton.isSubmitting;
  }

  public get submitButton(): FormSubmissionButton {
    return this._submitButton;
  }

  @ViewChild('formContainer', { read: ViewContainerRef, static: true }) public formContainer: ViewContainerRef;

  private _formConfig: FormConfig;
  private _submitButton: FormSubmissionButton = new FormSubmissionButton('Submit', 'Submitting...');

  constructor(
    private _formBuilderService: FormBuilderService,
    private _formSubmissionHandler: FormSubmissionHandler) {
  }

  /**
   * Handles the OnInit lifecycle event
   * @returns
   */
  public ngOnInit(): void {
    if (!this._formConfig || !this._formConfig.fields) {
      return;
    }

    for (let field of this._formConfig.fields) {
      this._initComponent(field);
    }
  }

  /**
   * Handles the click event of submit config permission and emits the submitted event
   */
  public async onFormSubmit(): Promise<void> {
    let success = await this._formSubmissionHandler.execute(this._submit, this.model.form, this.submitButton, this);
    this.submitted.emit(success);
  }

  /**
   * Initialises the component for the given field and inserts it into the form container
   * @param field
   */
  private _initComponent(field: FormFieldConfig): void {
    const component = this._formBuilderService.getFormComponent(field.component);
    const componentRef = this._formBuilderService.createFormComponent(component, this.formContainer);
    const componentInstance = componentRef.instance;
    componentInstance.formControl = this.model.getFormControl(field.name);
    componentInstance.label = field.label;
    componentInstance.name = field.name;
  }

  /**
   * Invokes the bound onSubmit action
   */
  private async _submit(): Promise<void> {
    this.model.form.disable();
    try {
      await this.onSubmit();
    } catch (err) {
      this.model.form.enable();
      throw err;
    }
    this.model.form.enable();
  }

}
