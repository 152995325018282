import { Component, OnInit } from '@angular/core';
import {DialogBase, DialogManager} from '../../common/dialogs/dialog-manager.service';
import {SelectSimVersionDialogData, SelectSimVersionResult} from './select-sim-version-dialog.service';
import {SelectSimVersionDialogSession, SelectSimVersionDialogSessionFactory} from './select-sim-version-dialog-session';
import {Subscription} from 'rxjs';

@Component({
    selector: 'cs-select-sim-version-dialog',
    templateUrl: './select-sim-version-dialog.component.html',
    styleUrls: ['./select-sim-version-dialog.component.scss'],
    standalone: false
})
export class SelectSimVersionDialogComponent implements OnInit {
  public isVisible: boolean = false;

  public dialog: DialogBase<SelectSimVersionResult>;
  public session: SelectSimVersionDialogSession;
  public sessionSubscription: Subscription;

  constructor(
    private sessionFactory: SelectSimVersionDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  public accept(result: SelectSimVersionResult) {
    if(this.dialog) {
      this.dialog.resolve(result);
    }
  }

  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  public dialogManagerChanged() {
    this.dismiss();

    let currentDialog = this.dialogManager.getSecondary();

    if(this.sessionSubscription){
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = undefined;
    }

    if(currentDialog && currentDialog instanceof SelectSimVersionDialogData){
      this.isVisible = true;
      this.dialog = <SelectSimVersionDialogData>currentDialog;
      this.session = this.sessionFactory.create(<SelectSimVersionDialogData>currentDialog);
      this.sessionSubscription = this.session.simVersionSelected.subscribe((data: SelectSimVersionResult) => this.accept(data));
    } else {
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}


