import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormCancellationHandler } from '../../forms/form-cancellation-handler.service';

@Component({
    selector: 'cs-form-back-button',
    templateUrl: './form-back-button.component.html',
    standalone: false
})
export class FormBackButtonComponent {

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public routeParams: any[];

  constructor(private _router: Router,
    private _formCancellationHandler: FormCancellationHandler) {}

  /**
   * Handles the form cancellation event and navigates back to the specified route
   */
  public async onCancel(): Promise<void> {
    const confirm = await this._formCancellationHandler.execute(this.form);
    if (confirm) {
      this._router.navigate(this.routeParams);
    }
  }

}
