import * as d3 from '../visualizations/d3-bundle';
import {Component, Input, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {StudyStub, StudyType} from '../../generated/api-stubs';
import {GetUsernameMap, UsernameMap} from '../simulations/get-username-map.service';
import {
  TenantStudyStatisticsUtilities,
  RawStatistics, ProcessedStatistics, ProcessedStatisticsForPeriodType
} from './tenant-study-statistics-utilities.service';
import {Dayjs} from '../common/dayjs.service';
import {StudyTypeLookupFactory, StudyTypeMap} from '../simulations/studies/study-type-lookup.service';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
    selector: 'cs-tenant-simulation-quota-view',
    templateUrl: './tenant-simulation-quota-view.component.html',
    styleUrls: ['./tenant-simulation-quota-view.component.scss'],
    standalone: false
})
export class TenantSimulationQuotaViewComponent implements OnInit {
  @Input() public tenantId: string;

  public errorMessage: string;

  public data: ViewModel;

  constructor(
    private authenticationService: AuthenticationService,
    private studyStub: StudyStub,
    private getUsernameMap: GetUsernameMap,
    private utilities: TenantStudyStatisticsUtilities,
    private dayjs: Dayjs,
    private studyTypeLookupFactory: StudyTypeLookupFactory,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {

      if(!this.tenantId){
        this.tenantId = this.authenticationService.userDataSnapshot?.tenant;
      }

      let studyTypeLookup = this.studyTypeLookupFactory.create(this.tenantId);

      let usernameMapTask = this.getUsernameMap.execute(this.tenantId);
      let studyTypeMapTask = studyTypeLookup.getStudyTypeMap();

      let startOfMonth = this.dayjs.create().utc().startOf('month');
      let startOfPreviousMonth = startOfMonth.subtract(1, 'month');

      let statisticsResult = await this.studyStub.getTenantStudyStatistics(this.tenantId, startOfPreviousMonth.format());
      let usernameMap = await usernameMapTask;
      let studyTypeMap = await studyTypeMapTask;

      this.data = this.createViewModel(statisticsResult.statistics, usernameMap, studyTypeMap);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public getBarWidth(count: number){
    if(!this.data.maxSimTypeCount){
      return '1px';
    }

    return Math.round((count / this.data.maxSimTypeCount) * 100) + '%';
  }

  public createViewModel(rawStatistics: RawStatistics, usernameMap: UsernameMap, studyTypeMap: StudyTypeMap){
    let statistics = this.processStatistics(rawStatistics, usernameMap);

    let studyTypes = this.utilities.getKeys(statistics.monthly.studyType) as StudyType[];

    let viewModel: ViewModel = {
      maxSimTypeCount: 1,
      months: []
    };

    for(let i=d3.min([2, statistics.monthly.dates.length]) - 1; i >= 0; --i){
      viewModel.months.push(this.createPeriodViewModel(statistics.monthly, i, studyTypes, studyTypeMap));
    }

    viewModel.maxSimTypeCount = d3.max(viewModel.months.map(m => d3.max(m.studyCategories.map(c => c.total.count))));

    return viewModel;
  }

  private createPeriodViewModel(statistics: ProcessedStatisticsForPeriodType, index: number, studyTypes: StudyType[], studyTypeMap: StudyTypeMap): PeriodInfo {
    let periodInfo: PeriodInfo = {
      name: index === 1 ? 'Current Month' : 'Previous Month',
      studyCategories: []
    };

    periodInfo.studyCategories.push(
      this.createStudyCategoryViewModel(
        statistics,
        index,
        studyTypes,
        studyTypeMap,
        'All Simulations'));

    return periodInfo;
  }

  private createStudyCategoryViewModel(statistics: ProcessedStatisticsForPeriodType, index: number, studyTypes: string[], studyTypeMap: StudyTypeMap, categoryName: string): StudyCategoryInfo{
    let result: StudyCategoryInfo = {
      name: categoryName,
      total: undefined,
      studyTypes: []
    };

    for(let studyType of studyTypes){
      let studyTypeStatistics = statistics.studyType[studyType];
      let count = 0;
      let computeCredits = 0;
      let storageCredits = 0;
      if(studyTypeStatistics && studyTypeStatistics.length > index){
        count = studyTypeStatistics[index].succeededSimulations;
        computeCredits = studyTypeStatistics[index].succeededComputeCredits;
        storageCredits = studyTypeStatistics[index].succeededStorageCredits;
      }

      let studyConfigType = studyTypeMap[studyType];
      result.studyTypes.push({
        name: studyConfigType ? studyConfigType.name : studyType,
        count,
        computeCredits,
        storageCredits
      });
    }

    result.total ={
      name: 'Total',
      count: result.studyTypes.reduce((p, c) => p + c.count, 0),
      computeCredits: result.studyTypes.reduce((p, c) => p + c.computeCredits, 0),
      storageCredits: result.studyTypes.reduce((p, c) => p + c.storageCredits, 0),
    };

    return result;
  }

  public processStatistics(statistics: RawStatistics, usernameMap: UsernameMap): ProcessedStatistics {
    let dateStrings = this.getDateStrings(statistics);
    let monthly = this.utilities.getProcessedStatistics(statistics, dateStrings.months, 'monthly', usernameMap);
    return {
      monthly,
      daily: undefined
    };
  }

  public getDateStrings(statistics: RawStatistics): { months: string[] } {
    let ranges = this.utilities.getDateRanges(statistics, false);

    return {
      months: this.utilities.getDatesForRange(ranges.minMonth, ranges.maxMonth, 'YYYY-MM', 'M')
    };
  }
}

interface ViewModel {
  maxSimTypeCount: number;
  months: PeriodInfo[];
}

interface PeriodInfo {
  name: string;
  studyCategories: StudyCategoryInfo[];
}

interface StudyCategoryInfo {
  name: string;
  total: ItemInfo;
  studyTypes: ItemInfo[];
}

interface ItemInfo {
  name: string;
  count: number;
  computeCredits: number;
  storageCredits: number;
}
