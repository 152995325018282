import {Component, Input} from '@angular/core';

/**
 * Component for displaying an error message for a worksheet item.
 */
@Component({
    selector: 'cs-worksheet-item-error-message',
    templateUrl: './worksheet-item-error-message.component.html',
    styleUrls: ['./worksheet-item-error-message.component.scss'],
    standalone: false
})
export class WorksheetItemErrorMessageComponent{

  /**
   * The error message to display.
   */
  @Input() public errorMessage: string;
}
