import {Component, ViewChild} from '@angular/core';
import {CONFIG_TYPE_DATA_KEY, ConfigType, IS_DEFAULT_DATA_KEY} from '../config-types';
import {IS_STAGED_DATA_KEY} from '../config-types';
import {InferableRouteParams} from '../../../common/inferable-route-params.service';
import {RouterCanDeactivate} from '../../../common/can-deactivate';
import {ActivatedRoute} from '@angular/router';
import {EditConfigComponent} from '../edit-config/edit-config.component';
import {IsWorksheetPage} from '../../is-worksheet-page';

export const CONFIG_ID_URL_PARAMETER_KEY: string = 'configId';
export const CONFIG_NAME_URL_PARAMETER_KEY: string = 'configName';
export const SIM_VERSION_URL_PARAMETER_KEY: string = 'simVersion';

@Component({
    templateUrl: './edit-config.page.html',
    styleUrls: ['./edit-config.page.scss'],
    standalone: false
})
export class EditConfigPage implements RouterCanDeactivate {
  public tenantId: string;
  public userId: string;
  public configId: string;
  public isStaged: boolean;
  public isDefault: boolean;
  public isWorksheetPage: boolean;

  public simVersion: string;
  public configName: string;
  public configType: ConfigType;

  @ViewChild('editConfigComponent', { static: true }) editConfigComponent: EditConfigComponent;

  constructor(
    isWorksheetPage: IsWorksheetPage,
    route: ActivatedRoute,
    inferableRouteParams: InferableRouteParams) {

    this.isWorksheetPage = isWorksheetPage.execute(route);

    this.tenantId = inferableRouteParams.getTenantId(route);

    this.configType = route.snapshot.data[CONFIG_TYPE_DATA_KEY];
    this.isStaged = !!route.snapshot.data[IS_STAGED_DATA_KEY];
    this.isDefault = !!route.snapshot.data[IS_DEFAULT_DATA_KEY];

    if(!this.isStaged && !this.isDefault) {
      this.configId = route.snapshot.params[CONFIG_ID_URL_PARAMETER_KEY];
    } else if(this.isStaged){
    } else if(this.isDefault){
      this.configName = route.snapshot.params[CONFIG_NAME_URL_PARAMETER_KEY];
      this.simVersion = route.snapshot.params[SIM_VERSION_URL_PARAMETER_KEY];
    }
  }

  public routerCanDeactivate() {
    return this.editConfigComponent.routerCanDeactivate();
  }
}
