import { Component, OnInit } from '@angular/core';
import {DialogBase, DialogManager} from '../../common/dialogs/dialog-manager.service';
import {Subscription} from 'rxjs';
import {StudyLoaderDialogData, StudyLoaderResult} from './study-loader-dialog.service';
import {StudyLoaderDialogSession, StudyLoaderDialogSessionFactory} from './study-loader-dialog-session';

/**
 * The study loader dialog component.
 */
@Component({
    selector: 'cs-study-loader-dialog',
    templateUrl: './study-loader-dialog.component.html',
    styleUrls: ['./study-loader-dialog.component.scss'],
    standalone: false
})
export class StudyLoaderDialogComponent implements OnInit {

  /**
   * Whether the dialog is visible.
   */
  public isVisible: boolean = false;

  /**
   * The dialog.
   */
  public dialog: DialogBase<StudyLoaderResult>;

  /**
   * The session.
   */
  public session: StudyLoaderDialogSession;

  /**
   * The subscription to the dialog manager.
   */
  public sessionSubscription: Subscription;

  /**
   * Creates an instance of StudyLoaderDialogComponent.
   * @param sessionFactory The study loader dialog session factory.
   * @param dialogManager The dialog manager.
   */
  constructor(
    private sessionFactory: StudyLoaderDialogSessionFactory,
    private dialogManager: DialogManager) {
  }

  /**
   * Initializes the component.
   */
  public ngOnInit(): any {
    this.dialogManager.secondaryChanged.subscribe(() => this.dialogManagerChanged());
  }

  /**
   * Called when the user accepts the dialog by selecting a study.
   * @param result The result.
   */
  public accept(result: StudyLoaderResult) {
    if(this.dialog) {
      this.dialog.resolve(result);
    }
  }

  /**
   * Called when the user dismisses the dialog without selecting a study.
   */
  public dismiss() {
    if(this.dialog){
      this.dialog.dismiss();
    }
  }

  /**
   * Called when the dialog manager changes.
   */
  public dialogManagerChanged() {
    // Close the dialog if it's already open.
    this.dismiss();

    // Get the current secondary dialog.
    let currentDialog = this.dialogManager.getSecondary();

    // If there is already a session subscription then unsubscribe from it.
    if(this.sessionSubscription){
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = undefined;
    }

    if(currentDialog && currentDialog instanceof StudyLoaderDialogData){
      // If the current dialog is a study loader dialog then show it.
      this.isVisible = true;
      this.dialog = <StudyLoaderDialogData>currentDialog;
      this.session = this.sessionFactory.create(<StudyLoaderDialogData>currentDialog);
      this.sessionSubscription = this.session.studySelected.subscribe((data: StudyLoaderResult) => this.accept(data));
      this.session.load();
    } else {
      // Otherwise hide the dialog.
      this.isVisible = false;
      this.session = undefined;
      this.dialog = undefined;
    }
  }
}
