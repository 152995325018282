import {Component, Input} from '@angular/core';
import {JobViewModel} from '../job-view-model';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ViewJobResultsSectionBase} from '../view-job-results-section-base';
import {StudyJobState} from '../../../../../generated/api-stubs';
import {JobSelectorState} from '../job-selector-state';

@Component({
    selector: 'cs-view-job-errors',
    templateUrl: './view-job-errors.component.html',
    styleUrls: ['./view-job-errors.component.scss'],
    standalone: false
})
export class ViewJobErrorsComponent extends ViewJobResultsSectionBase {
  @Input() public jobSelectorState: JobSelectorState;
  public job: JobViewModel = null;

  public jobsWithErrors: JobViewModel[];

  constructor(
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    super(getFriendlyErrorAndLog);
  }

  protected async load(){
    let jobsWithErrors: JobViewModel[] = [];
    for(let job of this.jobs){
      await job.jobMetadataResult.tryLoad();
      await job.studyMetadataResult.tryLoad();

      let show = false;
      if(job.jobMetadataResult.value){
        if(job.jobMetadataResult.value.studyJob.data.state === StudyJobState.failed){
          show = true;
        } else if(job.jobMetadataResult.value.studyJob.data.errorMessages){
          show = !!job.jobMetadataResult.value.studyJob.data.errorMessages.length;
        }
      } else if(job.studyMetadataResult.value && job.studyMetadataResult.value.study.data.errorMessages){
        show = !!job.studyMetadataResult.value.study.data.errorMessages.length;
      }

      if(show){
        await job.diagnosis.tryLoad();
        jobsWithErrors.push(job);
      }
    }

    this.jobsWithErrors = jobsWithErrors;
  }

  public async selectJob(job: JobViewModel) {
    try{
      this.job = job;
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}
