import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {SimVersionDocumentCache} from '../simulations/sim-version-document-cache.service';
import {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {CanopyJson} from '../common/canopy-json.service';
import {cssSanitize} from '../common/css-sanitize';
import {sortBy} from '../common/sort-by';
import {SimVersionStub, TenancyStub} from '../../generated/api-stubs';
import {
  CompareConfigDialog,
  NO_SAVE_OUTPUT_CONFIG_HANDLER
} from '../simulations/configs/comparing/compare-config-dialog/compare-config-dialog.service';
import {GetSimVersion} from '../common/get-sim-version.service';
import {defaultConfigToStudyInput} from '../worksheets/study-input-utilities';
import {ConfigOrConfigLoader} from '../simulations/configs/comparing/config-or-config-loader';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
    templateUrl: './view-sim-version-documents.page.html',
    styleUrls: ['./view-sim-version-documents.page.scss'],
    standalone: false
})
export class ViewSimVersionDocumentsPage implements OnInit {

  public errorMessage: string;
  public documents: Document[];
  public simVersion: string;
  public isAdministrator: boolean;
  public tenants: Tenant[];
  public selectedTenantId: string;

  constructor(
    private readonly documentCache: SimVersionDocumentCache,
    private readonly simVersionStub: SimVersionStub,
    private readonly json: CanopyJson,
    private readonly authenticationService: AuthenticationService,
    private readonly tenancyStub: TenancyStub,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.isAdministrator = this.authenticationService.isAdministrator;

      if(this.isAdministrator){
        let tenantsResult = await this.tenancyStub.getTenants();
        this.tenants = tenantsResult.tenants.map(v => new Tenant(v.tenantId, v.name));
        this.tenants.sort(sortBy('name'));
        this.selectedTenantId = this.tenants.length  ? this.tenants[0].tenantId : undefined;
      }

      let documentsResult = await this.documentCache.get(this.getSimVersion.currentSimVersion);
      this.simVersion = documentsResult.simVersion;
      this.documents = [];
      for(let document of documentsResult.documents){
        this.documents.push({
          name: document.name,
          content: document.content ? this.json.format(document.content) : undefined,
          id: cssSanitize(document.name),
          loading: false,
          comparing: false
        });
      }

      this.documents.sort(sortBy('name'));
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async loadDocument(document: Document) {
    try {
      document.loading = true;
      let documentResult = await this.simVersionStub.getDocument(this.simVersion, document.name);
      document.content = this.json.format(documentResult.document.content);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    document.loading = false;
  }

  public async compareDocument(document: Document) {
    try {
      this.errorMessage = undefined;
      if(!this.selectedTenantId){
        return;
      }

      document.comparing = true;
      let source = this.json.parse(document.content);
      if(!source){
        let documentResult = await this.simVersionStub.getDocument(this.simVersion, document.name);
        source = this.json.parse(documentResult.document.content);
        document.content = this.json.stringify(source);
      }

      let targetDocumentResult = await this.simVersionStub.getDocument(this.simVersion, document.name, this.selectedTenantId);
      let target = this.json.parse(targetDocumentResult.document.content);

      let sourceStudyInput = defaultConfigToStudyInput(undefined, document.name, source, this.simVersion);
      let targetStudyInput = defaultConfigToStudyInput(undefined, document.name, target, this.simVersion);

      await this.compareConfigDialog.compare(
        undefined,
        [
          new ConfigOrConfigLoader('target document', targetStudyInput, undefined),
          new ConfigOrConfigLoader('selected document', sourceStudyInput, undefined),
        ],
        NO_SAVE_OUTPUT_CONFIG_HANDLER);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    document.comparing = false;
  }
}

interface Document {
  name: string;
  content: string;
  id: string;
  loading: boolean;
  comparing: boolean;
}

class Tenant {
  constructor(public tenantId: string, public name: string){}
}
