import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {DocumentSubType, DocumentType, GetSupportSessionQueryResult} from '../../../generated/api-stubs';
import {SupportSessionDialog} from './support-session-dialog.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {UserInformationMap, CreateUserInformationMaps} from '../create-user-information-maps.service';
import { FetchUserState } from '../../user-state/fetch-user-state.service';

@Component({
    selector: 'cs-support-session-launcher',
    templateUrl: './support-session-launcher.component.html',
    styleUrls: ['./support-session-launcher.component.scss'],
    standalone: false
})
export class SupportSessionLauncherComponent implements AfterViewInit, OnInit {

  @Input() public tenantId: string;
  @Input() public documentId: string;
  @Input() public documentType: DocumentType;
  @Input() public documentSubType: DocumentSubType;
  @Input() public supportSession: GetSupportSessionQueryResult;
  @Output() public supportSessionChanged: EventEmitter<GetSupportSessionQueryResult> = new EventEmitter<GetSupportSessionQueryResult>();

  public errorMessage: string;

  public map: UserInformationMap;
  public showLauncher: boolean = false;

  constructor(
    private supportSessionDialog: SupportSessionDialog,
    private createUserInformationMaps: CreateUserInformationMaps,
    private fetchUserState: FetchUserState,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit(){
    try {
      if(this.supportSession){
        this.map = this.createUserInformationMaps.execute(this.supportSession.userInformation);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async ngAfterViewInit() {
    const userState = await this.fetchUserState.get();
    this.showLauncher = !userState.isMicroCanopy;
  }

  public async open() {
    try {
      let populatedSession: GetSupportSessionQueryResult;
      if(this.supportSession && this.supportSession.session && this.supportSession.session.responses){
        populatedSession = this.supportSession;
      }

      let result = await this.supportSessionDialog.show(
        this.tenantId,
        this.documentId,
        this.documentType,
        this.documentSubType,
        populatedSession);

      if(result){
        this.supportSessionChanged.emit(result);
        this.map = this.createUserInformationMaps.execute(result.userInformation);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}
