import {Component, Input} from '@angular/core';

@Component({
    selector: 'cs-view-credits',
    templateUrl: './view-credits.component.html',
    styleUrls: ['./view-credits.component.scss'],
    standalone: false
})
export class ViewCreditsComponent {
  @Input() public computeCredits: number;
  @Input() public storageCredits: number;
}
