import {Component, Input} from '@angular/core';
import {CustomProperty} from './custom-properties';

@Component({
    selector: 'cs-view-custom-properties',
    templateUrl: './view-custom-properties.component.html',
    styleUrls: ['./view-custom-properties.component.scss'],
    standalone: false
})
export class ViewCustomPropertiesComponent {
  @Input() public customProperties: CustomProperty[];
  @Input() public showDivider: boolean = true;
}
