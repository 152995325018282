import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  CompareConfigDialog,
  NO_SAVE_OUTPUT_CONFIG_HANDLER
} from '../comparing/compare-config-dialog/compare-config-dialog.service';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {DocumentSubType, GetStudyJobQueryResult, GetStudyQueryResult,} from '../../../../generated/api-stubs';
import {studyResultToStudyInput} from '../../../worksheets/study-input-utilities';
import {ConfigOrConfigLoader} from '../comparing/config-or-config-loader';
import {getSourceConfig} from '../../../worksheets/study-utilities';
import {InferInputSources} from '../../studies/infer-input-sources.service';
import {StageInputSource} from '../../studies/stage-input-source.service';
import {SourceItem} from './source-item';
import { AuthenticationService } from '../../../identity/state/authentication.service';

@Component({
    selector: 'cs-view-study-sources',
    templateUrl: './view-study-sources.component.html',
    styleUrls: ['./view-study-sources.component.scss'],
    standalone: false
})
export class ViewStudySourcesComponent implements OnInit, OnChanges {
  @Input() public studyResult: GetStudyQueryResult;
  @Input() public jobResult: GetStudyJobQueryResult;

  public simVersion: string;
  public sources: SourceItem[];
  public errorMessage: string;

  public get isAdministrator(): boolean {
    return this.authenticationService.isAdministrator;
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly inferInputSources: InferInputSources,
    private readonly stageInputSource: StageInputSource,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog
  ){
  }

  public ngOnInit(): void {
    this.ngOnChanges();
  }

  ngOnChanges(): void {
    try{
      this.simVersion = this.jobResult ? this.jobResult.convertedSimVersion : this.studyResult.convertedSimVersion;

      this.sources = this.inferInputSources.execute(this.studyResult, this.jobResult);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public stageSource(source: SourceItem) {
    try {
      this.stageInputSource.execute(source, this.studyResult, this.jobResult);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async compareToStaged(source: SourceItem) {
    try {
      let staged = await this.studyStagingArea.getInput(source.typeKey, this.simVersion);
      let sourceConfig = studyResultToStudyInput(source.typeKey, this.studyResult);
      if(sourceConfig){
        let sourceData = this.getSourceConfig(source.typeKey);
        sourceConfig = sourceConfig.withData(sourceData);
      }

      await this.compareConfigDialog.compare(
        source.typeKey,
        [
          new ConfigOrConfigLoader('current', sourceConfig, undefined),
          new ConfigOrConfigLoader('staged', staged, undefined),
        ],
        NO_SAVE_OUTPUT_CONFIG_HANDLER);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public getConfigOrConfigLoaderCallback(key: DocumentSubType){
    return () => this.getConfigForCompare(key);
  }

  public getConfigForCompare(key: DocumentSubType): ConfigOrConfigLoader {
    let current = studyResultToStudyInput(key, this.studyResult);
    return new ConfigOrConfigLoader('study input', current, undefined);
  }

  private getSourceConfig(key: string){
    return getSourceConfig(key, this.studyResult, this.jobResult);
  }
}

