import {Component} from '@angular/core';
import {CanopyValidators} from '../common/forms/canopy-validators.service';
import {OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {FormSubmissionButton} from '../common/forms/form-submission-button';
import {MembershipStub} from '../../generated/api-stubs';
import {FormSubmissionHandler} from '../common/forms/form-submission-handler.service';
import {DisplayableError} from '../common/errors/errors';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

export const INCORRECT_PARAMETERS_ERROR = 'You must supply a User ID and token.';

@Component({
    templateUrl: './reset-password.page.html',
    standalone: false
})
export class ResetPasswordPage implements OnInit {
  public form: UntypedFormGroup;
  public password: UntypedFormControl = new UntypedFormControl('', [Validators.required, ...CanopyValidators.passwordValidators]);

  public errorMessage: string;
  public submitButton = new FormSubmissionButton('Reset Password', 'Resetting...');

  public tokenInvalid = false;
  public submitted = false;

  private userId: string;
  private token: string;

  constructor(
    formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    private membershipStub: MembershipStub,
    private formSubmissionHandler: FormSubmissionHandler) {
    this.form = formBuilder.group({
      password: this.password
    });
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      this.userId = this.route.snapshot.queryParams['userId'];
      this.token = decodeURIComponent(this.route.snapshot.queryParams['token']);

      if (!this.userId || !this.token) {
        throw new DisplayableError(INCORRECT_PARAMETERS_ERROR);
      }

      try {
        await this.membershipStub.getPasswordResetTokenValidity(this.userId, this.token);
      } catch (validityError) {
        if (validityError.isFromApi && validityError.response.status === 404) {
          this.tokenInvalid = true;
        } else {
          throw validityError;
        }
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async onSubmit() {
    let success = await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);

    if (success) {
      this.submitted = true;
    }
  }

  private async submit() {
    await this.membershipStub.postPasswordResetConfirmation({
      userId: this.userId,
      newPassword: this.password.value,
      token: this.token
    });
  }
}
