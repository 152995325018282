import {ResultsState} from '../../../results-staging-area/results-staging-area.service';
import {ActivatedRoute} from '@angular/router';
import {Rison} from '../../../../common/canopy-rison';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {Component, OnInit} from '@angular/core';
import {JobViewModel} from '../job-view-model';
import {GetJobsFromResultSources} from '../get-job-view-models-from-result-sources';

@Component({
    templateUrl: './view-job-results.page.html',
    styleUrls: ['./view-job-results.page.scss'],
    standalone: false
})
export class ViewJobResultsPage implements OnInit {
  public errorMessage: string;

  public jobs: ReadonlyArray<JobViewModel>;

  public noViewableData: boolean;
  public dashboardId: string;

  constructor(
    private route: ActivatedRoute,
    private rison: Rison,
    private getJobsFromResultSources: GetJobsFromResultSources,
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
    this.dashboardId = 'dashboard-' + ('' + Math.random()).substring(2);
  }

  public ngOnInit() {
    this.load();
  }

  public async load() {
    try {
      let data = this.route.snapshot.params['data'];
      if(data){
        let decoded: any;
        try {
          decoded = this.rison.decodeObject(data);
        } catch(error) {
          try {
            // Applications often don't recognise the final apostrophe as part of the URL,
            // so we try adding it back on to see if that helps.
            decoded = this.rison.decodeObject(data + '\'');
          } catch(error2){
            throw error;
          }
        }
        let resultState = ResultsState.fromMutable(decoded);
        this.jobs = await this.getJobsFromResultSources.execute(resultState.sources);
      }
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public goBack() {
    window.history.back();
  }
}
