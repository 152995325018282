import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ButtonMenuItem,
  IMenuItemWrapper,
  MenuItem,
  MenuItemType,
  SeparatorMenuItem, SubMenuItem,
} from '../context-menu-types';
import { Size } from '../../visualizations/viewers/size';

@Component({
    selector: 'cs-context-menu-renderer',
    templateUrl: './context-menu-renderer.component.html',
    styleUrls: ['./context-menu-renderer.component.scss'],
    standalone: false
})
export class ContextMenuRendererComponent {

  @Input() public readonly items: ReadonlyArray<MenuItem<any>>;
  @Input() public readonly wrapper: IMenuItemWrapper<any>;
  @Input() public readonly horizontalExpandDirection: HorizontalExpandDirection;
  @Input() public readonly verticalExpandDirection: VerticalExpandDirection;
  @Input() public readonly screenSize: Size;
  @Input() public readonly enableScroll: boolean = false;

  @Output() public closeRequested: EventEmitter<void> = new EventEmitter<void>();

  public readonly MenuItemType = MenuItemType;

  constructor() { }

  public async executeAction(event: MouseEvent, item: MenuItem<any>) {
    if (item instanceof ButtonMenuItem) {
      this.requestClose();

      try {
        if (this.wrapper) {
          await this.wrapper.executeMenuItemAction(event, item.action);
        } else {
          await item.action(event);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  public get maximumScrollableHeight(): string {
    if (this.enableScroll && this.screenSize) {
      return '' + this.screenSize.height * 2 / 5 + 'px';
    }

    return undefined;
  }

  public asButton(item: MenuItem<any>): ButtonMenuItem<any> {
    return item as any as ButtonMenuItem<any>;
  }

  public asSpacer(item: MenuItem<any>): SeparatorMenuItem {
    return item as SeparatorMenuItem;
  }

  public asSubMenu(item: MenuItem<any>): SubMenuItem<any> {
    return item as any as SubMenuItem<any>;
  }

  public get subMenuTopString(): string {
    if (this.verticalExpandDirection === VerticalExpandDirection.up) {
      return undefined;
    }
    return '0';
  }

  public get subMenuLeftString(): string {
    if (this.horizontalExpandDirection === HorizontalExpandDirection.left) {
      return undefined;
    }
    return '100%';
  }

  public get subMenuBottomString(): string {
    if (this.verticalExpandDirection === VerticalExpandDirection.up) {
      return '0';
    }
    return undefined;
  }

  public get subMenuRightString(): string {
    if (this.horizontalExpandDirection === HorizontalExpandDirection.left) {
      return '100%';
    }
    return undefined;
  }

  public requestClose() {
    this.closeRequested.emit();
  }
}

export enum HorizontalExpandDirection {
  left,
  right,
}

export enum VerticalExpandDirection {
  up,
  down,
}
