/*
 * Usage:
 *   value_with_underscores | customLineBreaks
 */
import {Pipe, PipeTransform} from '@angular/core';
import {renderWithCustomLineBreaks} from './render-with-custom-line-breaks';

@Pipe({
    name: 'customLineBreaks',
    standalone: false
})
export class CustomLineBreaksPipe implements PipeTransform {
  transform(value: string): any {
    return renderWithCustomLineBreaks(value);
  }
}
