/*
 * Usage:
 *   value | toPrecision:dp
 * Example:
 *   {{ 2.1234 |  toPrecision:2}}
 *   formats to: 2.12
 */
import {Pipe, PipeTransform} from '@angular/core';
import * as d3 from '../visualizations/d3-bundle';

@Pipe({
    name: 'toPrecision',
    standalone: false
})
export class ToPrecisionPipe implements PipeTransform {
  transform(value: number | number[], significantFigures: number): any {
    return ToPrecisionPipe.execute(value, significantFigures);
  }

  public static execute(value: number | number[], significantFigures: number): string{
    if(significantFigures === -1){
      return '' + value;
    }
    if(Array.isArray(value)){
      let ret: string = '';
      value.forEach(element => {
        ret += this.executeInner(element, significantFigures) + ', ';
      });
      return ret.substring(0, ret.length - 2);
    } else{
      return this.executeInner(value, significantFigures);
    }
  }

  private static executeInner(value: number, significantFigures: number) : string {
    let scale = Math.floor(Math.log10(Math.abs(value)));
    // switch at scale < -3 so that width of exponent notation is the same as decimal notation at the switch point
    // switch at scale >= significantFigures so that there are no trialing zeros so it's clear that rounding is occuring
    let type = value != 0 && (scale < -3 || scale >= significantFigures) ? 'e' : 'r';
    if(type === 'e'){
      significantFigures--;
    }
    return d3.format('.'+ significantFigures + type)(value);
  }
}
