import {Component, Input} from '@angular/core';
import {DocumentSubType} from '../../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ConfirmationDialog} from '../../../../common/dialogs/confirmation-dialog.service';
import {ConfigLoaderDialog} from '../../config-loader-dialog/config-loader-dialog.service';
import {CompareConfigDialog, ISaveOutputConfigHandler} from '../compare-config-dialog/compare-config-dialog.service';
import {GetSimVersion} from '../../../../common/get-sim-version.service';
import {ConfigOrConfigLoader} from '../config-or-config-loader';

export const ERROR_TITLE = 'Error';

@Component({
    selector: 'cs-compare-to-something-button',
    templateUrl: './compare-to-something-button.component.html',
    styleUrls: ['./compare-to-something-button.component.scss'],
    standalone: false
})
export class CompareToSomethingButtonComponent {
  @Input() public compactButton: boolean = false;
  @Input() public configType: DocumentSubType;
  @Input() public simVersion: string | undefined;
  @Input() public getConfigOrConfigLoader: () => ConfigOrConfigLoader;
  @Input() public saveOutputConfigHandler: ISaveOutputConfigHandler | undefined;

  constructor(
    private readonly configLoaderDialog: ConfigLoaderDialog,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly prompt: ConfirmationDialog,
    private readonly getSimVersion: GetSimVersion,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public async execute() {
    try {
      let simVersion = this.simVersion || this.getSimVersion.currentSimVersion;

      let selectedConfig = await this.configLoaderDialog.loadConfig(
        this.configType,
        simVersion,
        undefined,
        undefined,
        false,
        true);

      if(!selectedConfig){
        return;
      }

      let configs: ConfigOrConfigLoader[] = [
        this.getConfigOrConfigLoader(),
      ];

      if(selectedConfig.configId){
        configs.push(new ConfigOrConfigLoader('selected', selectedConfig.config, undefined));
      }

      await this.compareConfigDialog.compare(
        this.configType,
        configs,
        this.saveOutputConfigHandler);
    } catch(error){
      const message = this.getFriendlyErrorAndLog.execute(error);
      await this.prompt.show(message, ERROR_TITLE);
    }
  }
}
