
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BootstrapAutomatedTests } from '../bootstrap-automated-tests';

@Component({
    selector: 'cs-visualizations-tests-page',
    imports: [],
    template: `
  <div id="automated-tests">
    <h3>Automated Tests</h3>
    <div id="automated-test-buttons"></div>
    <div id="automated-test-stage"></div>
  </div>`,
    styleUrls: ['./visualization-tests-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisualizationTestsPageComponent implements OnInit {

  constructor(
    private readonly bootstrapAutomatedTests: BootstrapAutomatedTests) { }

  ngOnInit() {
    this.bootstrapAutomatedTests.buildPage();
  }
}
