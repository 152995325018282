import { Component, Input, OnInit } from '@angular/core';
import { FormSubmissionButton } from '../common/forms/form-submission-button';
import { FormSubmissionHandler } from '../common/forms/form-submission-handler.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AccountSettingsStub, MultiFactorAuthenticationStub } from '../../generated/api-stubs';
import { FetchUserState } from '../user-state/fetch-user-state.service';

@Component({
    selector: 'cs-multi-factor-authentication',
    templateUrl: './multi-factor-authentication.component.html',
    standalone: false
})
export class MultiFactorAuthenticationComponent implements OnInit {

  @Input() public tenantId: string;
  @Input() public userId: string;

  public errorMessage: string;
  public submitButton: FormSubmissionButton = new FormSubmissionButton('', '');
  public form: UntypedFormGroup;
  public isTwoFactorEnabled: boolean = false;
  public key: string;
  public qrCodeImageUrl: string;
  public isLoading: boolean = false;
  public isSelf: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private multiFactorAuthenticationStub: MultiFactorAuthenticationStub,
    private formSubmissionHandler: FormSubmissionHandler,
    private fetchUserState: FetchUserState,
    private accountSettingsStub: AccountSettingsStub,
  ) {
    this.form = this.formBuilder.group({});
  }

  public async ngOnInit(): Promise<void> {
    await this.load();
  }

  public async onSubmit(): Promise<void> {
    await this.formSubmissionHandler.execute(this.submit, this.form, this.submitButton, this);
  }

  public async submit(): Promise<void> {
    await this.multiFactorAuthenticationStub.postMultiFactorAuthentication(this.tenantId, this.userId, { isEnabled: !this.isTwoFactorEnabled });

    if (this.isSelf) {
      await this.fetchUserState.update();
    }

    await this.load();
  }

  private async load(): Promise<void> {
    this.isLoading = true;

    const userState = await this.fetchUserState.get();

    this.isSelf = userState.userId === this.userId;

    if (this.isSelf) {
      this.isTwoFactorEnabled = userState.isTwoFactorEnabled;
      this.setSubmitButton(userState.isTwoFactorEnabled);

      if (this.isTwoFactorEnabled) {
        await this.getMultiFactorAuthenticationCode();
      }
    } else {
      const user = await this.accountSettingsStub.get(this.tenantId, this.userId);

      this.isTwoFactorEnabled = user.isTwoFactorEnabled;
      this.setSubmitButton(user.isTwoFactorEnabled);
    }

    this.isLoading = false;
  }

  private async getMultiFactorAuthenticationCode(): Promise<void> {
    const response = await this.multiFactorAuthenticationStub.getMultiFactorAuthenticationCode(this.tenantId, this.userId);

    this.key = response?.key;
    this.qrCodeImageUrl = response?.qrCodeImageUrl;
  }

  private setSubmitButton(isTwoFactorEnabled: boolean): void {
    const defaultAction = isTwoFactorEnabled ? 'Disable' : 'Enable';
    const submittingAction = isTwoFactorEnabled ? 'Disabling' : 'Enabling';

    this.submitButton = new FormSubmissionButton(`${defaultAction} Multi Factor Authentication`, `${submittingAction} Multi Factor Authentication...`);
  }
}
