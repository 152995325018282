import { Component } from '@angular/core';

@Component({
    selector: 'cs-encryption-help-links',
    templateUrl: './encryption-help-links.component.html',
    styleUrls: ['./encryption-help-links.component.scss'],
    standalone: false
})
export class EncryptionHelpLinksComponent {

  constructor() { }

}
