import {Component, Input, OnInit} from '@angular/core';
import {DocumentSubType, GetStudyJobQueryResult, GetStudyQueryResult, StudyType} from '../../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ConfigTypeLookup} from '../../configs/config-types';
import {
  getConfigNotesFromStudy,
  getConfigPropertiesFromStudy,
  getSourceConfig
} from '../../../worksheets/study-utilities';
import {StudyStagingArea} from '../../study-staging-area/study-staging-area.service';
import {DisplayableError} from '../../../common/errors/errors';
import {GetSimVersion} from '../../../common/get-sim-version.service';
import {Router} from '@angular/router';
import {RouterDataCache} from '../../../common/router-data-cache';
import {SourceItem} from '../../configs/view-study-sources/source-item';

export const TELEMETRY_CONFIG_NOT_FOUND_ERROR: string = 'Telemetry config not found.';

@Component({
    selector: 'cs-edit-and-run-telemetry-button',
    templateUrl: './edit-and-run-telemetry-button.component.html',
    styleUrls: ['./edit-and-run-telemetry-button.component.scss'],
    standalone: false
})
export class EditAndRunTelemetryButtonComponent implements OnInit {
  @Input() public studyResult: GetStudyQueryResult;
  @Input() public jobResult: GetStudyJobQueryResult | undefined;

  public simVersion: string;
  public errorMessage: string;

  public telemetrySource: SourceItem;
  public isTelemetryJob: boolean;
  public isRedirecting: boolean;

  constructor(
    private readonly studyStagingArea: StudyStagingArea,
    private readonly getSimVersion: GetSimVersion,
    private readonly router: Router,
    private readonly routerDataCache: RouterDataCache,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public ngOnInit() {
    try{
      if(!this.studyResult) {
        return;
      }

      this.simVersion = this.jobResult ? this.jobResult.convertedSimVersion : this.studyResult.convertedSimVersion;

      this.isTelemetryJob = this.studyResult.study.data.studyType === StudyType.telemetry;

      let sources = this.studyResult.study.data.sources
        .filter(v => v.configType === DocumentSubType.telemetry)
        .filter(v => this.getSourceConfig(v.configType))
        .map(v => {
          let name = v.name;
          if(this.jobResult){
            let matchedChange = this.jobResult.studyJob.data.changes.find(change => change.path === v.configType);
            if(matchedChange){
              name = matchedChange.savedConfig.name;
            }
          }

          return new SourceItem(
            ConfigTypeLookup.get(v.configType).titleName,
            v.configType,
            v.userId,
            v.configId,
            name,
            v.isEdited);
        });

      if(!sources.length) {
        return;
      }

      this.telemetrySource = sources[0];
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async execute(){
    try {
      if(!this.telemetrySource){
        return;
      }

      let source = this.telemetrySource;
      let sourceConfig = this.getSourceConfig(source.typeKey);
      if(!sourceConfig){
        throw new DisplayableError(TELEMETRY_CONFIG_NOT_FOUND_ERROR);
      }

      this.isRedirecting = true;

      let properties = getConfigPropertiesFromStudy(source.typeKey, this.studyResult);

      // Take the entire notes content if a telemetry section isn't found, as telemetry usually only has one input anyway.
      let notes = getConfigNotesFromStudy(source.typeKey, this.studyResult) || this.studyResult.study.notes;

      let studyInput = await this.studyStagingArea.getAsIfStaged(
        source.typeKey,
        source.userId,
        source.configId,
        source.name,
        sourceConfig,
        properties,
        notes,
        this.simVersion,
        source.isEdited,
        this.getSimVersion.currentSimVersion);

      this.routerDataCache.push(studyInput);
      await this.router.navigate([
        '/configs',
        ConfigTypeLookup.get(source.typeKey).pluralKey,
        'create']);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    this.isRedirecting = false;
  }

  private getSourceConfig(key: string){
    return getSourceConfig(key, this.studyResult, this.jobResult);
  }
}
