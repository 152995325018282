import {Component, ElementRef, Input} from '@angular/core';
import {RowMetadataViewModel} from '../row-metadata-view-model';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {WorksheetItemComponentBase} from '../worksheet-item-component-base';
import {CellElementToViewModelLookup} from '../cell-element-to-view-model-lookup';
import {RowMetadataContextMenuBuilder} from '../worksheet-commands/row-metadata-context-menu-builder';
import { AuthenticationService } from '../../identity/state/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { RowItemUrlService } from '../worksheet-commands/row-item-url.service';

/**
 * Component for displaying the metadata of a worksheet row (the first column of the row).
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
    selector: '[cs-worksheet-row-metadata-item]',
    templateUrl: './worksheet-row-metadata-item.component.html',
    styleUrls: ['./worksheet-row-metadata-item.component.scss'],
    standalone: false
})
export class WorksheetRowMetadataItemComponent extends WorksheetItemComponentBase {

  /**
   * The view model for the row metadata.
   */
  @Input() public readonly viewModel: RowMetadataViewModel;

  /**
   * The index of the row in the worksheet.
   */
  @Input() public readonly rowIndex: number;

  /**
   * Creates an instance of WorksheetRowMetadataItemComponent.
   * @param authenticationService The authentication service.
   * @param cellElementToViewModelLookup The cell element to view model lookup.
   * @param elementRef The element reference.
   * @param contextMenuBuilder The context menu builder.
   * @param getFriendlyErrorAndLog The service for getting friendly error messages and logging the error.
   * @param rowItemUrl The row item URL service.
   * @param route The activated route.
   */
  constructor(
    authenticationService: AuthenticationService,
    cellElementToViewModelLookup: CellElementToViewModelLookup,
    elementRef: ElementRef,
    contextMenuBuilder: RowMetadataContextMenuBuilder,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    rowItemUrl: RowItemUrlService,
    route: ActivatedRoute) {
    super(authenticationService, cellElementToViewModelLookup, elementRef, contextMenuBuilder, getFriendlyErrorAndLog, rowItemUrl, route);
  }
}
