import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {
  ConditionSourceViewModel, FilterConditionViewModel, GroupViewModel,
  IFilterQueryBuilderManager
} from './filter-query-builder-types';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {getGroupOperatorValues, GetTenantUsersQueryResult, GroupOperator} from '../../../generated/api-stubs';

@Component({
    selector: 'cs-filter-query-builder-group',
    templateUrl: './filter-query-builder-group.component.html',
    styleUrls: ['./filter-query-builder-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FilterQueryBuilderGroupComponent implements OnInit{
  @Input() public manager: IFilterQueryBuilderManager;
  @Input() public group: GroupViewModel;
  @Input() public tenantUsers: GetTenantUsersQueryResult;

  public errorMessage: string;

  public readonly groupOperators: ReadonlyArray<GroupOperator> = getGroupOperatorValues();

  public conditionSources: ReadonlyArray<ConditionSourceViewModel> = [];

  constructor(
    private getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(): void {
    try {
      this.conditionSources = this.manager.getConditionSources() || [];
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public addCondition(){
    if(this.conditionSources.length){
      this.group.conditions.push(new FilterConditionViewModel(this.conditionSources[0]));
      this.manager.notifyChanged();
    }
  }

  public removeCondition(condition: FilterConditionViewModel){
    let index = this.group.conditions.indexOf(condition);
    if(index !== -1){
      this.group.conditions.splice(index, 1);
      this.manager.notifyChanged();
    }
  }

  public addGroup(){
    this.group.groups.push(new GroupViewModel(this.getOppositeGroupOperator()));
    this.manager.notifyChanged();
  }

  public wrapGroup(){
    let conditions = [...this.group.conditions];
    let groups = [...this.group.groups];
    this.group.conditions.length = 0;
    this.group.groups.length = 0;
    this.group.groups.push(new GroupViewModel(this.group.operator, conditions, groups));
    this.group.operator = this.getOppositeGroupOperator();
    this.manager.notifyChanged();
  }

  public removeGroup(group: GroupViewModel){
    let index = this.group.groups.indexOf(group);
    if(index !== -1){
      this.group.groups.splice(index, 1);
      this.manager.notifyChanged();
    }
  }

  public absorbGroup(group: GroupViewModel){
    let index = this.group.groups.indexOf(group);
    if(index !== -1){
      this.group.groups.splice(index, 1);
    }

    this.group.conditions.push(...group.conditions);
    this.group.groups.push(...group.groups);

    this.manager.notifyChanged();
  }

  public onGroupOperatorChanged(groupOperator: GroupOperator) {
    try{
      this.group.operator = groupOperator;
      this.manager.notifyChanged();
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private getOppositeGroupOperator(): GroupOperator{
    return this.group.operator === GroupOperator.and ? GroupOperator.or : GroupOperator.and;
  }
}
