import {Component, Input, OnInit} from '@angular/core';
import {Filter} from '../../../../visualizations/navigation-station/config-builders/car-config-builder';
import {JobViewModel} from '../job-view-model';
import {
  VerifyCarTabViewModel, NavigationStationRenderType, SimTypesTabViewModel, TabRenderType,
  TabViewModel, CarTabViewModel, TrackTabViewModel
} from '../tab-view-model';
import {
  SimType,
  StudyJobState, DocumentSubType
} from '../../../../../generated/api-stubs';
import {JobSource} from '../job-source';
import {JobViewModelSetFactory} from '../job-view-model-set';
import {UnitsManager} from '../../../../units/units-manager.service';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {GetVerifyCarVisualizationTabs} from '../get-verify-car-visualization-tabs.service';
import {VERIFY_CAR_STUDY_NAME} from '../../../configs/car-config-validation-session';
import {JobSelectorState} from '../job-selector-state';
import {cssSanitize} from '../../../../common/css-sanitize';
import {VisualizationCaches, VisualizationFactory} from '../../../visualizations/visualization-factory.service';
import {GetJobsSimTypeMetadataFactory} from '../get-jobs-sim-type-metadata-factory.service';
import {StudyJobSimTypeMetadata} from '../../../../visualizations/viewers/channel-data-loaders/get-jobs-sim-type-metadata';
import {isTimeOrDistanceSimTypeForJobs} from '../../../../visualizations/viewers/channel-data-loaders/is-time-or-distance-sim-type';
import {getJobIndexFromJobId} from '../../../../common/get-job-index-from-job-id';
import {JobLogFileType} from '../view-job-log-file/view-job-log-file.component';
import {simVersionToNumber} from '../../../../visualizations/sim-version-to-number';

export const RACING_LINE_TAB_ID = 'racingLine';

@Component({
    selector: 'cs-view-job-results',
    templateUrl: './view-job-results.component.html',
    styleUrls: ['./view-job-results.component.scss'],
    standalone: false
})
export class ViewJobResultsComponent implements OnInit {

  @Input() public jobSources: ReadonlyArray<JobSource>;
  @Input() public jobs: ReadonlyArray<JobViewModel>;
  @Input() public navigationStationPrefix: string;

  public readonly renderType = TabRenderType;
  public readonly jobLogFileType = JobLogFileType;
  public readonly scalarResultsTabId: string = 'scalarResults';
  public readonly racingLineTabId: string = RACING_LINE_TAB_ID;
  public readonly errorsTabId: string = 'errors';
  public readonly informationTabId: string = 'information';
  public readonly inputsTabId: string = 'inputs';

  public tabs: ReadonlyArray<TabViewModel>;
  public currentTab: TabViewModel;
  public jobSelectorState: JobSelectorState = new JobSelectorState();
  public visualizationCaches: VisualizationCaches;

  public errorMessage: string;

  public shouldShowWarningsInline: boolean = false;

  constructor(
    private readonly jobViewModelSetFactory: JobViewModelSetFactory,
    private readonly unitsManager: UnitsManager,
    private readonly getCarVisualizationTabs: GetVerifyCarVisualizationTabs,
    private readonly visualizationFactory: VisualizationFactory,
    private readonly getJobsSimTypeMetadataFactory: GetJobsSimTypeMetadataFactory,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  public ngOnInit(){
    this.load();
  }

  public async load(){
    try {
      // We're going to display a lot of units, so ensure they are loaded
      // in advance for efficiency.
      await this.unitsManager.ensureInitialized();

      if(this.jobSources && !this.jobs){
        let viewModelSet = this.jobViewModelSetFactory.create(this.jobSources);
        this.jobs = viewModelSet.jobs;
      }

      if(!this.jobs){
        this.jobs = [];
      }

      let simTypes = await this.getAllSimTypes();

      this.visualizationCaches = this.visualizationFactory.createCaches();
      await this.visualizationFactory.populateCachesForStudyJobs(this.jobs, this.visualizationCaches);
      const getJobsSimTypeMetadata = this.getJobsSimTypeMetadataFactory.create(this.visualizationCaches.fileLoader);
      let studyJobSimTypes: ReadonlyArray<StudyJobSimTypeMetadata> = await getJobsSimTypeMetadata.execute(
        this.jobs.map(v => ({ studyId: v.source.studyId, jobIndex: getJobIndexFromJobId(v.source.jobId) })),
        simTypes);

      let tabs: TabViewModel[] = [];

      //let studyTypes = await this.getAllStudyTypes();

      let hasCar = false;
      let hasTrack = false;
      for(let job of this.jobs){
        await job.studyMetadataResult.tryLoad();
        if(job.studyMetadataResult.value){
          hasCar = hasCar || job.studyMetadataResult.value.study.data.sources.some(v => v.configType === DocumentSubType.car);
          hasTrack = hasTrack || job.studyMetadataResult.value.study.data.sources.some(v => v.configType === DocumentSubType.track);
        }

        if(hasCar && hasTrack){
          break;
        }
      }

      let lapSimTypes: SimType[] = [];
      let otherSimTypes: SimType[] = [];
      for(let simType of simTypes){
        if(simType === SimType.GenerateRacingLine) {
          continue;
        }

        if(isTimeOrDistanceSimTypeForJobs(simType, studyJobSimTypes)){
          lapSimTypes.push(simType);
        } else {
          otherSimTypes.push(simType);
        }
      }

      if(lapSimTypes.length){
        tabs.push(new SimTypesTabViewModel('Main', lapSimTypes, NavigationStationRenderType.default));
      }

      let racingLineSimType = simTypes.find(v => v === SimType.GenerateRacingLine || v === SimType.TrackConverter);
      if(racingLineSimType){
        tabs.push(new SimTypesTabViewModel('Racing Line', [racingLineSimType], NavigationStationRenderType.racingLine));
      }

      if(otherSimTypes.length){
        const simTypesWithoutVectorResults = new Set<SimType>([
          SimType.ConstraintSatisfier,
          SimType.TrackConverter,
        ]);
        tabs.push(...otherSimTypes
          .filter(v => !simTypesWithoutVectorResults.has(v))
          .map(v => new SimTypesTabViewModel(v, [v], NavigationStationRenderType.default)));
      }

      if(simTypes.indexOf(SimType.StraightSim) !== -1){
        let carTabs = this.getCarVisualizationTabs.execute();
        for(let tab of carTabs){
          tabs.push(new VerifyCarTabViewModel(tab.name, tab.filter, tab.perJob));
        }
      } else if(this.jobs.some(v => v.studyName.isLoaded && v.studyName.value === VERIFY_CAR_STUDY_NAME)){
        let carTabs = this.getCarVisualizationTabs.execute();
        let tab = carTabs.find(v => v.filter === Filter.suspension);
        if (tab) {
          tabs.push(new VerifyCarTabViewModel(tab.name, tab.filter, tab.perJob));
        }
      }

      if(hasCar){
        tabs.push(new CarTabViewModel());
      }
      if(hasTrack){
        tabs.push(new TrackTabViewModel());
      }

      tabs.push(...[
        new TabViewModel('Scalar Results', this.scalarResultsTabId, TabRenderType.scalarResults),
        new TabViewModel('Inputs', this.inputsTabId, TabRenderType.inputs),
        new TabViewModel('Info', this.informationTabId, TabRenderType.info),
        new TabViewModel('Warnings', this.informationTabId, TabRenderType.warnings),
        new TabViewModel('Errors', this.errorsTabId, TabRenderType.errors),
      ]);

      this.tabs = tabs;

      if(this.jobs.every(v =>
          (v.jobMetadataResult.value && v.jobMetadataResult.value.studyJob.data.state === StudyJobState.failed)
        || (v.studyMetadataResult.value && v.studyMetadataResult.value.study.data.succeededJobCount === 0))) {
        this.setTab(this.tabs[this.tabs.length - 1]);
      } else{
        this.setTab(this.tabs[0]);
      }

      // Sim version 1.2299 is the first sim version where the warning log was split into
      // errors and warnings, allowing us to display the more useful warning log inline.
      this.shouldShowWarningsInline
        = this.jobs.length === 1
        && this.jobs[0].studyMetadataResult.value
        && simVersionToNumber(this.jobs[0].studyMetadataResult.value.study.simVersion) >= 2299;
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  private async getAllSimTypes() {
    let simTypes: SimType[] = [];
    for (let job of this.jobs) {
      await job.simTypes.tryLoad();
      if(job.simTypes.value){
        for (let simType of job.simTypes.value) {
          if (simTypes.indexOf(simType) === -1) {
            simTypes.push(simType);
          }
        }
      }
    }

    return simTypes;
  }

  /*
  private async getAllStudyTypes() {
    let studyTypes: StudyType[] = [];
    for (let job of this.jobs) {
      await job.studyMetadataResult.tryLoad();
      if (job.studyMetadataResult.value) {
        let studyType = job.studyMetadataResult.value.study.data.studyType;
        if (studyTypes.indexOf(studyType) === -1) {
          studyTypes.push(studyType);
        }
      }
    }

    return studyTypes;
  }
  */

  public setTab(tab: TabViewModel){
    this.currentTab = tab;

    for(let activeTab of this.tabs.filter(v => v.isActive)){
      activeTab.deactivate();
    }

    tab.activate();
  }

  public cssSanitize(input: string): string {
    return cssSanitize(input);
  }
}
