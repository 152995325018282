import { Component } from '@angular/core';
import {GetTenantSimVersion} from '../../common/get-tenant-sim-version.service';
import {GetUserSimVersion} from '../../common/get-user-sim-version.service';
import {SelectSimVersionDialog} from './select-sim-version-dialog.service';
import {TenantSettingsStub} from '../../../generated/api-stubs';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {LocalUserSettingsManager} from '../../common/local-user-settings-manager';
import {ConfirmationDialog} from '../../common/dialogs/confirmation-dialog.service';
import { AuthenticationService } from '../../identity/state/authentication.service';

export const USE_TENANT_SIM_VERSION_TEXT: string = 'Use Tenant Sim Version';
export const CONFIRM_SET_TENANT_SIM_VERSION_TEXT: string = 'Are you sure you want to set the sim version for all users?';
export const CONFIRM_SET_TENANT_SIM_VERSION_TITLE_TEXT: string = 'Set Tenant Sim Version?';
export const CONFIRM_SET_TENANT_SIM_VERSION_BUTTON_TEXT: string = 'Set For All Users';

@Component({
    selector: 'cs-sim-version-status',
    templateUrl: './sim-version-status.component.html',
    styleUrls: ['./sim-version-status.component.scss'],
    standalone: false
})
export class SimVersionStatusComponent {

  public errorMessage: string;

  private _isUpdatingTenantSimVersion: boolean = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly getUserSimVersion: GetUserSimVersion,
    private readonly getTenantSimVersion: GetTenantSimVersion,
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly selectSimVersionDialog: SelectSimVersionDialog,
    private readonly localUserSettingsManager: LocalUserSettingsManager,
    private readonly confirmationDialog: ConfirmationDialog,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  public get userSimVersion(): string {
    return this.getUserSimVersion.currentSimVersion;
  }

  public get tenantSimVersion(): string {
    return this.getTenantSimVersion.currentSimVersion;
  }

  public get isUpdatingTenantSimVersion(): boolean {
    return this._isUpdatingTenantSimVersion || this.getTenantSimVersion.isUpdating;
  }

  public async selectUserSimVersion() {
    try{
      const userData = this.authenticationService.userDataSnapshot;
      const simVersionResult = await this.selectSimVersionDialog.show(
        userData.tenant,
        false,
        this.userSimVersion,
        USE_TENANT_SIM_VERSION_TEXT);

      if(!simVersionResult) {
        return;
      }

      this.localUserSettingsManager.state.userSimVersion = simVersionResult.simVersion;
      this.localUserSettingsManager.save();

      await this.getUserSimVersion.execute();
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async selectTenantSimVersion() {
    try{
      const userData = this.authenticationService.userDataSnapshot;

      const simVersionResult = await this.selectSimVersionDialog.show(
        userData.tenant,
        true);

      if(!simVersionResult) {
        await this.getTenantSimVersion.execute();
        return;
      }

      const confirmationResult = await this.confirmationDialog.show(
        CONFIRM_SET_TENANT_SIM_VERSION_TEXT,
        CONFIRM_SET_TENANT_SIM_VERSION_TITLE_TEXT,
        CONFIRM_SET_TENANT_SIM_VERSION_BUTTON_TEXT);
      if(!confirmationResult){
        return;
      }

      this._isUpdatingTenantSimVersion = true;
      await this.setTenantSimVersion(userData.tenant, simVersionResult.simVersion);
    } catch (error) {
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
    this._isUpdatingTenantSimVersion = false;
  }


  public async setTenantSimVersion(tenantId: string, simVersion: string) {
    await this.tenantSettingsStub.putTenantSettingsSimVersion(tenantId, {
      simVersion
    });

    // Update cached version if current tenant.
    await this.getTenantSimVersion.execute();
  }
}
