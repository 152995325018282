import { ErrorHandler, NgModule, Provider, inject, provideAppInitializer } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { BrowserModule  } from '@angular/platform-browser';
import { createErrorHandler } from '@sentry/angular';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { AppComponent } from './app.component';
import { PersistentComponentsComponent } from './persistent-components/persistent-components';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import {LocalStorage} from './common/local-storage.service';
import {SessionStorage} from './common/session-storage.service';
import {ExtractAuthenticationDataFromResponse} from './identity/authentication/extract-authentication-data-from-response.service';
import {GetHttpError} from './common/errors/services/get-http-error/get-http-error.service';
import {GetFriendlyErrorAndLog} from './common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {SignOut} from './identity/authentication/sign-out.service';
import {CanopyHttp} from './common/canopy-http.service';
import {Interceptors} from './common/http-interceptors/interceptors.service';
import {AuthenticationInterceptor} from './common/http-interceptors/authentication-interceptor.service';
import {DeveloperNameInterceptor} from './common/http-interceptors/developer-name-interceptor.service';
import {CanopyValidators} from './common/forms/canopy-validators.service';
import {FormSubmissionHandler} from './common/forms/form-submission-handler.service';
import {Authorize} from './identity/authorization/authorize.service';
import {AuthorizePage} from './identity/authorization/authorize-page.service';
import {FetchUserState} from './user-state/fetch-user-state.service';
import {FixQueryStringUrl} from './stubs/fix-query-string-url.service';
import {LogError} from './common/log-error.service';
import {DeveloperNameReader} from './common/http-interceptors/developer-name-reader.service';
import {InferableRouteParams} from './common/inferable-route-params.service';
import {TextFileReader} from './simulations/text-file-reader.service';
import {Dayjs} from './common/dayjs.service';
import {CanopyJson} from './common/canopy-json.service';
import {FormCancellationHandler} from './common/forms/form-cancellation-handler.service';
import {StudyStagingArea} from './simulations/study-staging-area/study-staging-area.service';
import {DialogManager} from './common/dialogs/dialog-manager.service';
import {ConfirmationDialog} from './common/dialogs/confirmation-dialog.service';
import {ApplicationInsights} from './common/application-insights.service';
import {ApplicationInsightsRouteChangedHandler} from './common/application-insights-route-changed-handler.service';
import {GetUsernameMap} from './simulations/get-username-map.service';
import {CanopyPusher} from './common/canopy-pusher.service';
import {VisualizationFactory} from './simulations/visualizations/visualization-factory.service';
import {SimVersionDocumentCache} from './simulations/sim-version-document-cache.service';
import {ResultsStagingArea} from './simulations/results-staging-area/results-staging-area.service';
import {Rison} from './common/canopy-rison';
import {StudyUtilities} from './simulations/studies/study-utilities.service';
import {Timer} from './common/timer.service';
import {VisualizationUtilities} from './simulations/visualizations/visualization-utilities.service';
import {BlobLoader} from './common/blob-loader.service';
import {FilterUtilities} from './simulations/list-filtering/filter-utilities';
import {CanopySiteHooksFactory} from './simulations/visualizations/canopy-site-hooks.service';
import {ChartRepositoryFactory} from './simulations/visualizations/chart-repository.service';
import {ConfigLoaderUtilities} from './simulations/configs/config-loader-utilities.service';
import {PromptDialog} from './common/dialogs/prompt-dialog.service';
import {ConfigLoaderDialog} from './simulations/configs/config-loader-dialog/config-loader-dialog.service';
import {ConfigLoaderDialogSessionFactory} from './simulations/configs/config-loader-dialog/config-loader-dialog-session';
import {CanopyFileLoaderFactory} from './simulations/visualizations/canopy-file-loader.service';
import {StudyTypeLookup, StudyTypeLookupFactory} from './simulations/studies/study-type-lookup.service';
import {BasicFileLoaderFactory} from './common/basic-file-loader.service';
import {GetTenantNameMap} from './simulations/get-tenant-name-map.service';
import {TenantStudyStatisticsUtilities} from './tenancy/tenant-study-statistics-utilities.service';
import {
  ConfigSubTreeRepositoryFactory
} from './simulations/configs/config-sub-tree-repository.service';
import {STUB_PROVIDERS} from '../generated/api-stubs';
import {AcceptHeaderInterceptor} from './common/http-interceptors/accept-header-interceptor.service';
import {ToFixedPipe} from './common/to-fixed.pipe';
import {ToPrecisionPipe} from './common/to-precision.pipe';
import {UnitsPipe} from './common/units.pipe';
import {UpdateCheckerComponent} from './common/update-checker.component';
import {ResultsStagingAreaComponent} from './simulations/results-staging-area/results-staging-area.component';
import {StudyStagingAreaComponent} from './simulations/study-staging-area/study-staging-area.component';
import {Footer} from './footer/footer.component';
import {NavBarComponent} from './navigation/nav-bar.component';
import {AllTenantsStudyStatisticsComponent} from './admin/all-tenants-study-statistics.component';
import {PoolsComponent} from './pool/pools.component';
import {SignOutEverywhereComponent} from './identity/sign-out-everywhere.component';
import {PoolStatusComponent} from './pool/pool-status.component';
import {EditCustomPropertiesComponent} from './simulations/custom-properties/edit-custom-properties.component';
import {ViewCustomPropertiesComponent} from './simulations/custom-properties/view-custom-properties.component';
import {ListFilterComponent, DynamicDisableDirective} from './simulations/list-filtering/list-filter.component';
import {ViewDefaultConfigsComponent} from './simulations/configs/view-default-configs/view-default-configs.component';
import {ViewConfigsComponent} from './simulations/configs/view-configs/view-configs.component';
import {SaveRacingLineButtonsComponent} from './simulations/configs/output-configs/save-racing-line-buttons/save-racing-line-buttons.component';
import {ViewJobComponent} from './simulations/jobs/view-job/view-job.component';
import {TenantStudyStatisticsComponent} from './tenancy/tenant-study-statistics.component';
import {TenantSettingsDefaultCustomPropertyNamesComponent} from './tenancy/tenant-settings-default-custom-property-names.component';
import {AdminTenantSettingsComponent} from './tenancy/admin-tenant-settings.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_BASE_HREF} from '@angular/common';
import {appRoutingProviders, routing} from './routes';
import {HomePage} from './home/home.page';
import {NotAuthorizedPage} from './not-authorized.page';
import {SignInRedirectPage} from './sign-in-redirect.page';
import {NotFoundPage} from './not-found.page';
import {AdminPage} from './admin/admin.page';
import {ViewSimVersionDocumentsPage} from './admin/view-sim-version-documents.page';
import {AccountSettingsPage} from './identity/account-settings.page';
import {RegisterPage} from './identity/register.page';
import {ResetPasswordPage} from './identity/reset-password.page';
import {PoolStatusPage} from './pool/pool-status.page';
import {CreateConfigPage} from './simulations/configs/create-config-page/create-config.page';
import {EditConfigPage} from './simulations/configs/edit-config-page/edit-config.page';
import {EditStudyPage} from './simulations/studies/edit-study-page/edit-study.page';
import {ViewConfigPage} from './simulations/configs/view-config-page/view-config.page';
import {ViewConfigsPage} from './simulations/configs/view-configs-page/view-configs.page';
import {ViewJobPage} from './simulations/jobs/view-job-page/view-job.page';
import {ViewJobsPage} from './simulations/jobs/view-jobs-page/view-jobs.page';
import {ViewStudiesPage} from './simulations/studies/view-studies-page/view-studies.page';
import { ManageConfigComponentsPage } from './simulations/configs/manage-config-components-page/manage-config-components.page';
import {ViewStudyPage} from './simulations/studies/view-study-page/view-study.page';
import {CreateTenantPage} from './tenancy/create-tenant.page';
import {TenantAccountSettingsPage} from './tenancy/tenant-account-settings.page';
import {TenantInformationPage} from './tenancy/tenant-information.page';
import {ForgotPasswordPage} from './identity/forgot-password.page';
import {LogoComponent} from './common/logo.component';
import {LoaderComponent} from './common/loader.component';
import {ChartHelpComponent} from './simulations/visualizations/chart-help.component';
import {
  CarConfigValidatorComponent,
} from './simulations/configs/car-config-validator/car-config-validator.component';
import {CarConfigValidationSessionFactory} from './simulations/configs/car-config-validation-session-factory';
import {CarConfigValidationResultsComponent} from './simulations/configs/car-config-validation-results/car-config-validation-results.component';
import {CarConfigValidationStagingArea} from './simulations/configs/car-config-validation-staging-area.service';
import {CanopyJsonDiffPatch} from './common/canopy-json-diff-patch.service';
import {CompareConfigDialogComponent} from './simulations/configs/comparing/compare-config-dialog/compare-config-dialog.component';
import {CompareConfigDialog} from './simulations/configs/comparing/compare-config-dialog/compare-config-dialog.service';
import {CanopyAutocomplete} from './common/canopy-autocomplete.service';
import {EditConfigStickyComponent} from './simulations/configs/edit-config-sticky/edit-config-sticky.component';
import {SetChannelUnitsDialogComponent} from './units/set-channel-units-dialog.component';
import {SetChannelUnitsDialog} from './units/set-channel-units-dialog.service';
import {UserSettings} from './user-state/user-settings.service';
import {UnitsManager} from './units/units-manager.service';
import {UserSettingsCache} from './user-state/user-settings-cache.service';
import {UnitComponent} from './units/unit.component';
import {SetChannelUnitsDialogSessionFactory} from './units/set-channel-units-dialog-session';
import {SupportSessionLauncherComponent} from './simulations/support-sessions/support-session-launcher.component';
import {SupportSessionDialogComponent} from './simulations/support-sessions/support-session-dialog.component';
import {SupportSessionDialog} from './simulations/support-sessions/support-session-dialog.service';
import {SupportSessionDialogSessionFactory} from './simulations/support-sessions/support-session-dialog-session';
import {FromNowPipe} from './common/from-now.pipe';
import {SupportSessionHoverLauncherComponent} from './simulations/support-sessions/support-session-hover-launcher.component';
import {CreateUserInformationMaps} from './simulations/create-user-information-maps.service';
import {ViewSupportSessionsPage} from './admin/view-support-sessions.page';
import {GetPreferredChart} from './simulations/visualizations/get-preferred-chart.service';
import {SetPreferredChart} from './simulations/visualizations/set-preferred-chart.service';
import {MarkdownComponent} from './wiki/markdown.component';
import {CustomReuseStrategy} from './custom-reuse-strategy';
import {RouteReuseStrategy, UrlSerializer} from '@angular/router';
import {ViewStudySourcesComponent} from './simulations/configs/view-study-sources/view-study-sources.component';
import {ViewJobDialogComponent} from './simulations/jobs/view-job-dialog/view-job-dialog.component';
import {ViewJobDialog} from './simulations/jobs/view-job-dialog/view-job-dialog.service';
import {ViewJobDialogSessionFactory} from './simulations/jobs/view-job-dialog/view-job-dialog-session';
import {LoadingDialogComponent} from './common/dialogs/loading-dialog.component';
import {LoadingDialog} from './common/dialogs/loading-dialog.service';
import {LoadingDialogSessionFactory} from './common/dialogs/loading-dialog-session';
import {SaveAsDialog} from './common/dialogs/save-as-dialog.service';
import {CompareStagedInputToSource} from './simulations/compare-staged-input-to-source.service';
import {TagInputModule} from 'ngx-chips';
import {CustomViewFilterComponent} from './simulations/configs/custom-view-filter/custom-view-filter.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {QuickConfigManagerComponent} from './simulations/configs/quick-config-manager/quick-config-manager.component';
import {ChannelEditorDialogSessionFactory} from './simulations/visualizations/channel-editor-dialog-session';
import {ChannelEditorDialog} from './simulations/visualizations/channel-editor-dialog.service';
import {ChannelEditorDialogComponent} from './simulations/visualizations/channel-editor-dialog.component';
import {SetBodyDialogStyleDirective} from './common/dialogs/set-body-dialog-style.directive';
import {ConfirmationDialogComponent} from './common/dialogs/confirmation-dialog.component';
import {ConfigLoaderDialogComponent} from './simulations/configs/config-loader-dialog/config-loader-dialog.component';
import {PromptDialogComponent} from './common/dialogs/prompt-dialog.component';
import {TenantSimulationQuotaViewComponent} from './tenancy/tenant-simulation-quota-view.component';
import {TenantSettingsSimVersionComponent} from './tenancy/sim-version/tenant-settings-sim-version.component';
import {StageStudy} from './simulations/studies/stage-study.service';
import {DownloadStudy} from './simulations/studies/download-study.service';
import {GoToJob} from './simulations/jobs/go-to-job.service';
import {TenantBillableSimulationCountComponent} from './tenancy/tenant-billable-simulation-count.component';
import {NumberWithCommasPipe} from './common/number-with-commas';
import {ConvertConfigToCsv} from './simulations/configs/convert-config-to-csv.service';
import {ExportConfigService} from './simulations/configs/export-config.service';
import {GetJobsFromResultSources} from './simulations/jobs/job-results/get-job-view-models-from-result-sources';
import {JobViewModelFactory} from './simulations/jobs/job-results/job-view-model';
import {JobViewModelSetFactory} from './simulations/jobs/job-results/job-view-model-set';
import {LazyLoadedStudyJobFactory} from './simulations/jobs/job-results/lazy-loaded-study-job-factory';
import {LazyLoadedStudyJobMetadataFactory} from './simulations/jobs/job-results/lazy-loaded-study-job-metadata-factory';
import {LazyLoadedJobNameFactory} from './simulations/jobs/job-results/lazy-loaded-study-job-name-factory';
import {LazyLoadedStudyJobScalarResultSetsFactory} from './simulations/jobs/job-results/lazy-loaded-study-job-scalar-result-sets-factory';
import {LazyLoadedStudyJobWarningsFactory} from './simulations/jobs/job-results/lazy-loaded-study-job-warnings-factory';
import {LazyLoadedStudyMetadataFactory} from './simulations/jobs/job-results/lazy-loaded-study-metadata-factory';
import {LazyLoadedStudyNameFactory} from './simulations/jobs/job-results/lazy-loaded-study-name-factory';
import {ViewJobInputsComponent} from './simulations/jobs/job-results/view-job-inputs/view-job-inputs.component';
import {ViewJobNavigationStationComponent} from './simulations/jobs/job-results/view-job-navigation-station/view-job-navigation-station.component';
import {ViewJobResultsComponent} from './simulations/jobs/job-results/view-job-results/view-job-results.component';
import {ViewJobResultsPage} from './simulations/jobs/job-results/view-job-results-page/view-job-results.page';
import {ViewJobScalarResultsComponent} from './simulations/jobs/job-results/view-job-scalar-results/view-job-scalar-results.component';
import {ViewJobLogFileComponent} from './simulations/jobs/job-results/view-job-log-file/view-job-log-file.component';
import {JobSelectorComponent} from './simulations/jobs/job-results/job-selector/job-selector.component';
import {LazyLoadedSimTypesFactory} from './simulations/jobs/job-results/lazy-loaded-sim-types.factory';
import {GetVerifyCarVisualizationTabs} from './simulations/jobs/job-results/get-verify-car-visualization-tabs.service';
import {ViewJobErrorsComponent} from './simulations/jobs/job-results/view-job-errors/view-job-errors.component';
import {ViewCreditsComponent} from './simulations/view-credits/view-credits.component';
import {FilterQueryBuilderComponent} from './simulations/list-filtering/filter-query-builder.component';
import {FilterQueryBuilderGroupComponent} from './simulations/list-filtering/filter-query-builder-group.component';
import {FilterQueryBuilderConditionComponent} from './simulations/list-filtering/filter-query-builder-condition.component';
import {FilterQueryRendererComponent} from './simulations/list-filtering/filter-query-renderer.component';
import {FilterQueryRendererGroupComponent} from './simulations/list-filtering/filter-query-renderer-group.component';
import {FilterQueryRendererConditionComponent} from './simulations/list-filtering/filter-query-renderer-condition.component';
import {ListFilterQueryBuilderDataSourceFactory} from './simulations/list-filtering/list-filter-query-builder-data-source.service';
import {UpgradeListFilterForComplexQueries} from './simulations/list-filtering/upgrade-list-filter-for-complex-queries.service';
import {CustomUrlSerializer} from './custom-url-serializer';
import {LocalUserSettingsManager} from './common/local-user-settings-manager';
import {UserRolesComponent} from './identity/user-roles.component';
import {GetSimVersion} from './common/get-sim-version.service';
import {ViewEditCustomPropertiesComponent} from './simulations/custom-properties/view-edit-custom-properties.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ViewTelemetryConfigsComponent} from './simulations/configs/view-telemetry-configs/view-telemetry-configs.component';
import {ViewStudiesComponent} from './simulations/studies/view-studies/view-studies.component';
import {ConvertTelemetryConfigToStudy} from './simulations/configs/convert-telemetry-config-to-study.service';
import {TelemetryHelpComponent} from './simulations/configs/telemetry-help/telemetry-help.component';
import {SignificantSimVersions} from './common/significant-sim-versions.service';
import {SelectSimVersionComponent} from './tenancy/sim-version/select-sim-version.component';
import { SelectSimVersionDialogComponent } from './tenancy/sim-version/select-sim-version-dialog.component';
import {SelectSimVersionDialogSessionFactory} from './tenancy/sim-version/select-sim-version-dialog-session';
import {SelectSimVersionDialog} from './tenancy/sim-version/select-sim-version-dialog.service';
import {GetUserSimVersion} from './common/get-user-sim-version.service';
import {GetTenantSimVersion} from './common/get-tenant-sim-version.service';
import { SimVersionStatusComponent } from './tenancy/sim-version/sim-version-status.component';
import {LazyLoadedStudyFactory} from './simulations/jobs/job-results/lazy-loaded-study-factory';
import { LintingEngineRunnerComponent } from './simulations/linting/linting-engine-runner/linting-engine-runner.component';
import { LintingEngineRunnerDialogComponent } from './simulations/linting/linting-engine-runner-dialog/linting-engine-runner-dialog.component';
import {LintingEngineRunnerDialogSessionFactory} from './simulations/linting/linting-engine-runner-dialog/linting-engine-runner-dialog-session';
import {LintingEngineRunnerDialog} from './simulations/linting/linting-engine-runner-dialog/linting-engine-runner-dialog.service';
import {LintingEngineDialogLauncherComponent} from './simulations/linting/linting-engine-dialog-launcher/linting-engine-dialog-launcher.component';
import {LintingEngineFactory} from './simulations/linting/linting-engine-factory';
import {GetJobsSimTypeMetadataFactory} from './simulations/jobs/job-results/get-jobs-sim-type-metadata-factory.service';
import { ListVersionsDialogLauncherComponent } from './simulations/versioning/list-versions-dialog-launcher/list-versions-dialog-launcher.component';
import { ListVersionsDialogComponent } from './simulations/versioning/list-versions-dialog/list-versions-dialog.component';
import {ListVersionsDialog} from './simulations/versioning/list-versions-dialog/list-versions-dialog.service';
import {ListVersionsDialogSessionFactory} from './simulations/versioning/list-versions-dialog/list-versions-dialog-session';
import {ToDateTimePipe} from './common/to-date-time.pipe';
import {StripNonVersionedDataFromDocument} from './simulations/versioning/strip-non-versioned-data-from-document.service';
import { ImportTelemetryDialogComponent } from './simulations/telemetry/import-telemetry-dialog/import-telemetry-dialog.component';
import { ImportTelemetryDialogLauncherComponent } from './simulations/telemetry/import-telemetry-dialog-launcher/import-telemetry-dialog-launcher.component';
import {ImportTelemetryDialogSessionFactory} from './simulations/telemetry/import-telemetry-dialog/import-telemetry-dialog-session';
import {ImportTelemetryDialog} from './simulations/telemetry/import-telemetry-dialog/import-telemetry-dialog.service';
import {StudySubmittedEvents} from './simulations/studies/study-submitted-events.service';
import { ConvertTelemetryCsvToJsonStageComponent } from './simulations/telemetry/convert-telemetry-csv-to-json-stage/convert-telemetry-csv-to-json-stage.component';
import {RefineTelemetryJsonStageComponent} from './simulations/telemetry/refine-telemetry-json-stage/refine-telemetry-json-stage.component';
import {ValidateTelemetryJsonStageComponent} from './simulations/telemetry/validate-telemetry-json-stage/validate-telemetry-json-stage.component';
import { RemapTelemetryJsonStageComponent } from './simulations/telemetry/remap-telemetry-json-stage/remap-telemetry-json-stage.component';
import { StageAsTelemetryTextComponent } from './simulations/telemetry/stage-as-telemetry-text/stage-as-telemetry-text.component';
import { EditAndRunTelemetryButtonComponent } from './simulations/telemetry/edit-and-run-telemetry-button/edit-and-run-telemetry-button.component';
import {RouterDataCache} from './common/router-data-cache';
import {AutosizeModule} from 'ngx-autosize';
import { NotificationEnablerComponent } from './notifications/notification-enabler/notification-enabler.component';
import { ViewWorksheetsPage } from './worksheets/view-worksheets/view-worksheets.page';
import { EditWorksheetPage } from './worksheets/edit-worksheet/edit-worksheet.page';
import { WorksheetTableComponent } from './worksheets/worksheet-table/worksheet-table.component';
import { WorksheetRowComponent } from './worksheets/worksheet-row/worksheet-row.component';
import { WorksheetConfigItemComponent } from './worksheets/worksheet-config-item/worksheet-config-item.component';
import { WorksheetStudyItemComponent } from './worksheets/worksheet-study-item/worksheet-study-item.component';
import { WorksheetSimulationItemComponent } from './worksheets/worksheet-simulation-item/worksheet-simulation-item.component';
import { WorksheetItemErrorMessageComponent } from './worksheets/worksheet-item-error-message/worksheet-item-error-message.component';
import { WorksheetRowMetadataItemComponent } from './worksheets/worksheet-row-metadata-item/worksheet-row-metadata-item.component';
import {WorksheetViewModelFactory} from './worksheets/worksheet-view-model';
import {SubmitStudy} from './worksheets/submit-study.service';
import {WorksheetClipboard} from './worksheets/worksheet-clipboard.service';
import { EditWorksheetComponent } from './worksheets/edit-worksheet/edit-worksheet.component';
import { WorksheetDockComponent } from './worksheets/worksheet-dock/worksheet-dock.component';
import {WorksheetDock} from './worksheets/worksheet-dock/worksheet-dock.service';
import {ResizableModule} from 'angular-resizable-element';
import { PreventParentScrollDirective } from './common/prevent-parent-scroll.directive';
import { RequestUndockWorksheetPage } from './worksheets/request-undock-worksheet/request-undock-worksheet.page';
import { ElementHeightCacherDirective } from './common/element-height-cacher.directive';
import {ElementHeightCache} from './common/element-height-cache.service';
import { StudyLoaderDialogComponent } from './worksheets/study-loader-dialog/study-loader-dialog.component';
import {StudyLoaderDialogSessionFactory} from './worksheets/study-loader-dialog/study-loader-dialog-session';
import {StudyLoaderDialog} from './worksheets/study-loader-dialog/study-loader-dialog.service';
import {DocumentUpdatedEventService} from './worksheets/document-updated-event.service';
import {DuplicateClipboardContentIfRequired} from './worksheets/duplicate-clipboard-content-if-required';
import {CreateWorksheetRowFromStudy} from './worksheets/create-worksheet-row-from-study';
import {CreateWorksheetFromStudy} from './worksheets/create-worksheet-from-study';
import {WorksheetUnderlyingDataFactory} from './worksheets/worksheet-underlying-data';
import {GetWorksheetForSupportSession} from './worksheets/get-worksheet-for-support-session';
import {CreateWorksheetFromStudyIfRequired} from './worksheets/create-worksheet-from-study-if-required';
import {CompareConfigToStudyInput} from './worksheets/compare-config-to-study-input';
import {LoadConfigFromReference} from './worksheets/load-config-from-reference';
import {LoadConfigFromStudyInput} from './worksheets/load-config-from-study-input';
import {RecreateTelemetryStudyFromInputData} from './worksheets/recreate-telemetry-study-from-input-data';
import {LoadStudy} from './worksheets/load-study';
import { WorksheetLabelsEditorDialogComponent } from './worksheets/worksheet-labels-editor-dialog/worksheet-labels-editor-dialog.component';
import { WorksheetLabelsEditorComponent } from './worksheets/worksheet-labels-editor/worksheet-labels-editor.component';
import {WorksheetLabelsEditorDialogSessionFactory} from './worksheets/worksheet-labels-editor-dialog/worksheet-labels-editor-session';
import {WorksheetLabelsEditorDialog} from './worksheets/worksheet-labels-editor-dialog/worksheet-labels-editor-dialog.service';
import { WorksheetLabelsComponent } from './worksheets/worksheet-labels/worksheet-labels.component';
import {ToShortPathPipe} from './common/to-short-path.pipe';
import {CustomLineBreaksPipe} from './common/custom-line-breaks.pipe';
import {CompareConfigToStaged} from './worksheets/compare-config-to-staged';
import {StageConfigReference} from './worksheets/stage-config-reference';
import {ActiveWorksheets} from './worksheets/active-worksheets.service';
import {LazyLoadedStudyJobInfoFactory} from './simulations/jobs/job-results/lazy-loaded-study-job-info-factory';
import {LazyLoadedStudyJobDiagnosisFactory} from './simulations/jobs/job-results/lazy-loaded-study-job-diagnosis-factory';
import { SetOwnershipDialogLauncherComponent } from './ownership/set-ownership-dialog-launcher/set-ownership-dialog-launcher.component';
import {PromptAndSetOwnership} from './ownership/prompt-and-set-ownership';
import {CloneWorksheet} from './worksheets/clone-worksheet.service';
import {CloneConfigLauncherComponent} from './simulations/configs/clone-config-launcher/clone-config-launcher.component';
import {CellElementToViewModelLookup} from './worksheets/cell-element-to-view-model-lookup';
import { ContextMenuRendererComponent } from './context-menu/context-menu-renderer/context-menu-renderer.component';
import {ContextMenu} from './context-menu/context-menu.service';
import {ContextMenuComponent} from './context-menu/context-menu/context-menu.component';
import { ClickOutsideDirective } from './common/click-outside.directive';
import { FocusOnShowDirective } from './common/focus-on-show.directive';
import {ALL_WORKSHEET_COMMAND_FACTORIES} from './worksheets/worksheet-commands/all-worksheet-commands';
import {ConfigContextMenuBuilder} from './worksheets/worksheet-commands/config-context-menu-builder';
import {SetConfigOrAllReferences} from './worksheets/worksheet-commands/set-config-or-all-references.service';
import {ConfirmReplaceAllReferences} from './worksheets/worksheet-commands/confirm-replace-all-references.service';
import {PasteContent} from './worksheets/worksheet-commands/paste-content.service';
import {StudyContextMenuBuilder} from './worksheets/worksheet-commands/study-context-menu-builder';
import {RowMetadataContextMenuBuilder} from './worksheets/worksheet-commands/row-metadata-context-menu-builder';
import {SimulationContextMenuBuilder} from './worksheets/worksheet-commands/simulation-context-menu-builder';
import {AddStudyToWorksheetAndExtractInputs} from './worksheets/add-study-to-worksheet-and-extract-inputs.service';
import {DeduplicateWorksheetRow} from './worksheets/deduplicate-worksheet-row.service';
import {SetStandardConfig} from './worksheets/worksheet-commands/set-standard-config.service';
import {SetTelemetryConfig} from './worksheets/worksheet-commands/set-telemetry-config.service';
import {LoadContextConfig} from './worksheets/worksheet-commands/load-context-config.service';
import {RetryOnNetworkErrorInterceptor} from './common/http-interceptors/retry-on-network-error-interceptor';
import { WorksheetContextMenuButtonComponent } from './worksheets/worksheet-context-menu-button/worksheet-context-menu-button.component';
import {CompareSelectedConfigs} from './worksheets/compare-selected-configs';
import { SaveConfigsWithConstraintResultsButtonsComponent } from './simulations/configs/output-configs/save-configs-with-constraint-results-buttons/save-configs-with-constraint-results-buttons.component';
import { EditDocumentMetadataComponent } from './simulations/edit-document-metadata/edit-document-metadata.component';
import { EditDocumentMetadataDialogComponent } from './simulations/edit-document-metadata-dialog/edit-document-metadata-dialog.component';
import {EditDocumentMetadataDialog} from './simulations/edit-document-metadata-dialog/edit-document-metadata-dialog.service';
import {EditDocumentMetadataDialogSessionFactory} from './simulations/edit-document-metadata-dialog/edit-document-metadata-session';
import {JsonEditor} from './simulations/configs/json-config-editor/json-editor.service';
import {GetStudyAutocompleteOptions} from './simulations/configs/json-config-editor/get-study-autocomplete-options.service';
import {GetChannelNameAutocompleteOptions} from './simulations/configs/json-config-editor/get-channel-name-autocomplete-options.service';
import {GetAutocompleteMaps} from './simulations/configs/json-config-editor/get-autocomplete-maps.service';
import {SavedConfigNamesCacheFactory} from './simulations/configs/json-config-editor/saved-config-names-cache';
import {CreateConfigSubTreeFilter} from './simulations/configs/json-config-editor/create-config-sub-tree-filter.service';
import { EditorNodeExtensionsFactory } from './simulations/configs/json-config-editor/json-editor-node-extensions/editor-node-extensions-factory';
import { EditConfigComponent } from './simulations/configs/edit-config/edit-config.component';
import {IsWorksheetPage} from './simulations/is-worksheet-page';
import { CompareConfigsComponent } from './simulations/configs/comparing/compare-configs/compare-configs.component';
import { CompareConfigVisualizationsComponent } from './simulations/configs/comparing/compare-config-visualizations/compare-config-visualizations.component';
import { CompareConfigStructuresComponent } from './simulations/configs/comparing/compare-config-structures/compare-config-structures.component';
import {ConvertDiffToViewModel} from './simulations/configs/comparing/compare-config-structures/convert-diff-to-view-model.service';
import { CompareConfigStructuresRendererComponent } from './simulations/configs/comparing/compare-config-structures-renderer/compare-config-structures-renderer.component';
import { CompareConfigStructuresValueRendererComponent } from './simulations/configs/comparing/compare-config-structures-value-renderer/compare-config-structures-value-renderer.component';
import { CompareConfigStructuresUnidiffRendererComponent } from './simulations/configs/comparing/compare-config-structures-unidiff-renderer/compare-config-structures-unidiff-renderer.component';
import {ApplyChangesToConfig} from './simulations/configs/apply-changes-to-config.service';
import {SaveToConfigReferenceHandlerFactory} from './simulations/configs/comparing/save-output-config-handlers/save-to-config-reference-handler';
import {SaveToEditorHandlerFactory} from './simulations/configs/comparing/save-output-config-handlers/save-to-editor-handler';
import {SaveToStagingAreaHandlerFactory} from './simulations/configs/comparing/save-output-config-handlers/save-to-staging-area-handler';
import { CompareToSomethingButtonComponent } from './simulations/configs/comparing/compare-to-something-button/compare-to-something-button.component';
import {CompareConfigToSomething} from './worksheets/compare-config-to-something';
import { SetGlobalSimVersionComponent } from './admin/set-global-sim-version/set-global-sim-version.component';
import { CompareConfigToOriginalComponent } from './simulations/configs/compare-config-to-original/compare-config-to-original.component';
import { ImportFileButtonComponent } from './common/import-file-button/import-file-button.component';
import {ExecuteStudyAndWaitFactory} from './simulations/studies/execute-study-and-wait.service';
import {ConvertPacejkaTirToJson} from './simulations/pacejka-canopy-conversion/convert-pacejka-tir-to-json.service';
import {ProcessPacejkaJson} from './simulations/pacejka-canopy-conversion/process-pacejka-json.service';
import {PerformPacejkaCanopyConversionFactory} from './simulations/pacejka-canopy-conversion/perform-pacejka-canopy-conversion';
import {PerformTirImportFactory} from './simulations/pacejka-canopy-conversion/perform-tir-import';
import {ConvertPacejkaJsonToCanopy} from './simulations/pacejka-canopy-conversion/convert-pacejka-json-to-canopy.service';
import { TenantDownloadsComponent } from './tenancy/tenant-downloads/tenant-downloads.component';
import { TenantDownloadsPageComponent } from './tenancy/tenant-downloads-page/tenant-downloads-page.component';
import { ZendeskComponent } from './sso/zendesk/zendesk.component';
import { UserSettingsSimVersionComponent } from './tenancy/sim-version/user-settings-sim-version/user-settings-sim-version.component';
import { GetUserMathsFormulaAutocompleteOptions } from './simulations/configs/json-config-editor/get-user-maths-formula-autocomplete-options.service';
import { TenantSettingsChannelWhitelistsComponent } from './tenancy/tenant-settings-channel-whitelists/tenant-settings-channel-whitelists.component';
import { TenantSettingsChannelWhitelistsPageComponent } from './tenancy/tenant-settings-channel-whitelists-page/tenant-settings-channel-whitelists-page.component';
import {ConformTirDataToCanopyStructureService} from './simulations/pacejka-canopy-conversion/conform-tir-data-to-canopy-structure.service';
import { HeaderMessageComponent } from './header-message/header-message.component';
import { SurveyMessageComponent } from './survey-message/survey-message.component';
import { StageAllInputsComponent } from './simulations/studies/stage-all-inputs/stage-all-inputs.component';
import {InferInputSources} from './simulations/studies/infer-input-sources.service';
import {StageInputSource} from './simulations/studies/stage-input-source.service';
import { UpdateRowNames } from './worksheets/worksheet-commands/import-staged/update-row-names';
import { GetConfigsToImport } from './worksheets/worksheet-commands/import-staged/get-configs-to-import';
import { ImportConfigFromStagingArea } from './worksheets/worksheet-commands/import-staged/import-config-from-staging-area';
import { ImportStandardConfigFromStagingArea } from './worksheets/worksheet-commands/import-staged/import-standard-config-from-staging-area';
import { ImportTelemetryConfigFromStagingArea } from './worksheets/worksheet-commands/import-staged/import-telemetry-config-from-staging-area';
import { ZendeskInformationComponent } from './simulations/support-sessions/zendesk-information/zendesk-information.component';
import { ZendeskMessagePage } from './sso/zendesk-message/zendesk-message.page';
import { ConfirmEmailPage } from './confirm-email/confirm-email.page';
import { LocalUserChartSettingsManager } from './common/local-user-chart-settings-manager';
import { DownloadReEncryptedConfigDialogComponent } from './encryption/download-re-encrypted-config-dialog/download-re-encrypted-config-dialog.component';
import { DownloadReEncryptedConfigDialogSessionFactory } from './encryption/download-re-encrypted-config-dialog/download-re-encrypted-config-dialog-session';
import { DownloadReEncryptedConfigDialog } from './encryption/download-re-encrypted-config-dialog/download-re-encrypted-config-dialog.service';
import { DownloadFile } from './common/download-file.service';
import { DownloadReEncryptedConfig } from './encryption/download-re-encrypted-config.service';
import { ViewConfigPermissionComponent } from './encryption/config-permissions/view-config-permissions/view-config-permission.component';
import { EditConfigPermissionComponent } from './encryption/config-permissions/edit-config-permissions/edit-config-permission.component';
import { isDevMode } from '@angular/core';
import { ConfigPermissionsState } from './encryption/config-permissions/state/config-permissions.state';
import { InputTextComponent } from './common/components/input-text/input-text.component';
import { FormBuilderComponent } from './common/components/form-builder/form-builder.component';
import { FormBuilderService } from './common/services/form-builder.service';
import { ValidationMessageContainer } from './common/components/validation-message-container/validation-message-container.component';
import { FormBackButtonComponent } from './common/components/form-back-button/form-back-button.component';
import { ErrorMessageService } from './common/errors/services/error-message/error-message.service';
import { FormErrorMessageComponent } from './common/errors/components/form-error-message/form-error-message.component';
import { EditConfigPermissionsConfig } from './encryption/config-permissions/edit-config-permissions/edit-config-permission.config';
import { ConfigPermissionsService } from './encryption/config-permissions/services/config-permissions.service';
import { ApplyCustomizations } from './simulations/configs/json-config-editor/json-editor-customizations/apply-customizations';
import { getJsonEditorCustomisationProviders } from './simulations/configs/json-config-editor/json-editor-customizations/get-json-editor-customization-providers';
import { getJsonEditorNodeExtensionsProviders } from './simulations/configs/json-config-editor/json-editor-node-extensions/get-json-editor-node-extensions-providers';
import { EditKeyPermissionsConfig } from './encryption/key-permissions/edit-key-permissions/edit-key-permission.config';
import { KeyPermissionsService } from './encryption/key-permissions/services/key-permissions.service';
import { ViewKeyPermissionComponent } from './encryption/key-permissions/view-key-permissions/view-key-permission.component';
import { EditKeyPermissionComponent } from './encryption/key-permissions/edit-key-permissions/edit-key-permission.component';
import { KeyPermissionsState } from './encryption/key-permissions/state/key-permissions.state';
import { EncryptionHelpLinksComponent } from './encryption/encryption-help-links/encryption-help-links.component';
import { RouteResolvingIndicatorComponent } from './common/route-resolving-indicator/route-resolving-indicator.component';
import { ConfigPermissionResolve } from './encryption/config-permissions/config-permission-resolve';
import { KeyPermissionsResolve } from './encryption/key-permissions/key-permissions-resolve';
import { GenericErrorPage } from './common/errors/generic-error-page/generic-error.page';
import { ErrorMessageComponent } from './common/errors/components/error-message/error-message.component';
import { ErrorMessageState } from './common/errors/state/error-message/error-message.state';
import { ConfigPermissionsResolve } from './encryption/config-permissions/config-permissions-resolve';
import { KeyPermissionResolve } from './encryption/key-permissions/key-permission-resolve';
import { SetAutocompleteSanitizer } from './common/set-autocomplete-sanitizer';
import { SetPriorityComponent } from './simulations/studies/view-study-page/set-priority/set-priority.component';
import { AuthenticationState } from './identity/state/authentication.state';
import { AuthenticationService } from './identity/state/authentication.service';
import { MultiFactorAuthenticationComponent } from './identity/multi-factor-authentication.component';
import { AuthGuardService } from './identity/authorization/auth-guard.service';
import { IDENTITY_BASE_URL } from '../generated/api-base-url';
import { DownloadButtonsComponent } from './simulations/studies/view-study-page/download-buttons/download-buttons.component';
import { RowItemUrlService } from './worksheets/worksheet-commands/row-item-url.service';
import { ConfigReferenceLoaderFactory } from './simulations/configs/comparing/config-loaders/config-reference-loader';
import { ExportConfigButtonsComponent } from './simulations/configs/export-config-buttons/export-config-buttons.component';
import { StudyJobsSummaryComponent } from './simulations/studies/study-jobs-summary/study-jobs-summary.component';
import { RedirectService } from './navigation/redirect.service';
import { BootstrapAutomatedTests } from './visualizations/tests/bootstrap-automated-tests';
import { SentryBreadcrumbHandler } from './common/sentry-breadcrumb-handler.service';
import { ThemeSwitcherComponent } from './navigation/theme-switcher/theme-switcher.component';
import { ConfigChangedEvents } from './simulations/configs/config-changed-events';

const sentry_error_handler_provider: Provider = {
  provide: ErrorHandler,
  useValue: createErrorHandler({
    logErrors: true,
  }),
};

@NgModule({
  declarations: [
        AppComponent,
        PersistentComponentsComponent,
        ToFixedPipe,
        ToPrecisionPipe,
        ToDateTimePipe,
        UnitsPipe,
        FromNowPipe,
        ToShortPathPipe,
        NumberWithCommasPipe,
        CustomLineBreaksPipe,
        NavBarComponent,
        Footer,
        StudyStagingAreaComponent,
        ResultsStagingAreaComponent,
        SetBodyDialogStyleDirective,
        ConfirmationDialogComponent,
        ConfigLoaderDialogComponent,
        PromptDialogComponent,
        UpdateCheckerComponent,
        PoolsComponent,
        PoolStatusComponent,
        AllTenantsStudyStatisticsComponent,
        SignOutEverywhereComponent,
        EditCustomPropertiesComponent,
        ViewCustomPropertiesComponent,
        ViewDefaultConfigsComponent,
        ListFilterComponent,
        ViewConfigsComponent,
        SaveRacingLineButtonsComponent,
        ViewJobComponent,
        AdminTenantSettingsComponent,
        TenantSettingsDefaultCustomPropertyNamesComponent,
        TenantStudyStatisticsComponent,
        HomePage,
        NotAuthorizedPage,
        SignInRedirectPage,
        NotFoundPage,
        AdminPage,
        ViewSimVersionDocumentsPage,
        AccountSettingsPage,
        ForgotPasswordPage,
        RegisterPage,
        ResetPasswordPage,
        PoolStatusPage,
        CreateConfigPage,
        EditConfigPage,
        EditStudyPage,
        ViewConfigPage,
        ViewConfigsPage,
        ViewJobPage,
        ViewJobsPage,
        ViewStudiesPage,
        ManageConfigComponentsPage,
        ViewStudyPage,
        CreateTenantPage,
        TenantAccountSettingsPage,
        TenantInformationPage,
        LogoComponent,
        LoaderComponent,
        ErrorMessageComponent,
        ChartHelpComponent,
        DynamicDisableDirective,
        CarConfigValidatorComponent,
        CarConfigValidationResultsComponent,
        CompareConfigDialogComponent,
        EditConfigStickyComponent,
        SetChannelUnitsDialogComponent,
        UnitComponent,
        SupportSessionLauncherComponent,
        SupportSessionDialogComponent,
        SupportSessionHoverLauncherComponent,
        ViewSupportSessionsPage,
        MarkdownComponent,
        ViewStudySourcesComponent,
        ViewJobDialogComponent,
        LoadingDialogComponent,
        CustomViewFilterComponent,
        QuickConfigManagerComponent,
        ChannelEditorDialogComponent,
        TenantSimulationQuotaViewComponent,
        TenantSettingsSimVersionComponent,
        TenantBillableSimulationCountComponent,
        ViewJobInputsComponent,
        ViewJobNavigationStationComponent,
        ViewJobResultsComponent,
        ViewJobResultsPage,
        ViewJobScalarResultsComponent,
        ViewJobLogFileComponent,
        ViewJobErrorsComponent,
        JobSelectorComponent,
        ViewCreditsComponent,
        FilterQueryBuilderComponent,
        FilterQueryBuilderGroupComponent,
        FilterQueryBuilderConditionComponent,
        FilterQueryRendererComponent,
        FilterQueryRendererGroupComponent,
        FilterQueryRendererConditionComponent,
        UserRolesComponent,
        ViewEditCustomPropertiesComponent,
        ViewTelemetryConfigsComponent,
        ViewStudiesComponent,
        TelemetryHelpComponent,
        SelectSimVersionComponent,
        SelectSimVersionDialogComponent,
        SimVersionStatusComponent,
        LintingEngineRunnerComponent,
        LintingEngineRunnerDialogComponent,
        LintingEngineDialogLauncherComponent,
        ListVersionsDialogLauncherComponent,
        ListVersionsDialogComponent,
        ImportTelemetryDialogComponent,
        ImportTelemetryDialogLauncherComponent,
        ConvertTelemetryCsvToJsonStageComponent,
        RefineTelemetryJsonStageComponent,
        ValidateTelemetryJsonStageComponent,
        RemapTelemetryJsonStageComponent,
        StageAsTelemetryTextComponent,
        EditAndRunTelemetryButtonComponent,
        NotificationEnablerComponent,
        ViewWorksheetsPage,
        EditWorksheetPage,
        WorksheetTableComponent,
        WorksheetRowComponent,
        WorksheetConfigItemComponent,
        WorksheetStudyItemComponent,
        WorksheetSimulationItemComponent,
        WorksheetItemErrorMessageComponent,
        WorksheetRowMetadataItemComponent,
        EditWorksheetComponent,
        WorksheetDockComponent,
        PreventParentScrollDirective,
        RequestUndockWorksheetPage,
        ElementHeightCacherDirective,
        StudyLoaderDialogComponent,
        WorksheetLabelsEditorDialogComponent,
        WorksheetLabelsEditorComponent,
        WorksheetLabelsComponent,
        SetOwnershipDialogLauncherComponent,
        CloneConfigLauncherComponent,
        ContextMenuRendererComponent,
        ContextMenuComponent,
        ClickOutsideDirective,
        FocusOnShowDirective,
        WorksheetContextMenuButtonComponent,
        SaveConfigsWithConstraintResultsButtonsComponent,
        EditDocumentMetadataComponent,
        EditDocumentMetadataDialogComponent,
        EditConfigComponent,
        CompareConfigsComponent,
        CompareConfigVisualizationsComponent,
        CompareConfigStructuresComponent,
        CompareConfigStructuresRendererComponent,
        CompareConfigStructuresValueRendererComponent,
        CompareConfigStructuresUnidiffRendererComponent,
        CompareToSomethingButtonComponent,
        SetGlobalSimVersionComponent,
        CompareConfigToOriginalComponent,
        ImportFileButtonComponent,
        TenantDownloadsComponent,
        TenantDownloadsPageComponent,
        ZendeskComponent,
        UserSettingsSimVersionComponent,
        TenantSettingsChannelWhitelistsComponent,
        TenantSettingsChannelWhitelistsPageComponent,
        HeaderMessageComponent,
        SurveyMessageComponent,
        StageAllInputsComponent,
        ZendeskInformationComponent,
        ZendeskMessagePage,
        ConfirmEmailPage,
        DownloadReEncryptedConfigDialogComponent,
        ViewConfigPermissionComponent,
        EditConfigPermissionComponent,
        ViewKeyPermissionComponent,
        EditKeyPermissionComponent,
        InputTextComponent,
        FormBuilderComponent,
        ValidationMessageContainer,
        FormBackButtonComponent,
        FormErrorMessageComponent,
        EncryptionHelpLinksComponent,
        RouteResolvingIndicatorComponent,
        GenericErrorPage,
        SetPriorityComponent,
        MultiFactorAuthenticationComponent,
        DownloadButtonsComponent,
        ExportConfigButtonsComponent,
        StudyJobsSummaryComponent,
        ThemeSwitcherComponent
    ],
    bootstrap: [AppComponent],
    schemas: [],
    imports: [
        TagInputModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        routing,
        AutosizeModule,
        ResizableModule,
        NgxsModule.forRoot([
            ErrorMessageState,
            ConfigPermissionsState,
            KeyPermissionsState,
            AuthenticationState,
        ], { developmentMode: isDevMode() }),
        NgxsStoragePluginModule.forRoot({
            keys: []
        }),
        AuthModule.forRoot({
            config: {
                authority: IDENTITY_BASE_URL.substring(0, IDENTITY_BASE_URL.length - 1),
                redirectUrl: `${window.location.origin}/sign-in-redirect`,
                unauthorizedRoute: 'not-authorized',
                postLoginRoute: `sign-in-redirect`,
                postLogoutRedirectUri: `${window.location.origin}`,
                clientId: 'angular_client',
                scope: 'openid profile canopy_api IdentityServerApi offline_access',
                responseType: 'code',
                silentRenew: true,
                useRefreshToken: true,
                logLevel: isDevMode() ? LogLevel.Debug : LogLevel.Error,
                maxIdTokenIatOffsetAllowedInSeconds: 600,
                triggerRefreshWhenIdTokenExpired: false,
                ignoreNonceAfterRefresh: true,
            },
        }),
        ToastrModule.forRoot({
            timeOut: 30000,
            preventDuplicates: true,
            countDuplicates: true,
            progressBar: true,
        })], providers: [
        provideAppInitializer(async () => {
          inject(SentryBreadcrumbHandler);
          let authenticationService = inject(AuthenticationService);
          await authenticationService.serviceOnInit()
        }),
        sentry_error_handler_provider,
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        { provide: APP_BASE_HREF, useValue: '/' },
        STUB_PROVIDERS,
        appRoutingProviders,
        SignOut,
        ExtractAuthenticationDataFromResponse,
        GetHttpError,
        LocalStorage,
        CookieService,
        SessionStorage,
        GetFriendlyErrorAndLog,
        CanopyHttp,
        Interceptors,
        AuthenticationInterceptor,
        DeveloperNameInterceptor,
        AcceptHeaderInterceptor,
        CanopyValidators,
        FormSubmissionHandler,
        Authorize,
        AuthorizePage,
        FetchUserState,
        FixQueryStringUrl,
        LogError,
        DeveloperNameReader,
        InferableRouteParams,
        TextFileReader,
        Dayjs,
        CanopyJson,
        FormCancellationHandler,
        StudyStagingArea,
        DialogManager,
        ConfirmationDialog,
        ApplicationInsights,
        ApplicationInsightsRouteChangedHandler,
        GetUsernameMap,
        GetTenantNameMap,
        CanopyPusher,
        VisualizationFactory,
        SimVersionDocumentCache,
        ResultsStagingArea,
        Rison,
        StudyUtilities,
        Timer,
        VisualizationUtilities,
        BasicFileLoaderFactory,
        BlobLoader,
        FilterUtilities,
        ChannelEditorDialogSessionFactory,
        ChannelEditorDialog,
        CanopySiteHooksFactory,
        ChartRepositoryFactory,
        ConfigLoaderUtilities,
        ConfigReferenceLoaderFactory,
        PromptDialog,
        ConfigLoaderDialog,
        ConfigLoaderDialogSessionFactory,
        CanopyFileLoaderFactory,
        StudyTypeLookup,
        StudyTypeLookupFactory,
        TenantStudyStatisticsUtilities,
        ConfigSubTreeRepositoryFactory,
        JsonEditor,
        GetStudyAutocompleteOptions,
        GetChannelNameAutocompleteOptions,
        GetUserMathsFormulaAutocompleteOptions,
        GetAutocompleteMaps,
        CarConfigValidationSessionFactory,
        CarConfigValidationStagingArea,
        CanopyJsonDiffPatch,
        CompareConfigDialog,
        CanopyAutocomplete,
        UserSettings,
        SetChannelUnitsDialog,
        SetChannelUnitsDialogSessionFactory,
        UnitsManager,
        UserSettingsCache,
        SupportSessionDialog,
        SupportSessionDialogSessionFactory,
        CreateUserInformationMaps,
        GetPreferredChart,
        SetPreferredChart,
        SavedConfigNamesCacheFactory,
        ViewJobDialog,
        ViewJobDialogSessionFactory,
        LoadingDialog,
        LoadingDialogSessionFactory,
        SaveAsDialog,
        CompareStagedInputToSource,
        StageStudy,
        DownloadStudy,
        GoToJob,
        ConvertConfigToCsv,
        ExportConfigService,
        GetJobsFromResultSources,
        JobViewModelFactory,
        JobViewModelSetFactory,
        LazyLoadedStudyJobFactory,
        LazyLoadedStudyJobMetadataFactory,
        LazyLoadedJobNameFactory,
        LazyLoadedStudyJobScalarResultSetsFactory,
        LazyLoadedStudyJobWarningsFactory,
        LazyLoadedStudyJobDiagnosisFactory,
        LazyLoadedStudyJobInfoFactory,
        LazyLoadedStudyFactory,
        LazyLoadedStudyMetadataFactory,
        LazyLoadedStudyNameFactory,
        LazyLoadedSimTypesFactory,
        GetVerifyCarVisualizationTabs,
        ListFilterQueryBuilderDataSourceFactory,
        UpgradeListFilterForComplexQueries,
        CreateConfigSubTreeFilter,
        LocalUserSettingsManager,
        LocalUserChartSettingsManager,
        GetUserSimVersion,
        GetTenantSimVersion,
        GetSimVersion,
        ConvertTelemetryConfigToStudy,
        SignificantSimVersions,
        SelectSimVersionDialogSessionFactory,
        SelectSimVersionDialog,
        LintingEngineRunnerDialogSessionFactory,
        LintingEngineRunnerDialog,
        LintingEngineFactory,
        EditorNodeExtensionsFactory,
        GetJobsSimTypeMetadataFactory,
        ListVersionsDialogSessionFactory,
        ListVersionsDialog,
        StripNonVersionedDataFromDocument,
        ImportTelemetryDialogSessionFactory,
        ImportTelemetryDialog,
        StudySubmittedEvents,
        ConfigChangedEvents,
        RouterDataCache,
        WorksheetViewModelFactory,
        SubmitStudy,
        WorksheetClipboard,
        WorksheetDock,
        ElementHeightCache,
        StudyLoaderDialogSessionFactory,
        StudyLoaderDialog,
        DocumentUpdatedEventService,
        DuplicateClipboardContentIfRequired,
        CreateWorksheetRowFromStudy,
        CreateWorksheetFromStudy,
        CreateWorksheetFromStudyIfRequired,
        WorksheetUnderlyingDataFactory,
        GetWorksheetForSupportSession,
        CompareConfigToStudyInput,
        LoadConfigFromStudyInput,
        LoadConfigFromReference,
        RecreateTelemetryStudyFromInputData,
        LoadStudy,
        WorksheetLabelsEditorDialog,
        WorksheetLabelsEditorDialogSessionFactory,
        CompareConfigToStaged,
        CompareConfigToSomething,
        CompareSelectedConfigs,
        StageConfigReference,
        ActiveWorksheets,
        PromptAndSetOwnership,
        CloneWorksheet,
        CellElementToViewModelLookup,
        ContextMenu,
        ALL_WORKSHEET_COMMAND_FACTORIES,
        ConfigContextMenuBuilder,
        StudyContextMenuBuilder,
        RowMetadataContextMenuBuilder,
        SimulationContextMenuBuilder,
        ConfirmReplaceAllReferences,
        SetConfigOrAllReferences,
        PasteContent,
        AddStudyToWorksheetAndExtractInputs,
        DeduplicateWorksheetRow,
        RowItemUrlService,
        SetStandardConfig,
        SetTelemetryConfig,
        LoadContextConfig,
        RetryOnNetworkErrorInterceptor,
        EditDocumentMetadataDialog,
        EditDocumentMetadataDialogSessionFactory,
        IsWorksheetPage,
        ConvertDiffToViewModel,
        ApplyChangesToConfig,
        SaveToConfigReferenceHandlerFactory,
        SaveToEditorHandlerFactory,
        SaveToStagingAreaHandlerFactory,
        ExecuteStudyAndWaitFactory,
        ConvertPacejkaTirToJson,
        ConvertPacejkaJsonToCanopy,
        ProcessPacejkaJson,
        PerformPacejkaCanopyConversionFactory,
        PerformTirImportFactory,
        ConformTirDataToCanopyStructureService,
        InferInputSources,
        StageInputSource,
        UpdateRowNames,
        GetConfigsToImport,
        ImportConfigFromStagingArea,
        ImportStandardConfigFromStagingArea,
        ImportTelemetryConfigFromStagingArea,
        DownloadReEncryptedConfigDialog,
        DownloadReEncryptedConfigDialogSessionFactory,
        DownloadFile,
        DownloadReEncryptedConfig,
        FormBuilderService,
        ErrorMessageService,
        EditConfigPermissionsConfig,
        ConfigPermissionsService,
        ConfigPermissionResolve,
        ConfigPermissionsResolve,
        EditKeyPermissionsConfig,
        KeyPermissionsService,
        KeyPermissionResolve,
        KeyPermissionsResolve,
        ApplyCustomizations,
        SetAutocompleteSanitizer,
        ...getJsonEditorCustomisationProviders(),
        ...getJsonEditorNodeExtensionsProviders(),
        AuthenticationService,
        AuthGuardService,
        RedirectService,
        BootstrapAutomatedTests,
        provideHttpClient(withInterceptorsFromDi())
    ]
  })
export class AppModule {}
