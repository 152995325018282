import {Component, Input} from '@angular/core';
import {TenantSettingsStub} from '../../../generated/api-stubs';
import {SimVersionSelectedEvent} from './select-sim-version.component';
import {GetTenantSimVersion} from '../../common/get-tenant-sim-version.service';

@Component({
    selector: 'cs-tenant-settings-sim-version',
    templateUrl: './tenant-settings-sim-version.component.html',
    styleUrls: ['./tenant-settings-sim-version.component.scss'],
    standalone: false
})
export class TenantSettingsSimVersionComponent {

  @Input() public tenantId: string;

  constructor(
    private readonly tenantSettingsStub: TenantSettingsStub,
    private readonly getSimVersion: GetTenantSimVersion) {
  }

  public onSimVersionSelected(event: SimVersionSelectedEvent){
    event.promise = this.setSimVersion(event.simVersion);
  }

  public async setSimVersion(simVersion: string) {
    await this.tenantSettingsStub.putTenantSettingsSimVersion(this.tenantId, {
      simVersion
    });

    // Update cached version if current tenant.
    await this.getSimVersion.executeForTenant(this.tenantId);
  }
}
