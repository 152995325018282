import {Component, Input} from '@angular/core';
import {ResolvedLabel} from '../../../generated/api-stubs';

/**
 * Component for displaying labels in a worksheet cell.
 */
@Component({
    selector: 'cs-worksheet-labels',
    templateUrl: './worksheet-labels.component.html',
    styleUrls: ['./worksheet-labels.component.scss'],
    standalone: false
})
export class WorksheetLabelsComponent {

  /**
   * The labels to display.
   */
  @Input() public labels: ReadonlyArray<ResolvedLabel>;

  /**
   * Whether there are any labels to display.
   */
  public get hasLabels(): boolean {
    return !!(this.labels && this.labels.length);
  }

  /**
   * Whether the first, and therefore all, labels are statistics.
   * Studies with multiple jobs will have statistics (min, mean, max) for each label, so
   * either all the labels will be statistics or none of them will be.
   */
  public get isNumericStatistics(): boolean {
    let value = this.labels[0].numericStatistics;
    return value !== null && value !== undefined;
  }

  /**
   * Whether the label at the given index is a numeric value.
   * @param index The label index to check.
   */
  public isNumericValue(index: number): boolean {
    if (index >= this.labels.length) {
      return false;
    }

    let value = this.labels[index].numericValue;
    return value !== null && value !== undefined;
  }

  /**
   * Whether the label at the given index is a string value.
   * @param index The label index to check.
   */
  public isStringValue(index: number): boolean {
    if (index >= this.labels.length) {
      return false;
    }

    let value = this.labels[index].stringValue;
    return value !== null && value !== undefined;
  }
}
