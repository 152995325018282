import {Component, ElementRef, HostBinding, Input} from '@angular/core';
import {ConfigViewModel} from '../config-view-model';
import {StudyInputNecessity} from '../study-view-model';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {
  WorksheetItemComponentBase
} from '../worksheet-item-component-base';
import {GetSimVersion} from '../../common/get-sim-version.service';
import {DocumentSubType, StudyDocument} from '../../../generated/api-stubs';
import {HashValidity} from '../hash-validity';
import {CellElementToViewModelLookup} from '../cell-element-to-view-model-lookup';
import {ConfigContextMenuBuilder} from '../worksheet-commands/config-context-menu-builder';
import { AuthenticationService } from '../../identity/state/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { RowItemUrlService } from '../worksheet-commands/row-item-url.service';

/**
 * Component for displaying a config in the worksheet.
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
    selector: '[cs-worksheet-config-item]',
    templateUrl: './worksheet-config-item.component.html',
    styleUrls: ['./worksheet-config-item.component.scss'],
    standalone: false
})
export class WorksheetConfigItemComponent extends WorksheetItemComponentBase {

  /**
   * The view model for the config.
   */
  @Input() public viewModel: ConfigViewModel;

  /**
   * Whether enum for whether the config is necessary for the selected study type.
   */
  public StudyInputNecessity = StudyInputNecessity;

  /**
   * The config type enum.
   */
  public DocumentSubType = DocumentSubType;

  /**
   * The validity of the hash vs the run study. In other words, is this the config
   * that the study was run with.
   */
  public HashValidity = HashValidity;

  /**
   * Creates an instance of WorksheetConfigItemComponent.
   * @param authenticationService The authentication service.
   * @param cellElementToViewModelLookup The cell element to view model lookup.
   * @param elementRef The element reference.
   * @param contextMenuBuilder The context menu builder.
   * @param getSimVersion The service for getting the current sim version.
   * @param getFriendlyErrorAndLog The service for getting a friendly error message and logging the error.
   * @param rowItemUrl The service for getting the URL for a row item.
   * @param route The activated route.
   */
  constructor(
    authenticationService: AuthenticationService,
    cellElementToViewModelLookup: CellElementToViewModelLookup,
    elementRef: ElementRef,
    contextMenuBuilder: ConfigContextMenuBuilder,
    private readonly getSimVersion: GetSimVersion,
    getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    rowItemUrl: RowItemUrlService,
    route: ActivatedRoute) {
    super(authenticationService, cellElementToViewModelLookup, elementRef, contextMenuBuilder, getFriendlyErrorAndLog, rowItemUrl, route);
  }

  /**
   * Whether the config is an identical config to the selected config.
   */
  @HostBinding('class.matches-selected') public get matchesSelected(): boolean {
    return this.viewModel.matchesSelectedConfig;
  }

  /**
   * Whether the config is selected.
   */
  @HostBinding('class.is-selected') public get isSelected(): boolean {
    return this.viewModel.isSelected;
  }

  /**
   * The string version of the config reference count.
   */
  public get referenceCountString(): string {
    let referenceMetadata = this.viewModel.referenceMetadata;
    if(!referenceMetadata){
      return '';
    }

    if(this.viewModel.configType === DocumentSubType.telemetry){
      // Telemetry can have both config and study references.
      return this.getDualReferenceCountString(referenceMetadata.configCount, referenceMetadata.studyCount);
    }

    return this.getReferenceCountString(referenceMetadata.configCount);
  }

  /**
   * The current sim version.
   */
  public get currentSimVersion(): string {
    return this.getSimVersion.currentSimVersion;
  }

  /**
   * Whether the config is necessary for the selected study type.
   */
  public get studyInputNecessity(): StudyInputNecessity {
    return this.viewModel.studyInputNecessity;
  }

  /**
   * Whether the config is read only to the current user.
   */
  public get isConfigReadOnly(): boolean {
    return this.viewModel.isReadOnly(this.userData.sub);
  }

  /**
   * Whether the config has a job index (can only be true if the config is a telemetry config).
   */
  public get hasJobIndex(): boolean {
    if(!this.viewModel.isTelemetry){
      return false;
    }

    const reference = this.viewModel.reference;
    return !!(reference && reference.tenant && reference.tenant.jobIndex);
  }

  /**
   * The job index of the config (can only be non-zero if the config is a telemetry config).
   */
  public get jobIndex(): number {
    if(this.hasJobIndex) {
      return this.viewModel.reference.tenant.jobIndex;
    }

    return 0;
  }

  /**
   * The study on the worksheet row.
   */
  public get study(): StudyDocument{
    return this.viewModel.populated.populatedStudy.resolvedReference?.data?.studyDocument;
  }
}
