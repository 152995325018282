import { Component } from '@angular/core';
import {
  ConfigType,
  WorksheetConfigType
} from '../../simulations/configs/config-types';
import {ListFilterData, WorksheetStub} from '../../../generated/api-stubs';
import {FilterUtilities} from '../../simulations/list-filtering/filter-utilities';
import {InferableRouteParams} from '../../common/inferable-route-params.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigSummary} from '../../simulations/configs/view-configs/view-configs.component';
import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {LoadingDialog} from '../../common/dialogs/loading-dialog.service';
import { AuthenticationService, UserData } from '../../identity/state/authentication.service';

/**
 * The page for viewing a list of worksheets.
 */
@Component({
    templateUrl: './view-worksheets.page.html',
    styleUrls: ['./view-worksheets.page.scss'],
    standalone: false
})
export class ViewWorksheetsPage {

  /**
   * The type of configuration to display.
   */
  public configType: ConfigType = WorksheetConfigType;

  /**
   * The tenant ID.
   */
  public tenantId: string;

  /**
   * The user ID.
   */
  public userId: string;

  /**
   * The user authentication data.
   */
  public userData: UserData;

  /**
   * The initial filter for the list of worksheets.
   */
  public initialFilter: ListFilterData;

  /**
   * The error message to display if an error occurs.
   */
  public errorMessage: string;

  /**
   * Creates an instance of ViewWorksheetsPage.
   * @param filterUtilities The filter utilities service.
   * @param inferableRouteParams The service for getting route parameters that can be inferred if absent.
   * @param route The activated route.
   * @param promptDialog The service for displaying a dialog prompt.
   * @param loadingDialog The service for displaying a loading dialog.
   * @param worksheetStub The worksheet stub service.
   * @param getFriendlyErrorMessageAndLog The service to get a friendly error message and log the error.
   * @param router The router service.
   * @param authenticationService The authentication service.
   */
  constructor(
    filterUtilities: FilterUtilities,
    inferableRouteParams: InferableRouteParams,
    route: ActivatedRoute,
    private readonly promptDialog: PromptDialog,
    private readonly loadingDialog: LoadingDialog,
    private readonly worksheetStub: WorksheetStub,
    private readonly getFriendlyErrorMessageAndLog: GetFriendlyErrorAndLog,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService) {

    this.tenantId = inferableRouteParams.getTenantId(route);
    this.userId = inferableRouteParams.getUserId(route);

    this.initialFilter = filterUtilities.getInitialFilter();

    this.userData = this.authenticationService.userDataSnapshot;
  }

  /**
   * Handles the filter changed event.
   * @param newFilter The new filter.
   */
  public filterChanged(newFilter: ListFilterData){
  }

  /**
   * Where to navigate to when a worksheet is selected.
   * @param config The configuration.
   * @returns The URL tree to navigate to.
   */
  public navigationUrlTree(config: ConfigSummary) {
    return ['/worksheets', config.tenantId, config.documentId];
  }

  /**
   * Handles the user requesting to create a new worksheet.
   */
  public async createWorksheet() {
    try {
      const name = await this.promptDialog.show<string>('Enter a name for the new worksheet', 'Worksheet Name');
      if(!name){
        return;
      }

      await this.loadingDialog.show(async (d) => {
        d.setStatus('Creating...');
        const createResult = await this.worksheetStub.postWorksheet(
          this.tenantId,
          {
            name
          });
        await this.router.navigate(['/worksheets', createResult.worksheet.tenantId, createResult.worksheet.worksheetId]);
      });
    } catch(error){
      this.errorMessage = this.getFriendlyErrorMessageAndLog.execute(error);
    }
  }
}
