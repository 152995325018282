import {Component, Input, OnInit} from '@angular/core';
import {JobViewModel} from '../../../jobs/job-results/job-view-model';
import {GetFriendlyErrorAndLog} from '../../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {ScalarResultSet} from '../../../studies/study-utilities.service';
import {
  ConfigStub,
  DocumentSubType,
  StudyDocumentStudyDocumentDataSource
} from '../../../../../generated/api-stubs';
import {ConfigTypeLookup, sortByConfigType} from '../../config-types';
import {DisplayableError} from '../../../../common/errors/errors';
import {CanopyJson} from '../../../../common/canopy-json.service';
import {LoadingDialog} from '../../../../common/dialogs/loading-dialog.service';
import {GetSimVersion} from '../../../../common/get-sim-version.service';
import {CustomPropertyUtilities} from '../../../custom-properties/custom-property-utilities';
import {StudyStagingArea} from '../../../study-staging-area/study-staging-area.service';
import {
  CompareConfigDialog,
  NO_SAVE_OUTPUT_CONFIG_HANDLER
} from '../../comparing/compare-config-dialog/compare-config-dialog.service';
import {SaveAsDialog} from '../../../../common/dialogs/save-as-dialog.service';
import {ApplyChangesToConfig, ConfigChange} from '../../apply-changes-to-config.service';
import {StudyInput} from '../../../../worksheets/study-input';
import {studyResultToStudyInput} from '../../../../worksheets/study-input-utilities';
import {ConfigOrConfigLoader} from '../../comparing/config-or-config-loader';
import {getSourceConfig} from '../../../../worksheets/study-utilities';
import { AuthenticationService, UserData } from '../../../../identity/state/authentication.service';

@Component({
    selector: 'cs-save-configs-with-constraint-results-buttons',
    templateUrl: './save-configs-with-constraint-results-buttons.component.html',
    styleUrls: ['./save-configs-with-constraint-results-buttons.component.scss'],
    standalone: false
})
export class SaveConfigsWithConstraintResultsButtonsComponent implements OnInit {

  @Input() public job: JobViewModel;

  public errorMessage: string;

  public userData: UserData;
  public configChanges: ConfigChanges[];
  public sourcesByConfigType: SourcesByConfigType;

  public isLoading: boolean = false;

  constructor(
    private readonly getSimVersion: GetSimVersion,
    private readonly configStub: ConfigStub,
    private readonly loadingDialog: LoadingDialog,
    private readonly studyStagingArea: StudyStagingArea,
    private readonly compareConfigDialog: CompareConfigDialog,
    private readonly saveAsDialog: SaveAsDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly json: CanopyJson,
    private readonly applyChangesToConfig: ApplyChangesToConfig,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog) {
  }

  ngOnInit() {
    this.load();
  }

  public async load(){
    try {
      if(!this.job) {
        return;
      }

      this.isLoading = true;
      this.userData = this.authenticationService.userDataSnapshot;

      await this.job.scalarResults.load();

      this.configChanges = this.extractConfigChanges(this.job.scalarResults.value);
      sortByConfigType(this.configChanges, v => v.configType);

      if(this.configChanges.length){
        await this.job.studyResult.load();
        let studyResult = this.job.studyResult.value;

        this.sourcesByConfigType = studyResult.study.data.sources.reduce(
          (p, c) => {
            p[c.configType] = c;
            return p;
          },
          {} as SourcesByConfigType);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }

    this.isLoading = false;
  }

  public async compareToInput(item: ConfigChanges){
    try {
      this.errorMessage = undefined;

      let sourceWithChanges = await this.getConfigWithChanges(item);

      let source = studyResultToStudyInput(item.configType, this.job.studyResult.value);
      let sourceData = getSourceConfig(item.configType, this.job.studyResult.value, this.job.jobResult.value);
      source = source.withData(sourceData);

      await this.compareConfigDialog.compare(
        item.configType,
        [
          new ConfigOrConfigLoader('input with constraint results applied', sourceWithChanges, undefined),
          new ConfigOrConfigLoader('input', source, undefined),
        ],
        NO_SAVE_OUTPUT_CONFIG_HANDLER);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async stageWithChanges(item: ConfigChanges){
    try {
      this.errorMessage = undefined;
      let studyInput = await this.getConfigWithChanges(item);
      this.studyStagingArea.stageInput(studyInput);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async saveChangesToSourceConfig(item: ConfigChanges){
    try {
      this.errorMessage = undefined;
      let configTypeSource = this.sourcesByConfigType[item.configType];
      if(!configTypeSource || !configTypeSource.configId){
        return;
      }

      let config = await this.getConfigWithChanges(item);

      let configId = configTypeSource.configId;
      let sourceResult = await this.loadingDialog.showUntilFinished(
        this.configStub.getConfig(
          this.userData.tenant,
          configId,
          undefined,
          this.getSimVersion.currentSimVersion),
        'Loading source config...');

      await this.loadingDialog.showUntilFinished(
        this.configStub.putConfig(
          this.userData.tenant,
          configId,
          {
            name: sourceResult.config.name,
            configType: sourceResult.config.subType,
            properties:  CustomPropertyUtilities.objectToList(sourceResult.config.properties),
            notes: sourceResult.config.notes,
            config: config.data,
            simVersion: config.simVersion
          }),
        'Saving...');
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public async saveChangesToNewConfig(item: ConfigChanges){
    try {
      this.errorMessage = undefined;
      let config = await this.getConfigWithChanges(item);

      await this.saveAsDialog.showForContent(
        config.name,
        item.configType,
        config.simVersion,
        undefined,
        config.data,
        config.properties,
        config.notes);
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  public canSaveToSource(configType: DocumentSubType){
    if(!this.sourcesByConfigType){
      return false;
    }

    let configTypeSource = this.sourcesByConfigType[configType];
    if(!configTypeSource){
      return false;
    }

    return !!configTypeSource.configId
      && !!configTypeSource.userId
      && configTypeSource.userId === this.userData.sub;
  }

  private async getConfigWithChanges(item: ConfigChanges): Promise<StudyInput> {
    await this.loadingDialog.showUntilFinished(
      this.job.jobResult.load(),
      'Please wait...');
    await this.loadingDialog.showUntilFinished(
      this.job.studyResult.load(),
      'Please wait...');

    let config = getSourceConfig(item.configType, this.job.studyResult.value, this.job.jobResult.value);
    if(!config){
      throw new DisplayableError('Input config not found.');
    }

    config = this.json.clone(config);

    this.applyChangesToConfig.execute(config, item.changes);

    let studyInput = studyResultToStudyInput(item.configType, this.job.studyResult.value);
    studyInput = studyInput.withData(config, true);

    return studyInput;
  }

  private extractConfigChanges(scalarResultSets: ScalarResultSet[]): ConfigChanges[] {

    const resultMap = new Map<string, ConfigChange[]>();
    for(let scalarResultSet of scalarResultSets){
      if(!scalarResultSet.constraintResults.length){
        continue;
      }

      for(let scalarResult of scalarResultSet.constraintResults){
        let splitPath = scalarResult.constraintParameterPath.split('.');
        if(splitPath.length <= 2){
          continue;
        }

        let configType = splitPath[0];
        let configPath = splitPath.slice(1);
        let value = scalarResult.value;

        let existing = resultMap.get(configType);
        if(!existing){
          existing = [];
          resultMap.set(configType, existing);
        }

        existing.push(new ConfigChange(configPath, value));
      }
    }

    const result: ConfigChanges[] = [];
    for(let [key, value] of Array.from(resultMap.entries())){
      const resolvedConfigType = ConfigTypeLookup.get(key);
      if(!resolvedConfigType){
        continue;
      }

      result.push(new ConfigChanges(
        resolvedConfigType.singularKey,
        resolvedConfigType.titleName,
        value));
    }

    return result;
  }
}

export class ConfigChanges {
  constructor(
    public readonly configType: DocumentSubType,
    public readonly name: string,
    public readonly changes: ReadonlyArray<ConfigChange>){
  }
}

interface SourcesByConfigType { [configType: string]: StudyDocumentStudyDocumentDataSource }
