import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {GetUserSimVersion} from '../common/get-user-sim-version.service';
import {GetTenantSimVersion} from '../common/get-tenant-sim-version.service';
import {ApiUrl} from '../api-url';
import { AuthenticationService } from '../identity/state/authentication.service';

@Component({
    selector: 'cs-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class Footer implements OnInit {
  public apiBaseUrl: string = ApiUrl.value;
  public isTestUser: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private getUserSimVersion: GetUserSimVersion,
    private getTenantSimVersion: GetTenantSimVersion){
  }

  public ngOnInit() {
    this.load();
  }

  public load(){
    this.isTestUser = this.authenticationService.isTestUser;
  }

  public get lastRequestedUserSimVersion() {
    return this.getUserSimVersion.currentSimVersion;
  }

  public get lastRequestedTenantSimVersion() {
    return this.getTenantSimVersion.currentSimVersion;
  }
}
