import {Component, OnInit, Input} from '@angular/core';
import {JobViewModel} from '../../jobs/job-results/job-view-model';
import {CachedCarConfigValidation, CarConfigValidationStagingArea} from '../car-config-validation-staging-area.service';
import {GetFriendlyErrorAndLog} from '../../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';

export const DEFAULT_SIMULATION_FAILED_MESSAGE = 'Failed to run simulation.';

@Component({
    selector: 'cs-car-config-validation-results',
    templateUrl: './car-config-validation-results.component.html',
    standalone: false
})
export class CarConfigValidationResultsComponent implements OnInit {
  @Input() public job: JobViewModel;
  @Input() public previousJob: JobViewModel;

  public errorMessage: string;
  public errorMessages: string[];
  public isStudySuccessful: boolean = false;

  public jobs: JobViewModel[];

  constructor(
    private readonly carConfigValidationStagingArea: CarConfigValidationStagingArea,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog){
  }

  ngOnInit(): void {
    this.load();
  }

  public load(): void{
    try {
      this.isStudySuccessful = !!this.job.studyMetadataResult.value.study.data.succeededJobCount;

      let jobs: JobViewModel[];
      if(!this.isStudySuccessful) {
        this.errorMessages = [
          DEFAULT_SIMULATION_FAILED_MESSAGE,
          ...(this.job.studyMetadataResult.value.study.data.errorMessages || []),
          ...(this.job.jobMetadataResult.value.studyJob.data.errorMessages || [])
        ];

        jobs = [];
      } else{
        let sessions: CachedCarConfigValidation[] = this.carConfigValidationStagingArea.get();
        jobs = sessions.map(v => v.jobViewModel);

        if(this.previousJob){
          if(jobs.every(v => v.source.studyId !== this.previousJob.source.studyId)){
            jobs.unshift(this.previousJob.clone('Previous'));
          }
        }
      }

      jobs.unshift(this.job);

      this.jobs = jobs;
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }
}

