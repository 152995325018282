import {Component, Input, OnInit} from '@angular/core';
import {GetFriendlyErrorAndLog} from '../../common/errors/services/get-friendly-error-and-log/get-friendly-error-and-log.service';
import {PromptDialog} from '../../common/dialogs/prompt-dialog.service';
import {CONFIG_LABEL_SOURCE, SCALAR_RESULTS_LABEL_SOURCE} from '../worksheet-constants';
import {DocumentSubType} from '../../../generated/api-stubs';
import {cssSanitize} from '../../common/css-sanitize';
import {GetStudyAutocompleteOptions} from '../../simulations/configs/json-config-editor/get-study-autocomplete-options.service';
import { ToastrService } from 'ngx-toastr';

/**
 * A definition for a label in a worksheet.
 */
export class CombinedWorksheetLabelDefinition {

  /**
   * Creates a new instance of CombinedWorksheetLabelDefinition.
   * @param source The source of the label data (e.g. config, scalar results).
   * @param name The name of the label (e.g. a config path, a scalar result name).
   * @param inWorksheetLabels Whether the label is in the worksheet labels, which are shown to all viewers of this worksheet.
   * @param inUserLabels Whether the label is in the user labels, which are shown to the current user for every worksheet.
   * @param inTenantLabels Whether the label is in the tenant labels, which are shown to all users in the tenant for every worksheet.
   */
  constructor(
    public readonly source: string,
    public readonly name: string,
    public inWorksheetLabels: boolean = false,
    public inUserLabels: boolean = false,
    public inTenantLabels: boolean = false){
  }
}

/**
 * A set of labels for a particular type of config or simulation (e.g. a car or straight sim).
 * It is the combined set of worksheet, user, and tenant labels.
 */
export class CombinedWorksheetLabelDefinitionSet {

  /**
   * Creates a new instance of CombinedWorksheetLabelDefinitionSet.
   * @param key The key for the label set (e.g. `car`).
   * @param displayName The display name for the label set (e.g. `Car`).
   * @param labelSetType The type of label set (e.g. config, simulation).
   * @param labels The labels in the set.
   */
  constructor(
    public readonly key: string,
    public readonly displayName: string,
    public readonly labelSetType: LabelSetType,
    public readonly labels: CombinedWorksheetLabelDefinition[]){
  }
}

/**
 * For a given category of labels (e.g. Configs or Simulations), this is the set of all label definition sets.
 */
export class CombinedWorksheetLabelDefinitionsSet {

  /**
   * Creates a new instance of CombinedWorksheetLabelDefinitionsSet.
   * @param name The name of the label set (e.g. Configs, Simulations).
   * @param items The items in the set.
   */
  constructor(
    public readonly name: string,
    public readonly items: CombinedWorksheetLabelDefinitionSet[]){
  }
}

/**
 * The combined set of worksheet label definitions for configs and simulations.
 */
export class CombinedWorksheetLabelDefinitions {

  /**
   * The list of label definitions sets.
   */
  public readonly list: ReadonlyArray<CombinedWorksheetLabelDefinitionsSet>;

  /**
   * Creates a new instance of CombinedWorksheetLabelDefinitions.
   * @param configLabelDefinitions The config label definition sets.
   * @param simulationLabelDefinitions The simulation label definition sets.
   */
  constructor(
    public readonly configLabelDefinitions: CombinedWorksheetLabelDefinitionSet[],
    public readonly simulationLabelDefinitions: CombinedWorksheetLabelDefinitionSet[]) {

    this.list = [
      new CombinedWorksheetLabelDefinitionsSet('Configs', this.configLabelDefinitions),
      new CombinedWorksheetLabelDefinitionsSet('Simulations', this.simulationLabelDefinitions),
    ];
  }
}

/**
 * The type of label set.
 */
export enum LabelSetType {
  config,
  simulation,
}

/**
 * The component for editing worksheet labels.
 */
@Component({
    selector: 'cs-worksheet-labels-editor',
    templateUrl: './worksheet-labels-editor.component.html',
    styleUrls: ['./worksheet-labels-editor.component.scss'],
    standalone: false
})
export class WorksheetLabelsEditorComponent implements OnInit {

  /**
   * Whether the user can edit the worksheet labels.
   */
  @Input() public readonly canEditWorksheetLabels: boolean;

  /**
   * The labels to edit.
   */
  @Input() public readonly labels: CombinedWorksheetLabelDefinitions;

  /**
   * The error message to display.
   */
  public errorMessage: string;

  /**
   * The active set of labels (i.e. which tab the user has selected).
   */
  public activeSet: CombinedWorksheetLabelDefinitionsSet;

  /**
   * Creates an instance of WorksheetLabelsEditorComponent.
   * @param promptDialog The prompt dialog service.
   * @param getStudyAutocompleteOptions The service for getting study autocomplete options.
   * @param getFriendlyErrorAndLog The service for getting a friendly error message and logging the error.
   */
  constructor(
    private readonly promptDialog: PromptDialog,
    private readonly getStudyAutocompleteOptions: GetStudyAutocompleteOptions,
    private readonly getFriendlyErrorAndLog: GetFriendlyErrorAndLog,
    private readonly toastr: ToastrService) { }

  /**
   * Initializes the component.
   */
  ngOnInit() {
    try {
      // If we have any sets, set the first one to be active.
      if(this.labels.list.length){
        this.activeSet = this.labels.list[0];
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Sets the active set of labels (switches tabs).
   * @param set The set to set as active.
   */
  public setActiveSet(set: CombinedWorksheetLabelDefinitionsSet){
    this.activeSet = set;
  }

  /**
   * Deletes a label from the set.
   * @param item The set to delete the label from.
   * @param definition The definition of the label to delete.
   */
  public deleteLabel(item: CombinedWorksheetLabelDefinitionSet, definition: CombinedWorksheetLabelDefinition){
    try {
      let index = item.labels.findIndex(v => v.name === definition.name && v.source === definition.source);
      if(index !== -1){
        item.labels.splice(index, 1);
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Toggles the location of a label (i.e. whether it is in the worksheet, user, or tenant labels).
   * @param definition The definition of the label to toggle.
   * @param toggle The location to toggle.
   */
  public toggleLocation(definition: CombinedWorksheetLabelDefinition, toggle: keyof CombinedWorksheetLabelDefinition){
    try {
      (definition as any)[toggle] = !definition[toggle];
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Adds a label to the set.
   * @param item The set to add the label to.
   */
  public async addLabel(item: CombinedWorksheetLabelDefinitionSet) {
    try {
      let newLabelDefinition: CombinedWorksheetLabelDefinition;

      if(item.labelSetType === LabelSetType.config){
        // Get autocomplete options for the prompt dialog.
        let configType = item.key as DocumentSubType;
        await this.getStudyAutocompleteOptions.initialize();
        let autocomplete = await this.getStudyAutocompleteOptions.executeSynchronous(false, false, configType);

        // Prompt the user for a path into the config (with autocomplete).
        let newLabel = await this.promptDialog.show<string>(
          'Enter a config path (stage a config to enable auto-complete):',
          'Add New Label',
          undefined,
          undefined,
          undefined,
          undefined,
          autocomplete.options);

        // If they entered a value, create the definition, defaulted to a user label.
        if(newLabel){
          newLabel = newLabel.trim();
          newLabelDefinition = new CombinedWorksheetLabelDefinition(
            CONFIG_LABEL_SOURCE,
            newLabel,
            false,
            true,
            false);
        }
      } else {

        // Prompt the user for a scalar result name (we don't currently hook up autocomplete here, but could
        // in theory).
        let newLabel = await this.promptDialog.show<string>(
          'Enter a scalar result name:',
          'Add New Label');

        // If they entered a value, create the definition, defaulted to a user label.
        if(newLabel){
          newLabel = newLabel.trim();
          newLabelDefinition = new CombinedWorksheetLabelDefinition(
            SCALAR_RESULTS_LABEL_SOURCE,
            newLabel,
            false,
            true,
            false);
        }
      }

      // If we have a new label definition
      if(newLabelDefinition){
        let validLabelRegex = new RegExp(/^\S+$/);
        let label = newLabelDefinition.name;
        if(!validLabelRegex.test(label)){
          this.toastr.error(`Invalid label. "${label}" must be a valid parameter path.`);
          return;
        }
        // If it isn't already in the set, add it.
        if(!item.labels.some(v => v.name === newLabelDefinition.name && v.source === newLabelDefinition.source)){
          item.labels.push(newLabelDefinition);
        }
      }
    } catch(error){
      this.errorMessage = this.getFriendlyErrorAndLog.execute(error);
    }
  }

  /**
   * Sanitizes a value for CSS.
   * @param value The value to sanitize.
   */
  public cssSanitize(value: string): string{
    return cssSanitize(value);
  }
}
