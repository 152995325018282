import { Component } from '@angular/core';
import { LogError } from './common/log-error.service';

@Component({
    selector: 'cs-not-found',
    template: `
    <div class="container-fluid">
      <div class="card card-body">
        <p>Route not found.</p>
      </div>
    </div>`,
    standalone: false
})
export class NotFoundPage {
  constructor(error: LogError) {
    error.execute(new Error('Route not found'));
  }
}
